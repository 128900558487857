import { throwIfNot, isId, addLogOnCall } from "~/utils/common"
import { augmentLink, flattenSectionLinks, linksByTypeById } from "~/utils/links"

// =================================== STATE ===================================

export const state = () => ({
  importantLinks: [],
  importantLinksLoaded: false,
})

// ================================== GETTERS ==================================

/**
 * @type {import("vuex").GetterTree<typeof state>}
 */
export const getters = {
  flatImportantLinks: state => flattenSectionLinks(state.importantLinks),
  importantByTypeById: (state, get) => linksByTypeById(get.flatImportantLinks),
}

// ================================= MUTATIONS =================================

/**
 * @type {import("vuex").MutationTree<typeof state>}
 */
export const mutations = {
  SET_IMPORTANT_LINKS (state, payload) {
    state.importantLinks = payload || []
    state.importantLinksLoaded = Array.isArray(payload)
  },
}

// ================================== ACTIONS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeActions = {
  _reset (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined)
    }
  },

  async createImportantLink (ctx, { projectId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.post(`/projects/${projectId}/important-links`, body, { headers })

    await ctx.dispatch('onProjectLinksRefresh', { projectId })
  },

  async loadImportantLinks (ctx, { projectId, useCache = false }) {
    throwIfNot(isId, projectId)
    if (useCache === true && ctx.state.importantLinksLoaded && !this.state.publicMode) return

    var data
    if (ctx.rootState.publicToken) {
      const publicToken = ctx.rootState.publicToken
      data = await this.$axios.$get(`/public/${publicToken}/important-links`)
    } else {
      throwIfNot(isId, ctx.rootState.workspace.currentId)
      const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }
      data = await this.$axios.$get(`/projects/${projectId}/important-links`, { headers })
    }

    for (const section of data) {
      for (const link of section.links) {
        augmentLink(link, ctx.rootState.workspace.currentId, projectId)
      }
    }

    ctx.commit('SET_IMPORTANT_LINKS', data)
  },

  async updateImportantLinks (ctx, { projectId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.put(`/projects/${projectId}/important-links`, body, { headers })

    await ctx.dispatch('onProjectLinksRefresh', { projectId })
  },

  async updateExternalLink (ctx, { projectId, linkId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, linkId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.put(`/projects/${projectId}/external-links/${linkId}`, body, { headers })

    await ctx.dispatch('onExternalLinkPatch', { projectId, linkId, patch: body })
  },
}

// ================================== EFFECTS ==================================

const storeEffects = {
  async onProjectLinksRefresh (ctx, { projectId }) {
    if (projectId !== this.state.project.contextId) return

    if (ctx.state.importantLinksLoaded) {
      await ctx.dispatch('loadImportantLinks', { projectId })
    }

    if (this.state.log.projectLogsLoaded) {
      await this.dispatch('log/loadProjectLogs', { projectId })
    }

    // reset log cache because we can't say what has changed
    this.commit('log/SET_LOG_CACHE', null)
    // But if a log is currently used, reload it immediately
    if (this.state.log.currentId) {
      await this.dispatch('log/loadLog', { projectId, logId: this.state.log.currentId })
    }
  },

  async onExternalLinkPatch (ctx, { projectId, linkId, patch }) {
    if (projectId !== this.state.project.contextId) return

    if (ctx.state.importantLinksLoaded) {
      await ctx.dispatch('loadImportantLinks', { projectId })
    }

    if (this.state.log.projectLogsLoaded) {
      this.commit('log/PATCH_PROJECT_LOG_EXTERNAL_LINK', { linkId, patch })
    }

    if (Object.values(this.state.log.logCache).filter(Boolean).length) {
      this.commit('log/PATCH_LOG_CACHE_EXTERNAL_LINK', { linkId, patch })
    }
  },
}

addLogOnCall(storeActions)
addLogOnCall(storeEffects)

export const actions = {
  ...storeActions,
  ...storeEffects,
}
