
/**
* This middleware is meant for the workspace demo creation form.
* Only members of the template workspace can create a demo workspace.
* The current workspace must also be the template workspace itself.
*
* REQUIREMENTS:
*   - Must be called after 'auth' middleware.
*   - Must be called after 'workspace' middleware.
*/
export default function workspaceCreateDemo ({ app, store, error }) {
  console.info('[middleware::workspaceCreateDemo]')

  if (!app.$canCreateDemoWorkspace(store.state.workspace.currentId)) {
    return error({ statusCode: 403 })
  }
}
