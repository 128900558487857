
export const LinkType = {
  DOC: 'doc',
  EXT: 'external',
}

export function areSameLinks (a, b) {
  const justCreated = !a.target.id
  return a.link_type === b.link_type
    && a.target.id === b.target.id
    && (!justCreated || a.target.url === b.target.url) // links with no id can be compared by url
}

// TODO: Remove when the API sends the correct path for internal links
export function augmentLink (link, workspaceId, projectId) {
  if (link.link_type !== 'external') {
    link.target.url = `/workspace/${workspaceId}/project/${projectId}/doc/${link.target.id}`
  }
}

export function flattenSectionLinks (sections) {
  const links = []
  if (!sections) sections = []
  for (const section of sections) {
    links.push(...section.links)
  }
  return links
}

export function linksByTypeById (links) {
  const result = { [LinkType.DOC]: {}, [LinkType.EXT]: {} }
  if (!links) links = []
  for (const { link_type, target } of links) {
    result[link_type][target.id] = true
  }
  return result
}

export function noDuplicateLinks (links) {
  var i, j, a, b
  const length = links.length
  for (i = 0; i < length - 1; i++) {
    a = links[i]
    for (j = i + 1; j < length; j++) {
      b = links[j]
      if (a.link_type !== 'external') continue
      if (a.target.url === b.target.url) return false
    }
  }
  return true
}
