import { LocalStorageAccessor } from '~/utils/localStorage'

const accessor = LocalStorageAccessor('flightlog-webapp:')

function shortcutIdAccessor (accessor) {
  return {
    del (id) {
      const byId = accessor.get() || {}
      delete byId[id]
    },
    get (id, subkey) {
      return accessor.get()?.[id]?.[subkey] ?? null
    },
    set (id, subkey, value) {
      const byId = accessor.get() || {}
      if (!byId[id]) {
        byId[id] = {}
      }
      byId[id][subkey] = value
      accessor.set(byId)
    },
  }
}

// TODO: handle server mode (no localStorage)

export default ({ store }, inject) => {
  const storage = {
    authAccess: accessor('auth-access', Object),
    currentUserId: accessor('current-user-id', Number),
    menuOpened: accessor('menu-opened', Object),
    userPrefs: accessor('user-prefs', Object),
    activation: accessor('activation', Object),
    userMenuGroupPref: accessor('user-menu-group-pref', String),
  }
  inject('localStorage', storage)

  inject('userPrefs', shortcutIdAccessor(storage.userPrefs))
  inject('activation', shortcutIdAccessor(storage.activation))

  store.commit('SYNC_MENU_OPENED', storage.menuOpened.get())
}
