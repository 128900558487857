<template>
  <ActionTopBar class="h-full" :canEdit="currentlyAbleToEdit">
    <template v-slot:default>
      <div
        class="absolute flex-shrink-0 hidden sm:block w-10 h-10 bg-white border border-gray-300 rounded"
        style="top: 10px"
        :style="$store.state.publicMode ? 'left: 75px' : 'left: 20px;'"
      >
        <EmojiPicker
          :value="currentEmoji"
          class="z-20"
          @input="$store.dispatch('project/updateSettings', {
            groupId: currentGroupId,
            emoji: $event,
            projectId,
            group_id: currentGroupId,
          })"
        >
          <template v-slot="{ onClick }">
            <EmojiButton
              :emoji="currentEmoji"
              :disabled="!currentlyAbleToEdit"
              iconClass="w-full p-1"
              class="block w-full text-2xl text-primary-700"
              :class="{'hover:bg-gray-50': currentlyAbleToEdit}"
              @click="onClick"
            />
          </template>
        </EmojiPicker>
      </div>
      <div class="flex items-center truncate">
        <!-- Flightlog public logo -->
        <div v-if="$store.state.publicMode" class="flex-shrink-0 w-10 md:mr-4">
          <img src="/logos/flightlog-app-badge__colors.png" alt="Flightlog logo">
        </div>

        <!-- Page location info -->
        <div class="truncate" :class="$store.state.publicMode ? 'sm:ml-16' : 'sm:ml-12'">
          <!-- Breadcrumb -->
          <div class="flex items-center text-sm font-bold text-gray-700 leading-snug truncate">
            <div class="flex items-center truncate">
              <div class="truncate">{{ currentName || '...' }}</div>
              <div v-if="isSharedProject" class="flex-shrink-0 ml-1 w-4">
                <GlobeAltSolid />
              </div>
            </div>
            <div class="flex-shrink-0 hidden sm:block mx-2">&frasl;</div>
            <div class="truncate hidden sm:flex items-center text-gray-900">
              <div class="truncate">{{ currentPageTitleI18n }}</div>
              <div v-if="isSharedDoc" class="flex-shrink-0 ml-1 w-4">
                <GlobeAltSolid />
              </div>
            </div>
          </div>
          <!-- Parent group -->
          <div
            v-if="!$store.state.publicMode"
            class="hidden sm:block text-sm font-medium text-gray-700 truncate"
          >
            <span>{{ currentParentGroupName }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:leadingContextualActions>
      <!-- Project settings -->
      <nuxt-link
        :to="`${projectPath}/settings`"
        class="hidden sm:block h-6 text-sm rounded px-2 mx-2"
      >
        <span>{{ $t('Edit project settings') }}</span>
      </nuxt-link>

      <!-- Share Project -->
      <button
        class="hidden sm:block h-6 text-sm rounded px-2 mx-2"
        @click="openShareProjectModal"
      >
        <span>{{ $t('Share') }}</span>
      </button>
    </template>

    <template v-slot:otherContextualActions>
      <!-- Responsive : Edit project -->
      <nuxt-link
        :to="`${projectPath}/settings`"
        class="sm:hidden w-full h-10 flex items-center text-left text-sm hover:bg-gray-50 px-4"
      >
        <span>{{ $t('Edit project settings') }}</span>
      </nuxt-link>

      <!-- Share Project -->
      <button
        class="sm:hidden w-full h-10 block text-left text-sm hover:bg-gray-50 px-4"
        @click="openShareProjectModal"
      >
        <span>{{ $t('Share') }}</span>
      </button>

      <!-- Archive project -->
      <button
        v-if="currentProject"
        class="w-full h-10 block text-left text-sm hover:bg-gray-50 px-4"
        @click="openArchiveProjectModal"
      >
        <span>{{ $t(currentProject.is_archived ? 'Restore' : 'Archive') }}</span>
      </button>

      <!-- Delete project -->
      <button
        v-if="currentProject && canDelete"
        class="w-full h-10 block text-left text-sm hover:bg-gray-50 px-4"
        @click="openDeleteProjectModal"
      >
        <span>{{ $t('Delete') }}</span>
      </button>
    </template>

    <template v-slot:contextualActions>
      <!-- New log -->
      <button
        type="button"
        :to="`${projectPath}/logs/create`"
        class="h-10 w-full flex items-center px-4 hover:bg-gray-50"
        @click="createLog"
      >
        <div class="w-4">
          <FlagSolid />
        </div>
        <span class="text-sm ml-2">{{ $t('New log') }}</span>
      </button>

      <!-- New important document -->
      <nuxt-link
        :to="`${projectPath}/important-docs?modal=add-attachement`"
        class="h-10 flex items-center px-4 hover:bg-gray-50"
      >
        <div class="w-4">
          <StarSolid />
        </div>
        <span class="text-sm ml-2">{{ $t('New important document') }}</span>
      </nuxt-link>

      <!-- New documentation page -->
      <button
        type="button"
        class="h-10 w-full flex items-center px-4 hover:bg-gray-50"
        @click="createNewDocumentation"
      >
        <div class="w-4">
          <DocumentSolid />
        </div>
        <span class="text-sm ml-2">{{ $t('New documentation page') }}</span>
      </button>
    </template>
  </ActionTopBar>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { isAtLeastMember } from '~/utils/rights'

import { TitleByPage } from "~/components/menus/ProjectPagesMenu"

import ActionTopBar from "~/components/bars/ActionTopBar"
import ConfirmModal from '~/components/modals/ConfirmModal'
import DocumentSolid from "~/components/icons/DocumentSolid"
import EmojiButton from '~/components/actions/EmojiButton'
import EmojiPicker from '~/components/dropdowns/EmojiPicker'
import FlagSolid from '~/components/icons/FlagSolid'
import GlobeAltSolid from "~/components/icons/GlobeAltSolid"
import ProjectArchiveModal from "~/components/modals/ProjectArchiveModal"
import ShareModal from "~/components/modals/ShareModal"
import StarSolid from "~/components/icons/StarSolid"

export default {
  name: 'ProjectTopBar',

  components: {
    ActionTopBar,
    DocumentSolid,
    EmojiButton,
    EmojiPicker,
    FlagSolid,
    GlobeAltSolid,
    StarSolid,
  },

  computed: {
    ...mapGetters('project', [
      'currentEmoji',
      'currentName',
      'currentProject',
      'currentParentGroupName',
      'currentlyAbleToEdit',
      'currentGroupId',
    ]),

    ...mapState('workspace', {
      workspaceId: 'currentId',
      currentWorkspace: 'currentWorkspace',
    }),

    ...mapGetters('workspace', [
      'currentRole',
    ]),

    ...mapState('project', {
      projectId: 'currentId',
    }),

    ...mapGetters('doc', {
      currentDoc: 'currentDoc',
      docName: 'currentName',
    }),

    projectPath () {
      return `/workspace/${this.workspaceId}/project/${this.projectId}`
    },

    currentPageTitleI18n () {
      if (this.$route.name === 'workspace-wsid-project-projectid-doc-docid') return this.docName || this.$t('Untitled document')
      const currentPage = this.$route.path.split('/').pop()
      return TitleByPage[currentPage]
    },

    isSharedProject () {
      if (!this.currentProject) return false
      return Boolean(this.currentProject.share_token)
    },

    isSharedDoc () {
      if (!this.currentDoc) return false
      return Boolean(this.currentDoc.share_token)
    },

    canDelete () {
      return isAtLeastMember(this.currentRole) && this.currentlyAbleToEdit
    },
  },

  methods: {
    async createNewDocumentation () {
      if (!this.currentWorkspace.can_add_docs) {
        this.$showModal(ConfirmModal, {
          title: this.$t(`Can't create more documents`),
          description: this.$t(`You have reached the maximum number of documents you can add to your workspace with a free plan. You need to upgrade your subscription plan to add more documents.`),
          confirmLabel: this.$t('Upgrade'),
          onConfirm: () => {
            this.$router.push(`/workspace/${this.workspaceId}/settings/upgrade`)
            this.$closeModal()
          },
        })
      } else {
        const createdId = await this.$store.dispatch('doc/createDoc', {
          projectId: this.projectId,
          parentPath: '',
        })

        this.$router.push(`${this.projectPath}/doc/${createdId}`)
      }
    },

    createLog () {
      if (!this.currentWorkspace.can_add_logs) {
        this.$showModal(ConfirmModal, {
          title: this.$t(`Can't create more logs`),
          description: this.$t(`You have reached the maximum number of logs you can add to your workspace with a free plan. You need to upgrade your subscription plan to add more logs.`),
          confirmLabel: this.$t('Upgrade'),
          onConfirm: () => {
            this.$router.push(`/workspace/${this.workspaceId}/settings/upgrade`)
            this.$closeModal()
          },
        })
      } else {
        this.$router.push(`/workspace/${this.workspaceId}/project/${this.projectId}/logs/create`)
      }
    },

    openShareProjectModal () {
      this.$showModal(ShareModal, {
        resourceType: 'project',
        resource: this.currentProject,
      })
    },

    openArchiveProjectModal () {
      this.$showModal(ProjectArchiveModal, {
        project: this.currentProject,
        groupId: this.currentGroupId,
        workspaceId: this.workspaceId,
        onConfirm: (result, action) => {
          if (result.data.success) {
            if (action === 'project/restoreProject') {
              this.$addToast({
                text: this.$t('Project restored'),
              })
            } else if (action === 'project/archiveProject') {
              this.$addToast({
                text: this.$t('Project archived'),
              })
            }
          }
        },
      })
    },

    openDeleteProjectModal () {
      this.$showModal(ConfirmModal, {
        title: this.$t('Delete project'),
        description: this.$t(`You're about to delete Project: "${this.currentName}" and its content.\n\nThis action cannot be undone. We will delete all associated data immediatly after your confirmation. Be very careful.\n\nAre you sure you wish to continue?`),
        confirmLabel: this.$t('Yes, delete'),
        isCritical: true,
        onConfirm: async () => {
          const result = await this.$store.dispatch('project/deleteProject', {
            projectId: this.projectId,
            groupId: this.currentGroupId,
          })
          if (result.data.success) {
            this.$addToast({
              text: this.$t('Project deleted'),
            })
          }
          this.$closeModal()
        },
      })
    },
  },
}
</script>
