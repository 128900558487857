<template>
  <div class="border-b-2 border-primary-900 px-2 py-4">
    <!-- Search field -->
    <div class="relative mb-2">
      <!-- Decoration -->
      <div class="absolute w-6 p-2px m-1">
        <SearchSolid />
      </div>

      <!-- Input -->
      <input
        ref="searchInput"
        type="search"
        :placeholder="$t('Search for anything')"
        class="
          w-full bg-primary-900 text-sm font-bold text-white rounded p-1 pl-8
          border border-primary-900 focus:shadow-border-primary-400 outline-none
          placeholder-primary-50
        "
        @click="openSearchModal"
      >
    </div>

    <!-- Home -->
    <nuxt-link
      :to="`/workspace/${workspaceId}`"
      exact-active-class="bg-primary-800"
      class="flex items-center text-sm font-bold text-white rounded hover:bg-primary-900 p-1 mb-2"
    >
      <div class="w-6 p-2px">
        <HomeSolid />
      </div>
      <div class="ml-1">{{ $t('Home') }}</div>
    </nuxt-link>

    <!-- Settings -->
    <nuxt-link
      :to="`/workspace/${workspaceId}/settings`"
      active-class="bg-primary-800"
      class="relative flex items-center text-sm font-bold text-white rounded hover:bg-primary-900 p-1"
    >
      <div class="w-6 p-2px">
        <CogSolid />
      </div>
      <div class="ml-1">{{ $t('Settings & Members') }}</div>
      <ExclamationCircleSolid v-if="displayAlertIcon" class="absolute right-0 ml-2 w-6 p-2px" />
    </nuxt-link>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import CogSolid from "~/components/icons/CogSolid"
import ExclamationCircleSolid from '~/components/icons/ExclamationCircleSolid'
import HomeSolid from "~/components/icons/HomeSolid"
import SearchModal from '~/components/modals/SearchModal'
import SearchSolid from "~/components/icons/SearchSolid"

export default {
  name: 'MainMenuWorkspace',

  components: {
    CogSolid,
    ExclamationCircleSolid,
    HomeSolid,
    SearchSolid,
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),

    ...mapGetters('billing', [
      'expiryError',
      'overseatError',
      'paymentError',
    ]),

    displayAlertIcon () {
      return this.expiryError || this.overseatError || this.paymentError
    },
  },

  methods: {
    openSearchModal () {
      this.$showModal(SearchModal, { workspaceId: this.workspaceId })
    },
  },
}
</script>
