import { isObject, getTypeName } from '~/utils/common'

export function LocalStorageAccessor (prefix) {
  function makeAccessor (key, type) {
    const cast = safeTypeGetter(type)
    return {
      get () {
        return cast(JSON.parse(localStorage.getItem(prefix + key)))
      },
      set (v) {
        const serialized = safeSerialize(key, v, type)
        // Ensure value will still be usable after parse + cast
        cast(JSON.parse(serialized))
        localStorage.setItem(prefix + key, serialized)
      },
      del () {
        localStorage.removeItem(prefix + key)
      },
    }
  }
  return process.browser ? makeAccessor : makeAccessorNoStorage
}

function safeTypeGetter (type) {
  switch (type) {
    case Number: return NumberGetter
    case Date: return DateGetter
    case Array: return ArrayGetter
    case Object: return ObjectGetter
    case Boolean:
    case String:
      return v => v
    default:
      throw new TypeError(`Unhandled type: ${type}`)
  }
}

function makeAccessorNoStorage () {
  return { get: noStorageError, set: noStorageError, del: noStorageError }
}

function noStorageError () {
  throw new ReferenceError('localStorage is only available in the browser')
}

function safeSerialize (key, v, type) {
  if (v === undefined || v === null) {
    throw new TypeError(`Invalid value: ${v}. Use $localStorage.<key>.del() to unset ${key}.`)
  }
  if (getTypeName(v) !== type.name) {
    throw new TypeError(`Unexpected value for ${key}: ${v}. Type should be ${type.name}.`)
  }
  return JSON.stringify(v)
}

function NumberGetter (v) {
  if (isFinite(v)) return v
  throw new Error(`Not a finite number: ${v}`)
}

function DateGetter (v) {
  const d = new Date(v)
  if (isFinite(d)) return d
  throw new Error(`Not a valid date: ${v}`)
}

function ArrayGetter (v) {
  if (v === null || Array.isArray(v)) return v
  throw new Error(`Not an array: ${v}`)
}

function ObjectGetter (v) {
  if (v === null || isObject(v)) return v
  throw new Error(`Not a plain object: ${v}`)
}
