/**
* This middleware must be used in routes that require authentication.
*/
export default async function auth ({ store, route, redirect }) {
  // NOTE: For now, bearer token is used through javascript and stored in the localStorage.
  // TODO: use cookie to be compatible with ssr (http only for better security)
  console.info('[middleware::auth]')

  // handle special public mode (no authentication required)
  const { publicMode, publicToken } = route.query

  if (publicMode && publicToken) {
    store.commit('SET_PUBLIC_MODE', { mode: publicMode, token: publicToken })
    return
  }

  if (await store.dispatch('user/isAuthenticated')) {
    return
  }

  redirect(`/account/signin?signout=1&next=${encodeURIComponent(route.fullPath)}`)
}
