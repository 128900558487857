<template>
  <div
    v-if="options"
    id="global-alert"
    class="relative w-screen flex items-center py-3 px-6"
    :class="[style.bg, style.color, {'h-16': !expanded}]"
  >
    <!-- Icon -->
    <component :is="style.icon" class="flex-shrink-0 w-6" :class="style.color" />

    <!-- Title and description -->
    <div ref="text" class="flex-grow h-full px-4" :class="{'truncate': !expanded}">
      <h5 class="font-bold" :class="[style.color, {'truncate': !expanded}]">{{ $t(options.title) }}</h5>
      <p class="text-gray-900 text-sm" :class="{'truncate': !expanded}">{{ $t(options.description) }}</p>
    </div>

    <!-- Action -->
    <button
      v-if="options.actionToken"
      type="button"
      class="flex-shrink-0 font-bold"
      :class="style.color"
      @click="dismissed = true"
    >
      <span>{{ $t(options.actionLabel) }}</span>
    </button>
    <nuxt-link
      v-else-if="options.actionLink"
      class="flex-shrink-0 font-bold"
      :class="style.color"
      :to="options.actionLink"
    >
      <span>{{ $t(options.actionLabel) }}</span>
    </nuxt-link>

    <!-- toggle expand button -->
    <button
      v-if="!enoughSpace"
      class="absolute z-10 w-6 text-primary transform -translate-x-1/2 -translate-y-1/2"
      :class="{'rotate-180': expanded}"
      style="top: 100%; left: 50%"
      @click="toggleExpand"
    >
      <ArrowCircleDownSolid />
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import ArrowCircleDownSolid from "~/components/icons/ArrowCircleDownSolid"
import ErrorIcon from '~/components/icons/ExclamationCircleSolid'
import InfoIcon from '~/components/icons/InformationCircleSolid'

export default {
  name: 'GlobalAlertBar',

  components: {
    ArrowCircleDownSolid,
  },

  data () {
    return {
      enoughSpace: true,
      expanded: false,
      dismissed: false,
      resizeObs: undefined,
    }
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentid',
    }),

    ...mapGetters('workspace', [
      'currentRole',
    ]),

    ...mapGetters('billing', [
      'expiryError',
      'overseatError',
      'paymentError',
      'canceledSubscription',
    ]),

    billingPath () {
      return `/workspace/${this.workspaceId}/settings/billing`
    },

    options () {
      // Several of the following flags could be true at the same time
      // The order defines priority
      if (this.paymentError) {
        const o = {
          kind: 'error',
          title: 'Payment error',
          description: 'There is an error when trying to upgrade your workspace plan. Please check your billing to submit informations again',
          actionLabel: 'Resolve billing',
        }
        if (this.isCurrentlyOwner) o.actionLink = `${this.billingPath}/funnel/finalization`
        return o
      }
      if (this.overseatError) {
        const o = {
          kind: 'error',
          title: 'Missing seat',
          description: 'You have reached the maximum number of members in your workspace. Add seats or extra members will be excluded from the workspace.',
          actionLabel: 'Add seat',
        }
        if (this.isCurrentlyOwner) o.actionLink = this.billingPath
        return o
      }
      if (this.canceledSubscription && !this.dismissed) {
        return {
          kind: 'info',
          title: 'Your workspace subscription has been canceled',
          description: 'You can use Premium features until the end of your commitment period. After that, your workspace will not be charged anymore, and your workspace will be downgraded.',
          actionLabel: 'Dismiss',
          actionToken: 'DISMISS',
        }
      }
      if (this.expiryError) {
        const o = {
          kind: 'error',
          title: 'Card is expirating',
          description: 'Your card is expirating soon, please contact your workspace owner to update a new valid credit card',
          actionLabel: 'Update your card',
        }
        if (this.isCurrentlyOwner) o.actionLink = this.billingPath
        return o
      }
      return null
    },

    alertKind () {
      return (this.options && this.options.kind) || 'info'
    },

    style () {
      switch (this.alertKind) {
        case 'error': return { icon: ErrorIcon, bg: 'bg-red-100', color: 'text-red' }
        default: return { icon: InfoIcon, bg: 'bg-primary-100', color: 'text-primary' }
      }
    },
  },

  watch: {
    options (value) {
      if (value) this.resizeObs.observe(document.documentElement)
      else this.resizeObs.disconnect()
    },
  },

  mounted () {
    this.verifySpace()
    this.resizeObs = new ResizeObserver(() => this.verifySpace())
  },

  beforeDestroy () {
    this.resizeObs.disconnect()
  },

  methods: {
    toggleExpand () {
      this.expanded = !this.expanded
      this.$nextTick(() => {
        if (!this.expanded) this.verifySpace()
      })
    },

    verifySpace () {
      if (!this.$refs.text) return // when options is null
      if (this.expanded) return // expanded text has enough space
      this.enoughSpace = hasTruncatedChild(this.$refs.text)
      if (this.enoughSpace && this.expanded) this.expanded = false
    },
  },
}

/**
 * @param {HTMLElement} container
 */
function hasTruncatedChild (container) {
  for (const el of container.children) {
    if (el.clientWidth < el.scrollWidth) {
      return false
    }
  }
  return true
}
</script>
