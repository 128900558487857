import { isBoolean, isObject } from '~/utils/common'

export const NODE_PATH_SEP = '.'

export const MAX_DEPTH = 4

export const NodeArea = {
  BEFORE: 'before',
  AFTER: 'after',
  INSIDE: 'inside',
}

export function resolveDepthBelow (node, depth = 0) {
  if (!node.children.length) return depth
  return Math.max(...node.children.map(c => resolveDepthBelow(c, depth + 1)))
}

/**
 * Resolve the area the mouse is currently in, relative to a given element.
 * Note: if areaRation is set to 0.5 (maximum), the mouse cannot be "INSIDE".
 * @param {Number} mouseY - position of mouse on y axis
 * @param {Number} targetY - top of the target element
 * @param {Number} targetHeight - height of the target element
 * @param {Number} areaRatio - Float number between 0 and 0.5 -> [0, 0.5]
 */
export function resolveNodeArea (mouseY, targetY, targetHeight, areaRatio = 0.15) {
  const bottomArea = areaRatio * targetHeight
  const topArea = (1 - areaRatio) * targetHeight
  const relativeMouseY = mouseY - targetY
  return relativeMouseY <= bottomArea ? NodeArea.BEFORE
    : relativeMouseY >= topArea ? NodeArea.AFTER
      : NodeArea.INSIDE
}

function resolveDestination (target, area) {
  switch (area) {
    case NodeArea.INSIDE: return {
      newParent: target,
      newPosition: 0,
    }
    case NodeArea.BEFORE: return {
      newParent: target.parent,
      newPosition: target.parent.children.indexOf(target),
    }
    case NodeArea.AFTER: return {
      newParent: target.parent,
      newPosition: target.parent.children.indexOf(target) + 1,
    }
    default:
      throw new Error(`Unexpected NodeArea: ${JSON.stringify(area)}`)
  }
}

export function moveNodeByRefs (node, target, area) {
  if (!isNode(node) || !isNode(target) || node === target) return

  var { newParent, newPosition } = resolveDestination(target, area)

  const oldPosition = node.parent.children.indexOf(node)

  // when moving inside the same parent and after current position, we must account for
  // the fact that we remove the node. There is one less node in the list
  if (newParent === node.parent && newPosition > oldPosition) newPosition--

  // remove draggedNode from its current position
  node.parent.children.splice(oldPosition, 1)
  // insert into its new parent
  newParent.children.splice(newPosition, 0, node)
  node.parent = newParent
}

export function updatePaths (node, updatedNode) {
  node.path = updatedNode.path
  node.children.forEach((child, i) => {
    const uChild = updatedNode.children[i]
    if (uChild.item_id !== child.item.id) {
      throw new Error(`Document tree is out of sync. Found ${child.item.id} instead of ${uChild.item_id}`)
    }
    updatePaths(child, uChild)
  })
}

export function augmentNodes (node, openedById) {
  // Assumes the function is first called on the root node (the one with no item)
  for (const child of node.children) {
    augmentNode(child, {
      parent: node,
      opened: openedById[child.item.id],
    })
    augmentNodes(child, openedById)
  }
}

export function augmentNode (node, { parent, opened }) {
  node.parent = parent
  node.opened = opened || false
}

export function isNode (value) {
  return (
    isObject(value) &&
    typeof value.path === 'string' &&
    'parent' in value &&
    'item' in value &&
    Array.isArray(value.children) &&
    isBoolean(value.opened)
  )
}

export function getNodeRef (node, idPath) {
  if (!idPath) return node
  for (const id of idPath.split(NODE_PATH_SEP)) {
    node = node.children.find(c => c.item.id === Number(id))
  }
  if (!node) throw new Error(`Could not find "${idPath}" in tree`)
  if (!isNode(node)) throw new Error('Unexpected ref to non augmented node.')
  return node
}

export function seekNodeById (tree, itemId) {
  var node = tree
  for (const child of node.children) {
    if (child.item.id === itemId) return child
    const found = seekNodeById(child, itemId)
    if (found) return found
  }
}

export function baseTree () {
  return { children: [] }
}

export function countChildren ({ children }) {
  let count = children.length
  for (const child of children) {
    count += countChildren(child)
  }
  return count
}
