import {
  addLogOnCall,
  isBoolean,
  isId,
  isObject,
  patchObject,
  removeFrom,
  setReactiveObjectKey,
  throwIfNot,
  findIndex,
} from "~/utils/common"
import { isNode, seekNodeById } from "~/utils/treenode"
import { MenuDocFilter } from '~/utils/constants'

// =================================== STATE ===================================

export const state = () => ({
  currentId: undefined,
  docDetails: {},
  templateGroups: [],
  templateGroupsLoaded: false,
  titles: [],
})

// ================================== GETTERS ==================================

/**
 * @type {import("vuex").GetterTree<typeof state>}
 */
export const getters = {
  currentDoc: state => state.docDetails[state.currentId],
  currentName: (state, get) => get.currentDoc?.name || '',
}

// ================================= MUTATIONS =================================

/**
 * @type {import("vuex").MutationTree<typeof state>}
 */
export const mutations = {
  SET_CURRENT_ID (state, docId) {
    state.currentId = docId || null
  },
  SET_TEMPLATES (state, payload) {
    state.templateGroups = payload || []
    state.templateGroupsLoaded = Array.isArray(payload)
  },
  PATCH_TEMPLATE_GROUP (state, { groupId, patch }) {
    for (const group of state.templateGroups) {
      if (groupId === group.id) {
        patchObject(group, patch)
      }
    }
  },
  REMOVE_TEMPLATE (state, { docId, groupId = null }) {
    for (const group of state.templateGroups) {
      if (groupId && groupId !== group.id) continue
      removeFrom(group.templates, t => t.id === docId)
    }
  },
  REMOVE_TEMPLATE_GROUP (state, groupId) {
    removeFrom(state.templateGroups, g => g.id === groupId)
  },
  SET_DOC_DETAIL (state, payload) {
    if (!payload) state.docDetails = {} // handle reset use case
    else {
      const { docId, data } = payload
      if (!docId || (data !== null && !isObject(data))) {
        throw new Error(`Invalid payload for SET_DOC_DETAIL: ${JSON.stringify(payload)}`)
      }
      setReactiveObjectKey(state.docDetails, docId, data)
    }
  },
  PATCH_DOC_DETAIL (state, { docId, patch }) {
    patchObject(state.docDetails[docId], patch)
  },
  REMOVE_DOC (state, { docId }) {
    state.docDetails[docId] = undefined
  },
  INSERT_TITLE (state, payload) {
    const newIdx = findIndex(state.titles, e => e.pos() - payload.pos())
    state.titles.splice(newIdx, 0, payload)
  },
  UPDATE_TITLE (state, payload) {
    const newIdx = findIndex(state.titles, e => e.pos() - payload.pos())
    state.titles.splice(newIdx, 1, payload)
  },
  REMOVE_TITLE (state, payload) {
    const newIdx = findIndex(state.titles, e => e.pos() - payload.pos())
    state.titles.splice(newIdx, 1)
  },
}

// ================================== ACTIONS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeActions = {
  _reset (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined)
    }
  },

  async createDoc (ctx, { projectId, parentPath }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }
    const body = {
      parent_path: parentPath,
    }

    const { data } = await this.$axios.post(`/projects/${projectId}/docs`, body, { headers })
    const newNode = data.created_node

    await ctx.dispatch('onDocCreate', { projectId, newNode, parentPath })

    return newNode.item.id
  },

  async setCurrentDoc (ctx, { projectId, docId }) {
    if (docId === ctx.state.currentId) return
    throwIfNot(isId, docId)

    ctx.commit('SET_CURRENT_ID', docId)

    if (!ctx.state.docDetails[docId]) {
      await ctx.dispatch('loadDocDetails', { projectId, docId })
    }

    const docsFilterValue = ctx.getters.currentDoc.is_archived
      ? MenuDocFilter.ARCHIVED_DOCS.value
      : MenuDocFilter.ACTIVE_DOCS.value

    await this.dispatch('project/updateMenuDocsFilter', docsFilterValue)
    await this.dispatch('project/openParentNodes', docId)
  },

  unsetCurrentDoc (ctx) {
    ctx.commit('SET_CURRENT_ID')
  },

  async loadDocDetails (ctx, { projectId, docId }) {
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)

    var data
    if (ctx.rootState.publicToken) {
      const publicToken = ctx.rootState.publicToken
      data = await this.$axios.$get(`/public/${publicToken}/docs/${docId}`)
    } else {
      throwIfNot(isId, ctx.rootState.workspace.currentId)
      const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }
      data = await this.$axios.$get(`/projects/${projectId}/docs/${docId}`, { headers })
    }

    ctx.commit('SET_DOC_DETAIL', { docId, data })
    return data
  },

  async loadTemplates (ctx, { useCache = false } = {}) {
    if (useCache === true && ctx.state.templateGroupsLoaded && !this.state.publicMode) return
    throwIfNot(isId, this.state.workspace.currentId)

    const headers = { 'x-current-workspace-id': this.state.workspace.currentId }

    const { data } = await this.$axios.get(`/docs/templates`, { headers })

    ctx.commit('SET_TEMPLATES', data)
  },

  async useTemplate (ctx, { projectId, docId, templateId }) {
    throwIfNot(isId, this.state.workspace.currentId)
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)
    throwIfNot(isId, templateId)

    const url = `/projects/${projectId}/docs/${docId}/use-template/${templateId}`
    const headers = { 'x-current-workspace-id': this.state.workspace.currentId }

    await this.$axios.put(url, undefined, { headers })

    // Documentation should be updated through websocket at this point
  },

  async duplicateDoc (ctx, { projectId, docId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, ctx.rootState.project.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)

    const url = `/projects/${projectId}/docs/${docId}/duplicate`
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const { data } = await this.$axios.post(url, body, { headers })
    const newNode = data.created_node

    await ctx.dispatch('onDocCreate', {
      projectId: body.target_project_id,
      newNode,
      parentPath: null,
    })

    return newNode.item.id
  },

  async updateSettings (ctx, { projectId, docId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.put(`/projects/${projectId}/docs/${docId}`, body, { headers })

    await ctx.dispatch('onDocPatch', { projectId, docId, patch: body })
  },

  async archiveDoc (ctx, { projectId, docId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.put(`/projects/${projectId}/docs/${docId}/archive`, null, { headers })

    await ctx.dispatch('onDocToggleArchive', { projectId, docId, archive: true })

    return result
  },

  async restoreDoc (ctx, { projectId, docId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.put(`/projects/${projectId}/docs/${docId}/restore`, null, { headers })

    await ctx.dispatch('onDocToggleArchive', { projectId, docId, archive: false })

    return result
  },

  async deleteDoc (ctx, { projectId, docId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.delete(`/projects/${projectId}/docs/${docId}`, { headers })

    const children = result.data.detail.infos.children

    await ctx.dispatch('onDocDelete', { projectId, docId, children })

    return { result, children }
  },

  async getShareToken (ctx, { projectId, docId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)

    const url = `/projects/${projectId}/docs/${docId}/share`
    const headers = {
      'X-Current-Workspace-Id': ctx.rootState.workspace.currentId,
      'X-Client-Id': this.$sse.id,
    }

    const { data } = await this.$axios.get(url, { headers })

    return data.share_token
  },

  async toggleShareToken (ctx, { projectId, docId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)

    const url = `/projects/${projectId}/docs/${docId}/share`
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const { data } = await this.$axios.put(url, null, { headers })

    await ctx.dispatch('onDocPatch', { projectId, docId, patch: { share_token: data.share_token } })

    return data.share_token
  },
}

// ================================== EFFECTS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeEffects = {
  onDocCreate (ctx, { projectId, newNode, parentPath }) {
    throwIfNot(isId, projectId)
    const isProjectContext = projectId === this.state.project.contextId

    if (isProjectContext) {
      this.commit('project/INSERT_MENU_NODE', { newNode, parentPath })
    }
  },

  onDocMove (ctx, { projectId, nodePath, targetPath, targetArea, updatedTree }) {
    throwIfNot(isId, projectId)
    const isProjectContext = projectId === this.state.project.contextId

    if (isProjectContext) {
      this.commit('project/MOVE_MENU_NODE', { nodePath, targetPath, targetArea, updatedTree })
    }

    // TODO: implement doc parent name in LinkCard -> check if updated after doc move
  },

  async onDocPatch (ctx, { projectId, docId, patch }) {
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)
    throwIfNot(isObject, patch)
    const isProjectContext = projectId === this.state.project.contextId

    if (ctx.state.docDetails[docId]) {
      ctx.commit('PATCH_DOC_DETAIL', { docId, patch })
    }

    if (isProjectContext) {
      const node = seekNodeById(this.state.project.menuDocs, docId)
      throwIfNot(isNode, node)
      this.commit('project/PATCH_MENU_NODE', { node, patch: { item: { ...patch } } })
    }

    if (ctx.state.templateGroupsLoaded) {
      if (patch.is_template === false) {
        ctx.commit('REMOVE_TEMPLATE', { docId })
      } else {
        // TODO: patch templateDoc instead of reloading
        await ctx.dispatch('loadTemplates')
      }
    }

    if (isProjectContext) {
      if (this.state.log.projectLogsLoaded) {
        // TODO: patch logs[*].attachments instead of reloading
        await this.dispatch('log/loadProjectLogs', { projectId })
      }

      if (this.state.link.importantLinksLoaded) {
        // TODO: patch importantLinks instead of reloading
        await this.dispatch('link/loadImportantLinks', { projectId })
      }
    }
  },

  async onDocToggleArchive (ctx, { projectId, docId, archive }) {
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)
    throwIfNot(isBoolean, archive)
    const isProjectContext = projectId === this.state.project.contextId

    if (ctx.state.docDetails[docId]) {
      ctx.commit('PATCH_DOC_DETAIL', { docId, patch: { is_archived: archive } })
    }

    if (isProjectContext) {
      const filterArchived = this.state.project.menuDocsFilter === MenuDocFilter.ARCHIVED_DOCS.value
      if (archive === filterArchived) { // Equivalent to adding doc in visible menu docs
        // TODO: check if it is possible to use 'project/INSERT_MENU_NODE' instead
        await this.dispatch('project/loadMenuDocs', projectId)
      } else {
        this.commit('project/REMOVE_MENU_NODE', docId)
      }
    }

    if (ctx.state.templateGroupsLoaded) {
      // TODO: patch templateGroups instead of reloading
      await ctx.dispatch('loadTemplates')
    }
  },

  async onDocDelete (ctx, { projectId, docId, children }) {
    throwIfNot(isId, projectId)
    throwIfNot(isId, docId)
    const isProjectContext = projectId === this.state.project.contextId

    if (ctx.state.docDetails[docId]) {
      ctx.commit('REMOVE_DOC', { docId })
    }

    if (isProjectContext) {
      this.commit('project/REMOVE_MENU_NODE', docId)
      if (ctx.state.currentId && children.includes(ctx.state.currentId)) {
        this.$router.push(`/workspace/${ctx.rootState.workspace.currentId}/project/${projectId}/logs`)
      }
    }

    if (ctx.state.templateGroupsLoaded) {
      await ctx.dispatch('loadTemplates')
    }
  },
}

addLogOnCall(storeActions)
addLogOnCall(storeEffects)

export const actions = {
  ...storeActions,
  ...storeEffects,
}
