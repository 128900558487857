export const billingSeats = [
  { id: 1, name: '1 seat' },
  { id: 2, name: '2 seats' },
  { id: 3, name: '3 seats' },
  { id: 4, name: '4 seats' },
  { id: 5, name: '5 seats' },
  { id: 10, name: '10 seats' },
  { id: 15, name: '15 seats' },
  { id: 20, name: '20 seats' },
  { id: 25, name: '25 seats' },
  { id: 30, name: '30 seats' },
  { id: 40, name: '40 seats' },
  { id: 50, name: '50 seats' },
]

export const BillingPlan = {
  PREMIUM: 'PREMIUM',
  PRO: 'PRO',
}

export const billingPlans = [
  { id: BillingPlan.PREMIUM, name: 'Premium' },
  // { id: BillingPlan.PRO, name: 'Pro' },
]

export const BillingCycle = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
}

export const billingCycles = [
  { id: BillingCycle.MONTHLY, name: 'Monthly' },
  { id: BillingCycle.YEARLY, name: 'Yearly' },
]

export const MenuGroupFilter = {
  ALL_GROUPS: { value: 'active-groups', labelI18n: 'Groups' },
  MY_GROUPS: { value: 'my-groups', labelI18n: 'My groups only' },
  ARCHIVED_GROUPS: { value: 'archived-groups', labelI18n: 'Archived groups' },
}

export const ProjectFilter = {
  ACTIVE_PROJECTS: { value: 'active-projects', labelI18n: 'Active projects' },
  ARCHIVED_PROJECTS: { value: 'archived-projects', labelI18n: 'Archived projects' },
}

export const ProjectLogsFilter = {
  ACTIVE_LOGS: { value: 'active-logs', labelI18n: 'Active logs' },
  ARCHIVED_LOGS: { value: 'archived-logs', labelI18n: 'Archived logs' },
}

export const MenuDocFilter = {
  ACTIVE_DOCS: { value: 'active-docs', labelI18n: 'Documentations' },
  ARCHIVED_DOCS: { value: 'archived-docs', labelI18n: 'Archived documentations' },
}

export function getFilterLabel (Filter, value) {
  for (const k in Filter) {
    if (Filter[k].value === value) {
      return Filter[k].labelI18n
    }
  }
  throw new Error(`Unknown filter value: ${value}. Expected one of ${Object.keys(Filter)}`)
}

export const RoleToRank = {
  OWNER: 40,
  ADMIN: 30,
  MEMBER: 20,
  GUEST: 10,
}

export const RoleToToken = {
  OWNER: 'SET_AS_OWNER',
  ADMIN: 'SET_AS_ADMIN',
  MEMBER: 'SET_AS_MEMBER',
}

export const RoleI18n = {
  OWNER: 'Owner',
  ADMIN: 'Admin',
  MEMBER: 'Member',
  GUEST: 'Guest',
}

export const RoleActionTokenI18n = {
  SET_AS_OWNER: 'Set as Owner',
  SET_AS_ADMIN: 'Set as Admin',
  SET_AS_MEMBER: 'Set as Member',
  RESEND_INVITE: 'Resend invite',
  CANCEL_INVITE: 'Cancel invite',
  REMOVE_FROM_WORKSPACE: 'Remove from workspace',
}

export const RightToToken = {
  EDIT: 'SET_CAN_EDIT',
  INVITE: 'SET_CAN_INVITE',
  VIEW: 'SET_CAN_VIEW',
}

export const RightI18n = {
  EDIT: 'Can edit and invite',
  INVITE: 'Can invite',
  VIEW: 'Can view',
}

export const RightActionTokenI18n = {
  SET_PRIMARY_CONTACT: 'Set as primary contact',
  UNSET_PRIMARY_CONTACT: 'Unset primary contact',
  SET_CAN_EDIT: 'Can edit and invite',
  SET_CAN_INVITE: 'Can invite',
  SET_CAN_VIEW: 'Can view',
  RESEND_INVITE: 'Resend invite',
  CANCEL_INVITE: 'Cancel invite',
  REMOVE_FROM_TEAM: 'Remove from team',
}

export const GROUP_PROJECTS_LIMIT = 3
