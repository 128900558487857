<template>
  <div id="cookieBanner" class="fixed z-50 left-0 bottom-0">
    <div class="bg-white border border-gray-300 rounded shadow-xl p-6 m-2 sm:m-8">
      <div class="max-w-11/12 sm:max-w-lg mb-5">
        <h1 class="text-md mb-2 font-semibold">{{ $t('We use cookies to give you the best possible experience on our website.') }}</h1>
        <p class="text-sm text-gray-700">
          <span>{{ $t('Some cookies are associated with your account and personal information in order to remember that you are logged in and which workspaces you are logged into. Other cookies are not tied to your account and allow us to carry out analytics and customization, among other similar things. For more detail, please read our ') }}</span>
          <span class="underline">{{ $t('Cookie Policy') }}</span>
        </p>
      </div>

      <div class="flex flex-wrap justify-center xs:justify-start -m-2">
        <button
          type="button"
          class="flex-shrink-0 Btn Btn-wired Btn-sm w-full xs:w-auto m-2"
          @click="$cookieConsent.submit('no')"
        >
          <span>{{ $t('Block unnecessary cookies') }}</span>
        </button>
        <button
          type="button"
          class="flex-shrink-0 Btn Btn-filled Btn-sm w-full xs:w-auto m-2"
          @click="$cookieConsent.submit('yes')"
        >
          <span>{{ $t('Allow all cookies') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CookieConsentCard',
}
</script>
