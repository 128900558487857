import Vue from 'vue'

import { isStrictlyInside, insertDivAtEndOfBody } from '~/utils/dom'

import Tooltip from '~/components/Tooltip'

Vue.use({
  install (Vue, options) {
    var tooltipVm, hideTimer, moveObserver
    const TOOLTIP_ID = 'tooltip'
    const TRANSITION_DURATION = 200
    var ttidIncrement = 0
    var touched = false
    const valueByEl = {}

    function showTooltip (el) {
      const { text, maxWidth, delay } = valueByEl[el.dataset.ttid]

      if (!tooltipVm) {
        // Inject a new element in the DOM
        insertDivAtEndOfBody(TOOLTIP_ID)
        // Create and mount a new vm
        tooltipVm = new Vue({
          ...Tooltip,
          propsData: {
            tooltipId: TOOLTIP_ID,
            duration: TRANSITION_DURATION,
            text,
            maxWidth,
            delay,
          },
        })
        tooltipVm.$mount(`#${TOOLTIP_ID}`)
      } else {
        // Reuse and update existing vm (when showTooltip is called before hideTimer is elapsed)
        tooltipVm.$el.style.display = 'block'
        tooltipVm.$props.text = text
        tooltipVm.$props.maxWidth = maxWidth
        tooltipVm.$props.delay = delay
      }
      tooltipVm.positionTooltip(el)
      hideTooltipIfAnchorMovesAway(el)

      // Interrupt any pending hide action (when showTooltip is called before hideTimer is elapsed)
      clearTimeout(hideTimer)
      hideTimer = setTimeout(() => {
        hideTimer = null
        tooltipVm.$props.active = true
      }, 0)
    }

    function hideTooltip () {
      if (!tooltipVm) return
      tooltipVm.$props.active = false
      moveObserver && moveObserver.disconnect()
      clearTimeout(hideTimer)
      hideTimer = setTimeout(() => {
        hideTimer = null
        touched = false
        tooltipVm.$el.style.display = 'none'
      }, TRANSITION_DURATION)
    }

    function hideTooltipIfAnchorMovesAway (el) {
      // a value null corresponds to the viewport (screen) (this would be wrong in an iframe)
      const root = null
      // Make sure to have valid pixel values to avoid breaking IntersectionObserver
      // Use negative margins to "shrink" root's size down to a virtual rectangle
      // precisely surrounding the anchor element.
      const { top, right, bottom, left } = el.getBoundingClientRect()
      const t = -top
      const r = -(window.innerWidth - right)
      const b = -(window.innerHeight - bottom)
      const l = -left
      const rootMargin = `${t}px ${r}px ${b}px ${l}px`
      // The threshold indicates at which percentage of intersection (between
      // the observed element and the root) the observer callback will be called.
      // NOTE: This threshold works in both ways (0% -> x% and 100% -> x%)
      const threshold = 0.75
      // This will observe if the element moves in the viewport and gets
      // too far from the position it had when the function was initially called.
      // When this happens, the tooltip must no longer stay visible.
      moveObserver = new IntersectionObserver((entries) => {
        if (!entries[0].isIntersecting) { // element has moved out of root's bounds
          hideTooltip()
        }
      }, { root, rootMargin, threshold })
      moveObserver.observe(el)
    }

    function useBinding (binding) {
      return {
        text: binding.value,
        delay: binding.modifiers.slow ? 500 : 100,
        maxWidth: binding.modifiers.compact ? 200 : undefined,
      }
    }

    Vue.directive('tooltip', {
      /**
       * @type {import('vue').DirectiveFunction}
       */
      bind (el, binding, vnode) {
        if (!el.dataset.ttid) el.dataset.ttid = ++ttidIncrement
        valueByEl[el.dataset.ttid] = useBinding(binding)

        el.addEventListener('touchstart', () => {
          if (!touched) {
            touched = true
            showTooltip(el)
          } else {
            hideTooltip()
          }
        })

        el.addEventListener('mouseenter', () => {
          showTooltip(el)
        })

        el.addEventListener('mouseleave', (event) => {
          const isChild = el !== event.target && el.contains(event.target)
          const isInside = isStrictlyInside(event, el)
          if (!isChild || !isInside) hideTooltip()
        })
      },

      /**
       * @type {import('vue').DirectiveFunction}
       */
      update (el, binding) {
        valueByEl[el.dataset.ttid] = useBinding(binding)
      },

      /**
       * @type {import('vue').DirectiveFunction}
       */
      unbind (el, binding) {
        delete valueByEl[el.dataset.ttid]
        hideTooltip()
      },
    })
  },
})
