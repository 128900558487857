
export default ({ $config }) => {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = $config.CRISP_WEBSITE_ID
  window.CRISP_RUNTIME_CONFIG = {
    locale : 'fr',
  }
  var d, s
  d = document; s = d.createElement("script")
  s.src = "https://client.crisp.chat/l.js"; s.async = 1
  d.getElementsByTagName("head")[0].appendChild(s)
}
