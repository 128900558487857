<template>
  <BaseModal :title="title" @modalClose="$emit('modalClose')">
    <template v-slot:body>
      <div class="max-w-full" style="width: 496px">
        <p class="whitespace-pre-line mb-6">{{ description }}</p>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex flex-col-reverse xs:flex-row justify-end items-stretch xs:items-center pt-4 -m-2 xs:m-0 xs:ml-auto">
        <button type="button" class="Btn Btn-wired-white m-2 xs:m-0" @click="$emit('modalClose')">
          {{ cancelLabel || $t('Cancel') }}
        </button>
        <button
          v-if="onConfirm"
          class="Btn ml-3 m-2 xs:m-0 xs:ml-3"
          :class="isCritical ? 'Btn-filled-red' : 'Btn-filled'"
          @click="onConfirm"
        >
          {{ confirmLabel || $t('Confirm') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '~/components/modals/BaseModal'

export default {
  name: 'ConfirmModal',

  components: {
    BaseModal,
  },

  props: {
    title: { type: String, required: true, default: '{ No title }' },
    description: { type: String, required: true, default: '{ No description }' },
    cancelLabel: { type: String, required: false, default: '' },
    confirmLabel: { type: String, required: false, default: '' },
    isCritical: { type: Boolean, required: false, default: false },
    onConfirm: { type: Function, required: false, default: null },
  },
}
</script>
