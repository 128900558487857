<template>
  <div class="flex items-center">
    <!-- Decorating icon -->
    <div class="flex-shrink-0 w-6 p-2px">
      <PlusSolid />
    </div>

    <!-- Label -->
    <slot name="default">
      <div class="text-sm font-bold ml-1">{{ label }}</div>
    </slot>
  </div>
</template>

<script>
import PlusSolid from "~/components/icons/PlusSolid"

export default {
  name: 'AddAction',

  components: {
    PlusSolid,
  },

  props: {
    label: { type: String, required: false, default: '{ no label }' },
  },
}
</script>
