<template>
  <div
    class="group relative flex items-center hover:bg-gray-50 rounded p-1"
    :class="{'bg-gray-200': isCurrent}"
  >
    <!-- Left: Toggle open button -->
    <button
      class="
        flex-shrink-0 relative z-10
        w-6 bg-gray-100 hover:bg-gray-200 rounded p-2px
        transform rotate-0 transition-transform duration-200 ease-in-out
      "
      :class="{'invisible': !openable, 'rotate-90': opened}"
      @mousedown.prevent
      @click="$emit('toggleOpen')"
    >
      <ChevronRightSolid />
    </button>

    <EmojiPicker
      :value="doc.emoji"
      class="flex-shrink-0 ml-2px"
      @input="$store.dispatch('doc/updateSettings', {
        emoji: $event,
        projectId,
        docId: doc.id,
      })"
    >
      <template v-slot="{ onClick }">
        <EmojiButton
          :emoji="doc.emoji"
          :disabled="!canEditDoc"
          iconClass="w-6 p-2px"
          class="block relative z-10 w-6 h-6 hover:bg-gray-200 rounded"
          @mousedown.prevent
          @click="onClick"
        />
      </template>
    </EmojiPicker>

    <!-- Left: Group name -->
    <div class="flex truncate" @click="$emit('toggleOpen')">
      <nuxt-link :to="docRoute" class="group flex-grow truncate outline-none ml-1" v-bind="$attrs">
        <div class="absolute inset-0 w-full h-full group-focus:shadow-border-primary-200 rounded" />
        <div class="text-sm font-bold truncate">{{ doc.name || $t('Untitled document') }}</div>
      </nuxt-link>
    </div>

    <!-- Archived icon -->
    <div class="flex-shrink-0 text-gray-400">
      <div
        v-if="doc.is_archived"
        class="w-6"
        style="padding:5px;"
      >
        <ArchiveSolid />
      </div>
      <div v-if="!doc.is_archived && doc.share_token" class="w-6" style="padding:5px;">
        <GlobeAltSolid />
      </div>
    </div>

    <div class="absolute flex items-center" style="right: 5px;">
      <!-- Right: Other actions dropdown -->
      <BaseDropdown v-if="canEditDoc" fromRight class="h-6 ml-1">
        <template v-slot:button>
          <button
            ref="btn"
            class="
              w-6 bg-gray-100 hover:bg-gray-200 rounded p-2px
              opacity-0 group-hover:opacity-100 focus:opacity-100
            "
            @mousedown.prevent
            @click="e => $refs.btn.focus()"
          >
            <DotsVerticalSolid />
          </button>
        </template>

        <template v-slot:content>
          <DocMenu
            :doc="doc"
            :canEdit="canEditDoc"
            :isMember="currentlyMember"
            @actionClicked="executeDropdownAction"
          />
        </template>
      </BaseDropdown>

      <!-- Right: Action add -->
      <button
        v-if="canCreate"
        v-tooltip.slow="$t('Add a child documentation')"
        class="
          z-10
          block w-6 bg-primary hover:bg-primary-800 text-white rounded p-2px ml-1
          opacity-0 group-hover:opacity-100 focus:opacity-100
        "
        @mousedown.prevent
        @click="$emit('createDoc')"
      >
        <PlusSolid />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { routeIncludes } from '~/utils/route'

import ArchiveSolid from '~/components/icons/ArchiveSolid'
import BaseDropdown from '~/components/dropdowns/BaseDropdown'
import ChevronRightSolid from "~/components/icons/ChevronRightSolid"
import ConfirmModal from '~/components/modals/ConfirmModal'
import DocMenu from '~/components/menus/DocMenu'
import DotsVerticalSolid from "~/components/icons/DotsVerticalSolid"
import DuplicateDocModal from '~/components/modals/DuplicateDocModal'
import EmojiButton from '~/components/actions/EmojiButton'
import EmojiPicker from '~/components/dropdowns/EmojiPicker'
import GlobeAltSolid from "~/components/icons/GlobeAltSolid"
import PlusSolid from "~/components/icons/PlusSolid"
import ShareModal from '~/components/modals/ShareModal'

export default {
  name: 'DocActionLink',

  components: {
    ArchiveSolid,
    BaseDropdown,
    ChevronRightSolid,
    DocMenu,
    DotsVerticalSolid,
    EmojiButton,
    EmojiPicker,
    GlobeAltSolid,
    PlusSolid,
  },

  props: {
    doc: { type: Object, required: true, default: () => ({}) },
    projectId: { type: Number, required: true, default: 0 },
    openable: { type: Boolean, required: true, default: false },
    opened: { type: Boolean, required: true, default: false },
    canCreate: { type: Boolean, required: true, default: false },
    canEditDoc: { type: Boolean, required: true, default: false },
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),

    ...mapGetters('workspace', [
      'currentlyMember',
    ]),

    docRoute () {
      return this.$router.resolve({
        name: `workspace-wsid-project-projectid-doc-docid`,
        params: { wsid: this.workspaceId, projectid: this.projectId, docid: this.doc.id },
        query: this.$route.query,
      }).route
    },

    isCurrent () {
      return routeIncludes(this.$route, this.docRoute)
    },
  },

  methods: {
    async executeDropdownAction (actionToken) {
      switch (actionToken) {
        case 'DUPLICATE':
          this.$showModal(DuplicateDocModal, {
            projectId: this.projectId,
            currentDoc: this.doc,
          })
          break

        case 'SHARE':
          this.$showModal(ShareModal, {
            resourceType: 'doc',
            resource: this.doc,
          })
          break

        case 'TOGGLE_TEMPLATE':
          await this.$store.dispatch('doc/updateSettings', {
            projectId: this.projectId,
            docId: this.doc.id,
            is_template: !this.doc.is_template,
          })
          break

        case 'EXPORT':
          return window.open(this.$router.resolve({ path: `/workspace/${this.workspaceId}/project/${this.projectId}/doc/${this.doc.id}/print` }).href, '_blank')

        case 'TOGGLE_LOCKED':
          await this.$store.dispatch('doc/updateSettings', {
            projectId: this.projectId,
            docId: this.doc.id,
            is_locked: !this.doc.is_locked,
          })
          break

        case 'ARCHIVE':
          this.$showModal(ConfirmModal, {
            title: this.$t('Archive document'),
            description: this.$t('Do you want to archive this document?'),
            onConfirm: async () => {
              const result = await this.$store.dispatch('doc/archiveDoc', {
                projectId: this.projectId,
                docId: this.doc.id,
              })
              if (result.data.success) {
                this.$addToast({
                  text: this.$t('Document archived'),
                })
              }
              this.$closeModal()
              this.$router.push(`/workspace/${this.workspaceId}/project/${this.projectId}/logs`)
            },
          })
          break

        case 'DELETE_DOC':
          this.$showModal(ConfirmModal, {
            title: this.$t('Delete document'),
            description: this.$t(`You're about to delete document: "${this.doc.name}" and its content.\n\nThis action cannot be undone. We will delete all associated data immediatly after your confirmation. Be very careful.\n\nAre you sure you wish to continue?`),
            confirmLabel: this.$t('Yes, delete'),
            isCritical: true,
            onConfirm: async () => {
              const r = await this.$store.dispatch('doc/deleteDoc', {
                projectId: this.doc.project_id,
                docId: this.doc.id,
              })
              if (r.result.data.success) {
                if (r.children.length > 1) {
                  this.$addToast({
                    text: this.$t("Document and document's children deleted"),
                  })
                } else {
                  this.$addToast({
                    text: this.$t('Document deleted'),
                  })
                }
              }
              this.$closeModal()
            },
          })
          break

        case 'RESTORE':
          this.$showModal(ConfirmModal, {
            title: this.$t('Restore document'),
            description: this.$t('Do you want to restore this document?'),
            onConfirm: async () => {
              const result = await this.$store.dispatch('doc/restoreDoc', {
                projectId: this.projectId,
                docId: this.doc.id,
              })
              if (result.data.success) {
                this.$addToast({
                  text: this.$t('Document restored'),
                })
              }
              this.$closeModal()
            },
          })
          break

        case 'SHOW_HISTORY':
          alert(actionToken)
          break

        default: throw new Error(`Unexpected action token: ${actionToken}`)
      }
    },
  },
}
</script>
