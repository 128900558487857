
export class SSEConnection {
  constructor () {
    this.id = undefined
    this.url = undefined
    this.source = undefined
    this.subs = {}
    this.errorHandler = fallbackErrorHandler
  }

  get topics () {
    return Object.keys(this.subs)
  }

  /**
   * Open an sse connection with a server and listen for messages to dispatch by topic.
   * @param {string} url http url to a server handling sse
   * @param {Object} options
   * @param {string|number} options.seed - base identifier for the user who opens the connection
   * @param {boolean} options.withCredentials
   */
  open (url, { seed, withCredentials = false } = {}) {
    this.url = url
    this.source = new EventSource(url, { withCredentials })
    return new Promise((resolve, reject) => {
      this.source.onerror = reject

      this.source.onopen = () => {
        this.id = this._setId(seed)
        this.source.onmessage = event => this._onMessage(event)
        this.source.onerror = event => this._onError(event)
        resolve()
        console.info(`[sse] Now listening on ${url}`)
      }
    })
  }

  close () {
    if (this.source) {
      this.source.close()
      this.source = null
    }
    this.unsubAll()
  }

  /**
   * @callback IMessageHandler
   * @param {any} payload
   * @param {{ topic: string, event: MessageEvent }} extra
   */

  /**
   * Subscribe to a topic on this connection.
   * @param {string} topic - topic to subscribe to
   * @param {IMessageHandler} topicHandler
   */
  sub (topic, topicHandler) {
    this.subs[topic] = topicHandler
    console.info(`[sse] Subscribed to ${topic}`)
  }

  /**
   * Unsubscribe from a topic on this connection.
   * @param {string} topic - topic to unsubscribe from
   */
  unsub (topic) {
    console.info(`[sse] Unsubscribed from ${topic}`)
    delete this.subs[topic]
  }

  /**
   * Unsubscribe from all topics.
   */
  unsubAll () {
    for (const topic in this.subs) {
      this.unsub(topic)
    }
  }

  /**
   * Set a function to call on errors.
   * @param {(message: string, event: ErrorEvent) => any} handler
   */
  setErrorHandler (handler = null) {
    if (handler !== null && typeof handler !== 'function') {
      throw new TypeError('Error handler must be a callable function')
    }
    this.errorHandler = handler || fallbackErrorHandler
  }

  /**
   * Handle incomming message event.
   * @param {MessageEvent} event
   */
  _onMessage (event) {
    var topic, payload, source
    try {
      ({ topic, payload, source } = JSON.parse(event.data))
    } catch (err) { return console.warn(`[_onMessage] Malformed message: '${event.data}'`) }
    // console.log(`[_onMessage] (${source} => ${this.id}) ${topic} ${payload}`)
    if (source === this.id) return

    const handler = this.subs[topic]
    if (!handler) return console.warn(`[_onMessage] No handler: '${event.data}'`)
    handler(payload)
  }

  _onError (event) {
    this.errorHandler(event.message, event)
  }

  _setId (seed) {
    return quickNumHash(`${seed}:${Date.now()}`)
  }
}

function fallbackErrorHandler (message, event) {
  console.warn(`Unhandled SSE error: ${message || 'No error message'}`)
}

/**
 * Calculate a 32 bit FNV-1a hash
 * Adapted from: https://gist.github.com/vaiorabbit/5657561
 * @param {string} str
 * @param {Number?} h - initial hashed number
 * @returns {Number}
 */
function quickNumHash (str, h = 0x811C9DC5) {
  var i, size
  for (i = 0, size = str.length; i < size; i++) {
    h ^= str.charCodeAt(i)
    h += (h << 1) + (h << 4) + (h << 7) + (h << 8) + (h << 24)
  }
  return h >>> 0
}
