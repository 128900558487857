import { SSEConnection } from '../utils/sse'

export default ({ $config }, inject) => {
  const SUB_URL = $config.SSE_URL || `https://${window.location.host}/sub`

  const sse = new SSEConnection()
  // connections will be opened and close by workspace contexts
  inject('SUB_URL', SUB_URL)
  inject('sse', sse)
  // Properly close when window is destroyed (will have no effect if already closed)
  window.addEventListener('beforeunload', () => {
    sse.close()
  })
}
