<template>
  <div class="relative flex overflow-hidden">
    <!-- Workspace Menu -->
    <MainMenu
      :opened="menuOpened.main"
      @openedChanged="$store.commit('SYNC_MENU_OPENED', { main: $event })"
    />

    <!-- Right: Page -->
    <div class="flex-grow relative h-screen">
      <!-- Top bar -->
      <div
        class="absolute top-0 w-full h-topbar bg-white border-b-2 border-gray-100"
        :class="menuOpened.main ? 'pl-0' : 'pl-8 md:pl-12'"
        style="transition: padding 200ms ease-in-out"
      >
        <ActionTopBar :canEdit="currentlyMember" class="h-full">
          <template v-slot:default>
            <h3 class="text-sm font-bold">{{ topBarTitle }}</h3>
          </template>
        </ActionTopBar>
      </div>

      <!-- Content -->
      <div class="h-full pt-topbar">
        <div class="h-full overflow-y-auto">
          <nuxt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ActionTopBar from "~/components/bars/ActionTopBar"
import MainMenu from "~/components/menus/MainMenu"

const CONFIG = {
  'workspace-wsid': { title: 'Home' },
  'workspace-wsid-search': { title: 'Search results' },
}

export default {
  name: 'WorkspaceLayout',

  components: {
    ActionTopBar,
    MainMenu,
  },

  middleware: ['auth', 'workspace'],

  computed: {
    ...mapState([
      'menuOpened',
    ]),

    ...mapGetters('workspace', [
      'currentlyMember',
    ]),

    topBarTitle () {
      const c = CONFIG[this.$route.name]
      return c ? this.$t(c.title) : this.$route.name
    },
  },
}
</script>
