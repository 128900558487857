
export function getSeatCode (plan, cycle) {
  return `seat_${plan}_${cycle}`.toLowerCase()
}

export function getStripePrice (plan, cycle, prices) {
  return prices[getSeatCode(plan, cycle)] || 0
}

export function formattedPrice (lang, currency, stripePrice) {
  const options = { style: 'currency', currency }
  const value = stripePrice / 100
  return new Intl.NumberFormat(lang, options).format(value)
}

export function getDiffPercentage (value, reference) {
  if (!reference) return 0
  return (value - reference) / reference * 100
}

export class StripeError extends Error {
  constructor (messageStripe = '', errorTitle = '', ...params) {
    super(...params)
    this.type = 'Stripe'
    this.name = errorTitle
    this.messageStripe = messageStripe
  }
}
