/**
 * This immediately called function adds event listeners that prevents focus ring when clicking
 * but allows it when keyboard tab is pressed.
 *
 * When there is no custom focus, it's probably better to fully handle the focus from here.
 * But this project uses different focus styles based on the box-shadow property.
 * We would have to update this file each time a new focus style is added or changed.
 * So I think it's more maintainable to simply expose the classes and use them directly in the CSS.
 */
;(function noFocusRingOnClick () {
  window.addEventListener('keydown', handleFirstTab)
  document.body.dataset.focusOnClick = false

  function handleFirstTab (e) {
    if (e.code === 'Tab' || e.keyCode === 9/* IE */) {
      document.body.dataset.focusOnClick = true
      window.removeEventListener('keydown', handleFirstTab)
      window.addEventListener('mousedown', handleMouseDownOnce)
    }
  }

  function handleMouseDownOnce (e) {
    e.target.blur()
    document.body.dataset.focusOnClick = false
    window.removeEventListener('mousedown', handleMouseDownOnce)
    window.addEventListener('keydown', handleFirstTab)
  }
})()

/**
 * This disables transitions and animations on all elements of the page
 * when a resize is occuring.
 */
;(function preventAnimationsOnResize () {
  const CLS = 'transitions-disabled' // this must match css class
  var resizeTimer

  function disableAnimations (cls, kind) {
    document.body.classList.add(cls)
    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(() => { document.body.classList.remove(cls) }, 400)
  }

  window.addEventListener("resize", () => {
    disableAnimations(CLS, 'event')
  })
})()
