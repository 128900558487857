<template>
  <div class="relative">
    <!-- Button -->
    <slot name="default" :onClick="toggleVisibility" />

    <!-- Dropdown -->
    <div
      v-if="pickerVisible"
      ref="container"
      class="
        absolute z-20 w-64 bg-white text-gray-900 whitespace-no-wrap rounded
        shadow-dropdown py-2 px-2 mt-4
      "
      :class="fromRight ? 'right-0' : 'left-0'"
      @mouseleave="spottedEmoji = value"
    >
      <!-- Search field -->
      <div class="relative mb-2">
        <div class="absolute w-6 text-gray-400 m-1">
          <SearchSolid />
        </div>
        <input
          ref="emojiSearch"
          v-model="searchValue"
          type="search"
          :placeholder="$t('Search')"
          class="
            w-full bg-white text-sm font-bold text-gray-900 rounded placeholder-gray-400 p-1 pl-8
            focus:shadow-border-primary-400 outline-none
          "
        >
      </div>

      <!-- Emojis list -->
      <div
        class="
          h-40 border-b border-t border-gray-50 rounded overflow-y-auto
          focus:shadow-border-primary-400 outline-none
        "
      >
        <div class="flex flex-wrap justify-start items-start py-1">
          <button
            v-for="emoji in emojisList"
            type="button"
            tabindex="-1"
            class="w-8 h-8 rounded"
            :class="{ 'bg-gray-100': spottedEmoji === emoji }"
            @mouseenter="spottedEmoji = emoji"
            @click="selectEmoji(emoji)"
          >
            <div class="flex justify-center items-center">{{ emoji }}</div>
          </button>
        </div>
      </div>

      <!-- Current emoji details -->
      <footer class="h-12 flex items-center px-1 py-1 mt-2">
        <EmojiButton
          disabled
          :emoji="spottedEmoji"
          iconClass="w-6 p-2px"
          class="flex-shrink-0 w-8 text-lg text-gray-400 mr-2"
        />

        <div class="flex-grow leading-tight truncate">
          <span class="text-sm">{{ spottedEmojiTitle || $t('Select an emoji') }}</span>
          <br>
          <span class="text-xs text-gray-700">{{ spottedEmojiCode }}</span>
        </div>

        <button
          class="flex-shrink-0 w-8 h-8 text-gray-700 hover:bg-gray-50 rounded p-1 ml-2"
          :class="{ 'opacity-0': !value }"
          @click="selectEmoji('')"
        >
          <TrashSolid />
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { isStrictlyInside } from '~/utils/dom'
import { emojis, filterEmojis } from '~/utils/emojis'

import EmojiButton from '~/components/actions/EmojiButton'
import SearchSolid from "~/components/icons/SearchSolid"
import TrashSolid from "~/components/icons/TrashSolid"

export default {
  name: 'EmojiPicker',

  components: {
    EmojiButton,
    SearchSolid,
    TrashSolid,
  },

  props: {
    value: { type: String, required: false, default: '' },
    fromRight: { type: Boolean, required: false, default: false },
  },

  data () {
    return {
      searchValue: '',
      spottedEmoji: '',
      pickerVisible: false,
    }
  },

  computed: {
    emojisList () {
      return filterEmojis(this.searchValue)
    },

    spottedEmojiName () {
      return emojis[this.spottedEmoji]
    },

    spottedEmojiTitle () {
      const name = this.spottedEmojiName
      return name ? name[0].toUpperCase() + name.substr(1) : ''
    },

    spottedEmojiCode () {
      if (!this.spottedEmojiName) return ''
      const code = this.spottedEmojiName.replace(/\s/g, '_')
      return `:${code}:`
    },
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value && value !== this.spottedEmoji) this.spottedEmoji = value
      },
    },
    pickerVisible (val) {
      this.$nextTick(() => {
        if (val) this.$refs.emojiSearch.focus()
      })
      if (!val) this.$refs.emojiSearch.blur()
    },
  },

  beforeDestroy () {
    this.stopListeningOnClick()
  },

  methods: {
    toggleVisibility () {
      if (this.pickerVisible) {
        this.stopListeningOnClick()
      } else {
        this.startListeningOnClick()
      }
    },

    clickListener () {
      if (!isStrictlyInside(event, this.$refs.container)) this.stopListeningOnClick()
    },

    startListeningOnClick () {
      this.pickerVisible = true
      setTimeout(() => {
        document.body.addEventListener('click', this.clickListener)
      }, 0) // delay to avoid triggering click immediately
    },

    stopListeningOnClick () {
      document.body.removeEventListener('click', this.clickListener)
      this.pickerVisible = false
    },

    selectEmoji (emoji) {
      this.$emit('input', emoji)
      this.stopListeningOnClick()
    },
  },
}
</script>
