<template>
  <div class="flex justify-between items-center p-1 mb-2">
    <ActionDropdown
      :actions="dropdownActions"
      :disabled="disabled"
      class="z-20 h-6"
      v-on="$listeners"
    >
      <template v-slot:buttonContent>
        <div class="flex">
          <!-- Title -->
          <div class="text-sm font-bold ml-1">{{ label }}</div>
          <!-- Icon -->
          <div v-if="!disabled" class="w-6 p-2px ml-1">
            <ChevronDownSolid />
          </div>
        </div>
      </template>
    </ActionDropdown>

    <!-- right action -->
    <slot name="default" />
  </div>
</template>

<script>
import ActionDropdown from '~/components/dropdowns/ActionDropdown'
import ChevronDownSolid from "~/components/icons/ChevronDownSolid"

export default {
  name: 'MenuTreeHeader',

  components: {
    ActionDropdown,
    ChevronDownSolid,
  },

  props: {
    label: { type: String, required: true, default: '' },
    dropdownActions: { type: Array, required: true, default: () => [] },
    disabled: { type: Boolean, required: false, default: false },
  },
}
</script>
