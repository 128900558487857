<template>
  <div class="relative w-screen h-screen flex overflow-hidden">
    <!-- Workspace Menu -->
    <MainMenu
      v-if="!publicMode"
      :opened="menuOpened.main"
      @openedChanged="$store.commit('SYNC_MENU_OPENED', { main: $event })"
    />

    <!-- Right: Page -->
    <div
      class="flex-grow relative h-screen flex flex-col"
      :class="menuOpened.main ? 'w-has-sidemenu' : 'w-full'"
      style="transition: width 200ms ease-in-out"
    >
      <!-- Top bar -->
      <div
        ref="topbar"
        class="flex-shrink-0 w-full h-topbar bg-white border-b-2 border-gray-100"
        :class="{'pl-8 md:pl-12': !publicMode && !menuOpened.main}"
        style="transition: padding 200ms ease-in-out"
      >
        <ProjectTopBar />
      </div>

      <ArchivedBar
        v-if="projectArchiveBarVisible"
        :label="$t('This project is archived')"
        :actionVisible="currentlyMember"
        class="w-full"
        @restore="restoreProject"
        @heightChanged="projectArchiveBarHeight = $event"
      />

      <!-- Content -->
      <div class="flex" :style="`height: ${outerHeight}px`">
        <ProjectMenu
          :opened="menuOpened.project"
          @openedChanged="$store.commit('SYNC_MENU_OPENED', { project: $event })"
        />

        <!-- Right of menu -->
        <div
          class="flex-grow h-full"
          :class="menuOpened.project ? 'w-has-sidemenu' : 'w-full'"
          style="transition: width 200ms ease-in-out"
        >
          <!-- Fixed bar -->
          <ArchivedBar
            v-if="docArchiveBarVisible"
            :label="$t('This documentation is archived')"
            :actionVisible="currentlyMember"
            class="flex-shrink-0 w-full"
            @restore="restoreDoc"
            @heightChanged="docArchiveBarHeight = $event"
          />

          <!-- Project subpage -->
          <div class="overflow-y-auto" :style="`height: ${innerHeight}px`">
            <nuxt />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import projectContext from '~/middleware/context/project'

import MainMenu from "~/components/menus/MainMenu"
import ProjectMenu from "~/components/menus/ProjectMenu"
import ProjectTopBar from "~/components/bars/ProjectTopBar"
import ArchivedBar from '~/components/bars/ArchivedBar'

export default {
  name: 'ProjectLayout',

  components: {
    MainMenu,
    ArchivedBar,
    ProjectMenu,
    ProjectTopBar,
  },

  middleware: ['auth', 'workspace', projectContext.enter],

  data () {
    return {
      basePageHeight: 0,
      projectArchiveBarHeight: 0,
      docArchiveBarHeight: 0,
    }
  },

  computed: {
    ...mapState([
      'menuOpened',
      'publicMode',
    ]),

    ...mapGetters('workspace', [
      'currentlyMember',
    ]),

    ...mapState('project', {
      projectId: 'currentId',
    }),

    ...mapGetters('project', [
      'currentGroupId',
      'currentProject',
    ]),

    ...mapState('doc', {
      docId: 'currentId',
    }),

    ...mapGetters('doc', [
      'currentDoc',
    ]),

    projectArchiveBarVisible () {
      return this.currentProject ? this.currentProject.is_archived : false
    },

    docArchiveBarVisible () {
      return this.currentDoc ? this.currentDoc.is_archived : false
    },

    outerHeight () {
      return this.projectArchiveBarVisible
        ? this.basePageHeight - this.projectArchiveBarHeight
        : this.basePageHeight
    },

    innerHeight () {
      return this.docArchiveBarVisible
        ? this.outerHeight - this.docArchiveBarHeight
        : this.outerHeight
    },
  },

  mounted () {
    this.updatePageHeight()
    window.addEventListener('resize', this.updatePageHeight)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.updatePageHeight)
    projectContext.leave(this.$nuxt.context)
  },

  methods: {
    updatePageHeight () {
      this.basePageHeight = window.innerHeight - this.$refs.topbar.getBoundingClientRect().height
    },

    restoreProject () {
      if (!this.currentlyMember) return
      this.$store.dispatch('project/restoreProject', {
        projectId: this.projectId,
        groupId: this.currentGroupId,
      })
    },

    restoreDoc () {
      if (!this.currentlyMember) return
      this.$store.dispatch('doc/restoreDoc', {
        projectId: this.projectId,
        docId: this.docId,
      })
    },
  },
}
</script>
