import {
  addLogOnCall,
  arraysDiffer,
  extractProperties,
  isId,
  isObject,
  iterObject,
  patchObject,
  removeFrom,
  setReactiveObjectKey,
  throwIfNot,
} from "~/utils/common"
import { areSameLinks, augmentLink } from "~/utils/links"
import { ProjectLogsFilter } from "~/utils/constants"

// =================================== STATE ===================================

export const state = () => ({
  currentId: undefined,
  logCache: {},
  projectLogs: [],
  projectLogsFilter: ProjectLogsFilter.ACTIVE_LOGS.value,
  projectLogsLoaded: false,
})

// ================================== GETTERS ==================================

/**
 * @type {import("vuex").GetterTree<typeof state>}
 */
export const getters = {
  currentLog: state => state.logCache[state.currentId],
  currentTitle: (state, get) => get.currentLog?.title,
  currentDescription: (state, get) => get.currentLog?.description,
  currentAttachments: (state, get) => get.currentLog?.attachments,
}

// ================================= MUTATIONS =================================

/**
 * @type {import("vuex").MutationTree<typeof state>}
 */
export const mutations = {
  SET_CURRENT_ID (state, logId) {
    state.currentId = logId || null
  },
  SET_LOG_CACHE (state, payload) {
    if (!payload) state.logCache = {} // handle reset use case
    else {
      const { logId, data } = payload
      if (!logId || (data !== null && !isObject(data))) {
        throw new Error(`Invalid payload for SET_LOG_CACHE: ${JSON.stringify(payload)}`)
      }
      setReactiveObjectKey(state.logCache, logId, data)
    }
  },
  PATCH_LOG_CACHE (state, { logId, patch }) {
    const obj = state.logCache[logId]
    if (obj) {
      patchObject(obj, patch)
    }
  },
  PATCH_LOG_CACHE_EXTERNAL_LINK (state, { linkId, patch }) {
    for (const log of iterObject(state.logCache)) {
      for (const a of log.attachments) {
        if (a.link_type === 'external' && a.target.id === linkId) {
          patchObject(a.target, patch)
          // a link cannot be duplicated in a log
          // but it can be in several logs at the same time
          // only break from the inner loop
          break
        }
      }
    }
  },
  SET_PROJECT_LOGS (state, logs) {
    state.projectLogs = logs || []
    state.projectLogsLoaded = Array.isArray(logs)
  },
  PATCH_PROJECT_LOG (state, { logId, patch }) {
    for (const log of state.projectLogs) {
      if (log.id === logId) {
        patchObject(log, patch)
      }
    }
  },
  PATCH_PROJECT_LOG_CREATOR (state, { userId, creator }) {
    for (const log of state.projectLogs) {
      if (log.creator.id === userId) {
        patchObject(log, { creator })
      }
    }
  },
  PATCH_PROJECT_LOG_EXTERNAL_LINK (state, { linkId, patch }) {
    for (const log of state.projectLogs) {
      for (const a of log.attachments) {
        if (a.link_type === 'external' && a.target.id === linkId) {
          patchObject(a.target, patch)
        }
      }
    }
  },
  REMOVE_PROJECT_LOG (state, logId) {
    removeFrom(state.projectLogs, l => l.id === logId)
  },
  SET_PROJECTS_LOGS_FILTER (state, newValue) {
    state.projectLogsFilter = newValue || ProjectLogsFilter.ACTIVE_LOGS.value
  },
}

// ================================== ACTIONS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeActions = {
  _reset (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined)
    }
  },

  async setCurrentLog (ctx, logId) {
    if (logId === ctx.state.currentId) return
    throwIfNot(isId, logId)

    ctx.commit('SET_CURRENT_ID', logId)

    if (!ctx.state.logCache[logId]) {
      const projectId = ctx.rootState.project.currentId
      await ctx.dispatch('loadLog', { projectId, logId })
    }
  },

  unsetCurrentLog (ctx, payload) {
    ctx.commit('SET_CURRENT_ID')
  },

  async loadProjectLogs (ctx, { projectId, useCache = false }) {
    throwIfNot(isId, projectId)
    if (useCache === true && ctx.state.projectLogsLoaded && !this.state.publicMode) return

    const params = { only: ctx.state.projectLogsFilter }

    var data
    if (ctx.rootState.publicToken) {
      const publicToken = ctx.rootState.publicToken
      data = await this.$axios.$get(`/public/${publicToken}/logs`, { params })
    } else {
      throwIfNot(isId, ctx.rootState.workspace.currentId)
      const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }
      data = await this.$axios.$get(`/projects/${projectId}/logs`, { headers, params })
    }

    for (const log of data) {
      for (const a of log.attachments) {
        augmentLink(a, ctx.rootState.workspace.currentId, projectId)
      }
    }

    ctx.commit('SET_PROJECT_LOGS', data)
  },

  async updateProjectLogsFilter (ctx, newValue) {
    const projectId = ctx.rootState.project.currentId
    throwIfNot(isId, projectId)
    if (newValue === ctx.state.projectLogsFilter) return

    ctx.commit('SET_PROJECTS_LOGS_FILTER', newValue)
    await ctx.dispatch('loadProjectLogs', { projectId })
  },

  async loadLog (ctx, { projectId, logId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, projectId)
    throwIfNot(isId, logId)

    const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }

    const { data } = await this.$axios.get(`/projects/${projectId}/logs/${logId}`, { headers })

    for (const a of data.attachments) {
      augmentLink(a, ctx.rootState.workspace.currentId, projectId)
    }

    ctx.commit('SET_LOG_CACHE', { logId, data })

    return data
  },

  async createLog (ctx, { projectId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const { data } = await this.$axios.post(`/projects/${projectId}/logs`, body, { headers })

    await ctx.dispatch('onProjectLogsRefresh', { projectId })

    return data.created_id
  },

  async notifyLog (ctx, { projectId, logId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, logId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.post(`/projects/${projectId}/logs/${logId}/notify`, body, { headers })

    await ctx.dispatch('onLogPatch', { projectId, logId, patch: { notification_sent: true } })
  },

  async updateLog (ctx, { projectId, logId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, logId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.put(`/projects/${projectId}/logs/${logId}`, body, { headers })

    const { attachments, ...patch } = body

    const log = ctx.state.logCache[logId]
    const linkDiffer = (a, b) => !areSameLinks(a, b)

    const titleChanged = patch.title && patch.title !== log.title
    const descriptionChanged = patch.description && patch.description !== log.description
    const attachmentsChanged = arraysDiffer(log.attachments, attachments, linkDiffer)

    if (titleChanged || descriptionChanged || attachmentsChanged) {
      patch.edition_date = new Date().toJSON().replace('Z', '000') // mimic python isoformat
    }

    await ctx.dispatch('onLogPatch', { projectId, logId, patch, attachmentsChanged })
  },

  async archiveLog (ctx, { projectId, logId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, logId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.put(`/projects/${projectId}/logs/${logId}/archive`, null, { headers })

    await ctx.dispatch('onLogToggleArchive', { projectId, logId, archive: true })

    return result
  },

  async restoreLog (ctx, { projectId, logId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, logId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.put(`/projects/${projectId}/logs/${logId}/restore`, null, { headers })

    await ctx.dispatch('onLogToggleArchive', { projectId, logId, archive: false })

    return result
  },

  async deleteLog (ctx, { projectId, logId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, logId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.delete(`/projects/${projectId}/logs/${logId}`, { headers })

    ctx.dispatch('onLogDelete', { projectId, logId })

    return result
  },
}

// ================================== EFFECTS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeEffects = {
  async onProjectLogsRefresh (ctx, { projectId }) {
    if (projectId !== this.state.project.contextId) return

    if (ctx.state.projectLogsLoaded) {
      await ctx.dispatch('loadProjectLogs', { projectId })
    }

    if (this.state.link.importantLinksLoaded) {
      // Reload important links in case one of the attachments became important
      await this.dispatch('link/loadImportantLinks', { projectId })
    }
  },

  async onLogPatch (ctx, { projectId, logId, patch, attachmentsChanged }) {
    if (projectId !== this.state.project.contextId) return

    // Attachments are too complex to be patched normally.
    // If they changed, reload the complete log. Otherwise patch the other parts of the log.
    if (attachmentsChanged) {
      const { attachments } = await ctx.dispatch('loadLog', { projectId, logId })
      patch = { ...patch, attachments }
    } else if (logId in ctx.state.logCache) {
      const p = extractProperties(patch, ['title', 'description'])
      if (p) ctx.commit('PATCH_LOG_CACHE', { logId, patch: p })
    }

    if (ctx.state.projectLogsLoaded) {
      ctx.commit('PATCH_PROJECT_LOG', { logId, patch })
    }
  },

  async onLogToggleArchive (ctx, { projectId, logId, archive }) {
    const isProjectContext = projectId === this.state.project.contextId

    if (isProjectContext && ctx.state.projectLogsLoaded) {
      const filterArchive = ctx.state.projectLogsFilter === ProjectLogsFilter.ARCHIVED_LOGS.value
      if (archive === filterArchive) {
        // Refresh to recover the missing log
        await ctx.dispatch('loadProjectLogs', { projectId })
      } else {
        // Simply remove the log that changed archive state
        ctx.commit('REMOVE_PROJECT_LOG', logId)
      }
    }
  },

  onLogDelete (ctx, { projectId, logId }) {
    const isProjectContext = projectId === this.state.project.contextId

    if (isProjectContext && ctx.state.projectLogsLoaded) {
      ctx.commit('REMOVE_PROJECT_LOG', logId)
    }
  },
}

addLogOnCall(storeActions)
addLogOnCall(storeEffects)

export const actions = {
  ...storeActions,
  ...storeEffects,
}
