/**
 * This middleware can be used in pages where a billing customer must be
 * associated with the current workspace.
 *
 * REQUIREMENTS:
 *   - Must be called after 'auth' middleware.
 *   - Must be called after 'workspace' middleware.
 *   - Must be called after 'billing' middleware.
 */
export default function customerRequired ({ store, params, redirect }) {
  console.info('[middleware::customerRequired]')

  const noCustomer = !store.state.billing.customer
  if (noCustomer) {
    redirect(`/workspace/${params.wsid}/settings/billing`)
  }
}
