<template>
  <BaseModal :title="title" @modalClose="$emit('modalClose')">
    <template v-slot:body>
      <div class="mb-4 max-w-full" style="width: 496px">
        <p class="whitespace-pre-line">{{ description }}</p>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex flex-col-reverse xs:flex-row justify-end items-stretch xs:items-center pt-4 -m-2 xs:m-0 xs:ml-auto">
        <button type="button" class="Btn Btn-wired-white m-2 xs:m-0" @click="$emit('modalClose')">
          <span>{{ $t('Cancel') }}</span>
        </button>
        <button class="Btn Btn-wired m-2 xs:m-0 xs:ml-3" @click="confirm">
          <span>{{ $t('The group only') }}</span>
        </button>
        <button class="Btn Btn-filled m-2 xs:m-0 xs:ml-3" @click="confirm({ with_projects: true })">
          <span>{{ $t('The group and projects') }}</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '~/components/modals/BaseModal'

export default {
  name: 'GroupArchiveModal',

  components: {
    BaseModal,
  },

  props: {
    group: { type: Object, required: true, default: () => ({}) },
    onConfirm: { type: Function, required: true, default: (action, result) => {} },
  },

  computed: {
    title () {
      const text = this.group.is_archived ? 'Restore group' : 'Archive group'
      return this.$t(text)
    },

    description () {
      const text = this.group.is_archived
        ? 'You can restore the group with its projects or you can restore only the group.'
        : 'Archiving is useful to clean your workspace if you don’t think you’ll need this project any longer. It will still be available in searches and the archived section. \nYou can archive the group with its projects or only the group'
      return this.$t(text)
    },
  },

  methods: {
    async confirm ({ with_projects } = {}) {
      const action = this.group.is_archived ? 'group/restoreGroup' : 'group/archiveGroup'
      const result = await this.$store.dispatch(action, {
        groupId: this.group.id,
        with_projects,
      })
      this.onConfirm(action, result)
      this.$emit('modalClose')
    },
  },
}
</script>
