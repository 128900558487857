import Vue from 'vue'

import { waitForNuxtStart } from '~/utils/common'
import { SecondsPerMonth } from '~/utils/date'
import { insertDivAtEndOfBody } from '~/utils/dom'

import CookieConsentCard from '~/components/cards/CookieConsentCard'

export default ({ $config, store, $gtm }) => {
  Vue.use({
    install (Vue, options) {
      const COOKIE_ID = 'cookieBanner'
      var vm

      const cookieConsent = {
        ready: false,

        init () {
          if (this.ready) return

          switch (store.$cookies.get('cky-consent')) {
            case 'yes':
              // Activate GTM
              $gtm.init($config.GTM_ID)
              break

            case 'no':
              // Do nothing
              break

            case undefined:
              // Wait until nuxt app is mounted. The nuxt app should be mounted first.
              waitForNuxtStart(() => this.showCard())
              break
          }
        },

        showCard () {
          if (vm) return
          insertDivAtEndOfBody(COOKIE_ID)

          vm = new Vue({
            ...CookieConsentCard,
            $cookieConsent: this,
          })

          vm.$mount(`#${COOKIE_ID}`)
        },

        hideCard () {
          if (!vm) return
          vm.$el.remove()
          vm.$destroy()
          vm = null
        },

        reset () {
          store.$cookies.remove('cky-consent')
          this.showCard()
        },

        submit (value) {
          store.$cookies.set(`cky-consent`, value, {
            path: '/',
            maxAge: 6 * SecondsPerMonth,
            sameSite: "lax",
          })
          this.hideCard()
          if (value === 'yes') $gtm.init($config.GTM_ID)
        },
      }

      cookieConsent.init()
      Vue.prototype.$cookieConsent = cookieConsent
    },
  })
}
