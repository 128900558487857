<template>
  <div class="mb-2">
    <nuxt-link
      v-if="route"
      :to="route"
      active-class="bg-gray-100"
      class="block hover:bg-gray-50 rounded p-1"
    >
      <div class="relative flex items-center">
        <!-- Icon -->
        <div class="w-6 p-2px">
          <component :is="icon" />
        </div>

        <!-- Label -->
        <div class="text-sm font-bold ml-1">{{ label }}</div>
      </div>
    </nuxt-link>

    <div v-else class="relative flex items-center text-gray-400 rounded p-1">
      <!-- Icon -->
      <div class="w-6 p-2px">
        <component :is="icon" />
      </div>

      <!-- Label -->
      <div class="text-sm font-bold ml-1">{{ label }}</div>
    </div>
  </div>
</template>

<script>
import UserGroupSolid from '~/components/icons/UserGroupSolid'
import FlagSolid from '~/components/icons/FlagSolid'
import CalendarSolid from '~/components/icons/CalendarSolid'
import StarSolid from '~/components/icons/StarSolid'

export default {
  name: 'ProjectPageLink',

  components: {
    UserGroupSolid,
    FlagSolid,
    CalendarSolid,
    StarSolid,
  },

  props: {
    route: { type: [Object, String], required: false, default: '' },
    label: { type: String, required: true, default: '' },
    icon: { type: String, required: true, default: '' },
  },
}
</script>
