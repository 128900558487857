<template>
  <div class="w-screen h-screen bg-white text-gray-900">
    <!-- Top bar -->
    <FormTopBar :topBarTitle="topBarTitle" @goBack="customRedirectBack ? $router.replace(customRedirectBack) : $router.back()" />

    <!-- Page content -->
    <div class="h-screen pt-topbar overflow-y-auto">
      <div class="w-full h-full max-w-fl-form mx-auto">
        <nuxt />
      </div>
    </div>
  </div>
</template>

<script>
import FormTopBar from '~/components/bars/FormTopBar'

export default {
  name: 'FormLayout',

  components: {
    FormTopBar,
  },

  data () {
    return {
      topBarTitle: undefined,
      customRedirectBack: undefined,
    }
  },

  provide () {
    return {
      layoutContext: this,
    }
  },
}
</script>
