import Vue from 'vue'

export function waitForNuxtStart (callback) {
  var starter = setInterval(() => {
    const nuxt = Vue.prototype.$nuxt
    if (nuxt && nuxt.$loading.percent !== undefined) {
      callback()
      clearInterval(starter)
    }
  }, 250)
}

export function addLogOnCall (actions) {
  if (process.env.NODE_ENV === 'production') return
  for (const fname in actions) {
    const action = actions[fname]
    actions[fname] = function actionWithLogging (ctx, payload) {
      console.info(`[${fname}]`, payload)
      return action.call(this, ctx, payload)
    }
  }
}

export function setReactiveObjectKey (obj, key, value) {
  if (!(key in obj)) {
    Vue.set(obj, key, value)
  } else {
    obj[key] = value
  }
}

/**
 * Create a new object with given props extracted from a source object.
 * @param {Object} source - source object
 * @param {String[]} propNames - list of properties to recover from source
 * @returns {Object?} null if none of the required props are defined in source.
 */
export function extractProperties (source, propNames) {
  var result = {}
  var empty = true
  for (const name of propNames) {
    const value = source[name]
    if (value !== undefined) {
      empty = false
      result[name] = value
    }
  }
  return empty ? null : result
}

/** @returns {String} name of value's type constructor */
export function getTypeName (value) {
  const typeString = Object.prototype.toString.call(value)
  // "[jsBaseType actualType]" => "actualType" e.g. "[object Object]" => "Object"
  return typeString.split(' ')[1].slice(0, -1)
}

export const isObject = value => Object.prototype.toString.call(value) === '[object Object]'
export const isBoolean = value => typeof value === 'boolean'
export const isDate = value => Object.prototype.toString.call(value) === '[object Date]'
export const isFile = value => Object.prototype.toString.call(value) === '[object File]'
export const isId = value => Number.isInteger(value) && value > 0

/** @throws {Error} if guard(value) is not true */
export function throwIfNot (guard, value) {
  if (guard(value) !== true) {
    var prettyValue
    try {
      prettyValue = JSON.stringify(value)
    } catch (e) {
      console.warn("[Warn] Couldn't serialize value because of the following error:", e)
    }
    const msg = process.env.NODE_ENV === 'production'
      ? 'A precondition has failed and the action could not be completed'
      : `${guard.name}(${prettyValue}: ${typeof value}) has failed`
    throw new Error(msg)
  }
}

/**
 * Remove items matching predicate from array. Array is modified in place.
 * @template T
 * @param {Array<T>} array
 * @param {(item: T) => any} predicate
 */
export function removeFrom (array, predicate) {
  for (var i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      array.splice(i, 1)
    }
  }
}

/**
 * Tell if 2 arrays do not contain the same items in the same order.
 * @template T
 * @param {T[]} a - array to compare from
 * @param {T[]} b - array to compare to
 * @param {string?} key - optional key of object to check in array items
 * @returns {boolean}
 */
export function arraysDiffer (a, b, key = null) {
  const differ = key === null
    ? (item, i) => item !== b[i] // compare by plain value
    : typeof key === 'function'
      ? (item, i) => key(item, b[i]) // compare by function
      : (item, i) => item[key] !== b[i][key] // compare by property with direct access
  return a.length !== b.length || a.some(differ)
}

/**
 * Equivalent to javascript's builtin Array.map except it works with every
 * iterables, including generators.
 * @template T
 * @param {Iterable<T>} iterable
 * @param {(item: T) => any} callback
 */
export function map (iterable, callback) {
  const result = []
  for (const item of iterable) {
    result.push(callback(item))
  }
  return result
}

/**
 * Produce an iterator of `object`'s truthy values (skip falsy values).
 * If a specific key is given the iterator only tries the value at this key.
 * @param {Object<string, any>} object
 * @param {string} key
 */
export function * iterObject (object, key = null) {
  if (key) {
    if (object[key]) yield object[key]
  } else {
    for (const k in object) {
      if (object[k]) yield object[k]
    }
  }
}

/**
 * Merge properties of objects recursively.
 * If type of value is not plain object, simply override.
 */
export function mergeObjects (ref, changes) {
  for (const k in changes) {
    if (isObject(ref[k]) && isObject(changes[k])) {
      mergeObjects(ref[k], changes[k])
    } else {
      ref[k] = changes[k]
    }
  }
}

/**
 * Variant of mergeObjects where only properties already present in `ref`
 * are updated with the matching properties of `patch`.
 * This variant is guaranted to preserve the original object's structure.
 * It skips missing properties, and it skips `patch` properties that would
 * override objects in `ref`.
 */
export function patchObject (ref, patch) {
  for (const k in patch) {
    if (isObject(ref[k])) {
      if (!isObject(patch[k])) {
        console.info(`Object ${k} of ref would be destroyed. Skipped (not patched).`)
        continue // do not break `ref`'s structure
      }
      patchObject(ref[k], patch[k])
    } else if (k in ref) {
      ref[k] = patch[k]
    } else {
      console.info(`Property ${k} not found in ref. Skipped (not patched).`)
    }
  }
}

export function deepCopy (object) {
  return JSON.parse(JSON.stringify(object))
}

const sizeLabels = ['o', 'ko', 'Mo', 'Go']

export function octets (size) {
  let i = 0
  while (Math.abs(size) >= 1024 && i < sizeLabels.length - 1) {
    size /= 1024
    i++
  }
  return `${size.toFixed(i > 1 ? 2 : 0)}${sizeLabels[i]}`.replace('.', ',')
}

export function hasNoDuplicates (list) {
  var i, j
  const length = list.length
  for (i = 0; i < length - 1; i++) {
    for (j = i + 1; j < length; j++) {
      if (list[i] === list[j]) return false
    }
  }
  return true
}

/**
 * Find index in array arr based on fCmp compare function.
 * fCmp is a function of arr[i] so that fCmp(arr[i]) is a number.
 */
export function findIndex (arr, fCmp) {
  let idxMin = 0
  let idxMax = arr.length
  let idx = (idxMin + idxMax) >> 1
  while (idxMin < idxMax) {
    idx = (idxMin + idxMax) >> 1
    const cmp = fCmp(arr[idx])
    if (cmp > 0) {
      idxMax = idx
    } else if (cmp < 0) {
      idx++
      idxMin = idx
    } else {
      idxMin = idxMax
    }
  }
  return idx
}
