<template>
  <div class="flex items-center bg-primary-50 text-gray-700 border-b border-primary-200 py-2">
    <div class="flex-shrink-0 w-6 h-6 ml-2">
      <ArchiveSolid />
    </div>
    <div class="flex-grow ml-2 text-sm">{{ label || $t('This resource is archived') }}</div>
    <div v-if="actionVisible" class="flex-shrink-0 text-sm mr-6">
      <button type="button" class="underline" @click="$emit('restore')">{{ $t('Restore') }}</button>
    </div>
  </div>
</template>

<script>
import ArchiveSolid from '~/components/icons/ArchiveSolid'

export default {
  name: 'ArchivedBar',

  components: {
    ArchiveSolid,
  },

  props: {
    label: { type: String, required: false, default: '' },
    actionVisible: { type: Boolean, required: false, default: false },
  },

  mounted () {
    this.$emit('heightChanged', this.$el.getBoundingClientRect().height)
  },

  beforeDestroy () {
    this.$emit('heightChanged', 0)
  },
}
</script>
