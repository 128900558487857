/**
 * Sets current log context in the store.
 *
 * REQUIREMENTS:
 *   - Must be called after 'auth' middleware.
 *   - Must be called after 'workspace' middleware.
 *   - Must be called after 'project' middleware.
 *   - The route must contain logid.
 */
export default {
  async enter ({ store, params, error }) {
    console.info('[middleware::context.log.enter]')
    const logId = Number(params.logid)
    if (!logId) {
      return error({ statusCode: 404, message: 'Missing doc id' })
    }
    await store.dispatch('log/setCurrentLog', logId)
  },

  async leave ({ store, params }) {
    if (!params.logid) {
      console.info('[middleware::context.log.leave]')
      await store.dispatch('log/unsetCurrentLog')
    }
  },
}
