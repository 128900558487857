var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group relative flex items-center hover:bg-primary-900 rounded p-1",class:{'bg-primary-800': _vm.isCurrentRoute}},[_c('EmojiPicker',{staticClass:"flex-shrink-0",attrs:{"value":_vm.project.emoji},on:{"input":function($event){return _vm.$store.dispatch('project/updateSettings', {
      groupId: _vm.groupId,
      emoji: $event,
      projectId: _vm.project.id,
      group_id: _vm.groupId,
    })}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var onClick = ref.onClick;
return [_c('EmojiButton',{staticClass:"block relative z-10 w-6 h-6 hover:bg-primary-800 text-primary-200 rounded leading-none",attrs:{"emoji":_vm.project.emoji,"disabled":!_vm.canEdit,"iconClass":"w-6 p-2px"},on:{"click":onClick}})]}}])}),_vm._v(" "),_c('nuxt-link',{staticClass:"group flex-grow truncate outline-none ml-2 flex items-center",attrs:{"to":_vm.projectPath}},[_c('div',{staticClass:"absolute inset-0 w-full h-full group-focus:shadow-border-primary-200 rounded"}),_vm._v(" "),_c('div',{staticClass:"items-center text-sm font-bold truncate"},[_vm._v(_vm._s(_vm.project.name))])]),_vm._v(" "),_c('div',{staticClass:"flex items-center flex-shrink-0 text-primary-300"},[(_vm.project.is_archived)?_c('div',{staticClass:"w-6",staticStyle:{"padding":"5px"}},[_c('ArchiveSolid')],1):_vm._e(),_vm._v(" "),(!_vm.project.is_archived && _vm.project.share_token)?_c('div',{staticClass:"w-6",staticStyle:{"padding":"5px"}},[_c('GlobeAltSolid')],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"absolute",staticStyle:{"right":"5px"}},[(_vm.otherActions.length)?_c('ActionDropdown',{staticClass:"h-6 ml-1",attrs:{"actions":_vm.otherActions,"fromRight":""},on:{"dropdownAction":_vm.executeDropdownAction}},[_c('button',{ref:"dropdownBtn",staticClass:"\n          w-6 bg-primary-800 hover:bg-primary-700 rounded p-2px\n          opacity-0 group-hover:opacity-100 focus:opacity-100\n        ",on:{"click":function($event){return _vm.$refs.dropdownBtn.focus()}}},[_c('DotsVerticalSolid')],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }