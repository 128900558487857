<template>
  <div
    class="group relative flex items-center hover:bg-primary-900 rounded p-1"
    :class="{'bg-primary-800': isCurrentRoute}"
  >
    <!-- Left: Emoji -->
    <EmojiPicker
      :value="project.emoji"
      class="flex-shrink-0"
      @input="$store.dispatch('project/updateSettings', {
        groupId,
        emoji: $event,
        projectId: project.id,
        group_id: groupId,
      })"
    >
      <template v-slot:default="{ onClick }">
        <EmojiButton
          :emoji="project.emoji"
          :disabled="!canEdit"
          iconClass="w-6 p-2px"
          class="block relative z-10 w-6 h-6 hover:bg-primary-800 text-primary-200 rounded leading-none"
          @click="onClick"
        />
      </template>
    </EmojiPicker>

    <!-- Left: Name -->
    <nuxt-link :to="projectPath" class="group flex-grow truncate outline-none ml-2 flex items-center">
      <div class="absolute inset-0 w-full h-full group-focus:shadow-border-primary-200 rounded" />
      <div class="items-center text-sm font-bold truncate">{{ project.name }}</div>
    </nuxt-link>

    <!-- Archived icon -->
    <div class="flex items-center flex-shrink-0 text-primary-300">
      <div
        v-if="project.is_archived"
        class="w-6"
        style="padding:5px;"
      >
        <ArchiveSolid />
      </div>
      <div v-if="!project.is_archived && project.share_token" class="w-6" style="padding:5px;">
        <GlobeAltSolid />
      </div>
    </div>

    <!-- Right: Other actions dropdown -->
    <div class="absolute" style="right: 5px">
      <ActionDropdown
        v-if="otherActions.length"
        :actions="otherActions"
        fromRight
        class="h-6 ml-1"
        @dropdownAction="executeDropdownAction"
      >
        <button
          ref="dropdownBtn"
          class="
            w-6 bg-primary-800 hover:bg-primary-700 rounded p-2px
            opacity-0 group-hover:opacity-100 focus:opacity-100
          "
          @click="$refs.dropdownBtn.focus()"
        >
          <DotsVerticalSolid />
          </svg>
        </button>
      </ActionDropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { isAtLeastEdit, isAtLeastInvite, isAtLeastMember } from '~/utils/rights'
import { routeIncludes } from '~/utils/route'

import ActionDropdown from '~/components/dropdowns/ActionDropdown'
import ArchiveSolid from '~/components/icons/ArchiveSolid'
import DotsVerticalSolid from "~/components/icons/DotsVerticalSolid"
import EmojiButton from '~/components/actions/EmojiButton'
import EmojiPicker from '~/components/dropdowns/EmojiPicker'
import GlobeAltSolid from "~/components/icons/GlobeAltSolid"
import ConfirmModal from '~/components/modals/ConfirmModal'
import ProjectArchiveModal from "~/components/modals/ProjectArchiveModal"

export default {
  name: 'ProjectActionLink',

  components: {
    ActionDropdown,
    ArchiveSolid,
    DotsVerticalSolid,
    EmojiButton,
    EmojiPicker,
    GlobeAltSolid,
  },

  props: {
    project: { type: Object, required: true, default: () => ({}) },
    groupId: { type: Number, required: true, default: 0 },
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),

    ...mapGetters('workspace', {
      currentRole: 'currentRole',
    }),

    projectPath () {
      return `/workspace/${this.workspaceId}/project/${this.project.id}`
    },

    isCurrentRoute () {
      return routeIncludes(this.$route, { path: this.projectPath })
    },

    canEdit () {
      return isAtLeastEdit(this.project.current_user_right)
    },

    canInvite () {
      return isAtLeastInvite(this.project.current_user_right)
    },

    isMemberOrHigherRole () {
      const result = isAtLeastMember(this.currentRole)
      return result
    },

    otherActions () {
      const actions = []
      if (this.canEdit) {
        actions.push({ path: `${this.projectPath}/settings`, labelI18n: 'Edit project settings' })
      }
      if (this.canInvite) {
        actions.push({ path: `${this.projectPath}/team?modal=add-users`, labelI18n: 'Add people' })
      }
      if (this.canEdit) {
        if (this.project.is_archived) {
          actions.push({ token: 'RESTORE', labelI18n: 'Restore' })
        } else {
          actions.push({ token: 'ARCHIVE', labelI18n: 'Archive' })
        }
      }
      if (this.isMemberOrHigherRole && this.canEdit && this.canInvite) {
        actions.push({ token: 'DELETE', labelI18n: 'Delete' })
      }
      return actions
    },
  },

  methods: {
    executeDropdownAction (actionToken) {
      switch (actionToken) {
        case 'ARCHIVE':
        case 'RESTORE':
          this.$showModal(ProjectArchiveModal, {
            project: this.project,
            groupId: this.groupId,
            workspaceId: this.workspaceId,
            onConfirm: (result, action) => {
              if (result.data.success) {
                if (action === 'project/restoreProject') {
                  this.$addToast({
                    text: this.$t('Project restored'),
                  })
                } else if (action === 'project/archiveProject') {
                  this.$addToast({
                    text: this.$t('Project archived'),
                  })
                }
              }
            },
          })
          break
        case 'DELETE':
          this.$showModal(ConfirmModal, {
            title: this.$t('Delete project'),
            description: this.$t(`You're about to delete Project: "${this.project.name}" and its content.\n\nThis action cannot be undone. We will delete all associated data immediatly after your confirmation. Be very careful.\n\nAre you sure you wish to continue?`),
            confirmLabel: this.$t('Yes, delete'),
            isCritical: true,
            onConfirm: async () => {
              const result = await this.$store.dispatch('project/deleteProject', {
                projectId: this.project.id,
                groupId: this.groupId,
              })
              if (result.data.success) {
                this.$addToast({
                  text: this.$t('Project deleted'),
                })
              }
              this.$closeModal()
            },
          })
          break

        default: throw new Error(`Unexpected action token: ${actionToken}`)
      }
    },
  },
}
</script>
