<template>
  <!-- Workspace Menu -->
  <OpenableSidePanel
    :opened="opened"
    class="z-30 h-screen"
    @openedChanged="$emit('openedChanged', $event)"
  >
    <div class="relative h-full bg-primary text-white border-r-2 border-primary-800 flex flex-col">
      <!-- Menu: Header -->
      <MainMenuHeader class="flex-shrink-0 h-topbar" />

      <!-- Menu show/hide button -->
      <div class="absolute right-0 h-topbar flex items-center pr-3">
        <button
          class="rounded w-6 h-6 transform duration-200 ease-in-out p-2px"
          :class="opened
            ? 'text-white bg-primary-900 hover:bg-primary-700'
            : 'text-gray-900 hover:bg-gray-30 translate-x-12 md:translate-x-16'
          "
          style="transition-property: transform,background-color,color;"
          @click="$emit('openedChanged', !opened)"
        >
          <MenuSolid />
        </button>
      </div>

      <!-- Menu: Workspace -->
      <MainMenuWorkspace class="flex-shrink-0" />

      <!-- Menu: Groups -->
      <div class="flex-grow border-b-2 border-primary-900 overflow-y-auto px-2 py-4">
        <!-- Group section header (All groups row) -->
        <MenuTreeHeader
          :dropdownActions="menuGroupFilterActions"
          :label="menuGroupsLabel"
          @dropdownAction="executeDropdownAction"
        >
          <button
            v-if="currentlyMember"
            v-tooltip.slow="$t('Create a new group')"
            type="button"
            class="flex-shrink-0 w-6 bg-orange hover:bg-orange-800 text-white rounded p-2px"
            @click="createGroup"
          >
            <PlusSolid />
          </button>
        </MenuTreeHeader>

        <!-- Create group link (only if no group names) -->
        <div v-if="!menuGroups.length" class="pl-1 mb-2">
          <div v-if="menuGroupsFilter === MenuGroupFilter.ARCHIVED_GROUPS.value" class="text-sm ml-6">
            <span>{{ $t('No archived groups') }}</span>
          </div>
          <button v-else-if="currentlyMember" type="button" @click="createGroup">
            <AddAction :label="$t('Create group')" />
          </button>
        </div>

        <!-- Groups (only if group names) -->
        <MainMenuGroup
          v-for="group in menuGroups"
          :key="group.id"
          :group="group"
          :detail="groupDetails[group.id]"
          :isOpened="openedInMenu[group.id]"
          class="mb-1"
        />
      </div>

      <!-- Menu footer -->
      <footer class="flex-shrink-0 p-5">
        <div class="flex items-end">
          <nuxt-link to="/" class="block rounded p-1">
            <img
              src="/logos/flightlog-app-logo__white-and-text-white.png"
              alt="Flightlog logo"
              class="h-6"
            >
          </nuxt-link>
          <div class="text-xs text-primary-100 ml-2 mb-2px">{{ version }}</div>
        </div>
      </footer>
    </div>
  </OpenableSidePanel>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { MenuGroupFilter, getFilterLabel } from '~/utils/constants'

import AddAction from '~/components/actions/AddAction'
import ConfirmModal from '~/components/modals/ConfirmModal'
import MainMenuGroup from '~/components/partials/MainMenuGroup'
import MainMenuHeader from '~/components/partials/MainMenuHeader'
import MainMenuWorkspace from '~/components/partials/MainMenuWorkspace'
import MenuSolid from "~/components/icons/MenuSolid"
import MenuTreeHeader from '~/components/menus/items/MenuTreeHeader'
import OpenableSidePanel from '~/components/menus/OpenableSidePanel'
import PlusSolid from "~/components/icons/PlusSolid"

const menuGroupFilterActions = Object.keys(MenuGroupFilter).map(token => ({
  token,
  labelI18n: MenuGroupFilter[token].labelI18n,
}))

export default {
  name: 'MainMenu',

  components: {
    AddAction,
    MainMenuGroup,
    MainMenuHeader,
    MainMenuWorkspace,
    MenuSolid,
    MenuTreeHeader,
    OpenableSidePanel,
    PlusSolid,
  },

  props: {
    opened: { type: Boolean, required: false, default: undefined },
  },

  data () {
    return {
      MenuGroupFilter,
      menuGroupFilterActions,
      version: this.$config.VERSION_NAME,
    }
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
      currentWorkspace: 'currentWorkspace',
    }),

    ...mapState('group', [
      'menuGroups',
      'menuGroupsFilter',
      'groupDetails',
      'openedInMenu',
    ]),

    ...mapGetters('workspace', [
      'currentlyMember',
    ]),

    menuGroupsLabel () {
      return this.$t(getFilterLabel(MenuGroupFilter, this.menuGroupsFilter))
    },
  },

  methods: {
    createGroup () {
      if (!this.currentWorkspace.can_add_groups) {
        this.$showModal(ConfirmModal, {
          title: this.$t(`Can't create more groups`),
          description: this.$t(`You have reached the maximum number of groups you can add to your workspace with a free plan. You need to upgrade your subscription plan to add more groups.`),
          confirmLabel: this.$t('Upgrade'),
          onConfirm: () => {
            this.$router.push(`/workspace/${this.workspaceId}/settings/upgrade`)
            this.$closeModal()
          },
        })
      } else {
        this.$router.push(`/workspace/${this.workspaceId}/group/create`)
      }
    },
    async executeDropdownAction (actionToken) {
      const value = MenuGroupFilter[actionToken].value
      await this.$store.dispatch('group/updateMenuGroupsFilter', value)
    },
  },
}
</script>
