<template>
  <div
    class="Dropdown-onFocus relative"
    :class="{'focus-kept': focusKept}"
    @mousedown="keepFocusOnMouseDown"
  >
    <!-- Dropdown trigger -->
    <slot name="button">
      <!-- Dropdown button -->
      <button ref="btn" :disabled="disabled" class="block rounded" @click="e => $refs.btn.focus()">
        <slot name="buttonContent" />
      </button>
    </slot>

    <!-- Dropdown content -->
    <div
      v-if="!disabled"
      class="
        Dropdown-content absolute z-20
        bg-white text-gray-900 whitespace-no-wrap rounded shadow-dropdown py-2 mt-1
      "
      :class="fromRight ? 'right-0' : 'left-0'"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseDropdown',

  props: {
    disabled: { type: Boolean, required: false, default: false },
    fromRight: { type: Boolean, required: false, default: false },
  },

  data () {
    return {
      focusKept: false,
    }
  },

  methods: {
    keepFocusOnMouseDown (event) {
      this.focusKept = true
      const reset = () => {
        this.focusKept = false
        document.body.removeEventListener('mouseup', reset)
        document.body.removeEventListener('mouseleave', reset)
      }
      document.body.addEventListener('mouseup', reset)
      document.body.addEventListener('mouseleave', reset)
    },
  },
}
</script>
