/**
 * Sets current group context in the store.
 *
 * REQUIREMENTS:
 *   - Must be called after 'auth' middleware.
 *   - Must be called after 'workspace' middleware.
 *   - The route must contain groupid.
 */
export default {
  async enter ({ store, params, error }) {
    console.info('[middleware::context.group.enter]')
    const groupId = Number(params.groupid)
    if (!groupId) {
      return error({ statusCode: 404, message: 'Missing group id' })
    }
    await store.dispatch('group/setCurrentGroup', groupId)
  },

  async leave ({ store, params }) {
    if (!params.groupid) {
      console.info('[middleware::context.group.leave]')
      await store.dispatch('group/unsetCurrentGroup')
    }
  },
}
