import { activationError } from '~/utils/errors'

/**
* This middleware is meant for the profile part of onboarding.
* It ensures there is a valid activation token in the query
*/
export default async function signupProfile ({ store, query, error }) {
  console.info('[middleware::signupProfile]')
  if (!query.token) return error({ statusCode: 404 })

  try {
    // Check activation token validity before redirecting to signup-profile
    const { data } = await store.$axios.get(`/users/signup/profile/${query.token}`)
    store.$activation.set(query.token, 'signupEmail', data.detail)
  } catch (e) {
    console.error(e)
    return error(activationError(e))
  }
}
