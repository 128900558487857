<template>
  <div
    class="absolute right-0 flex flex-col-reverse overflow-scroll Toast"
  >
    <transition-group
      name="toast"
      tag="ul"
    >
      <!-- TODO use transition group -->
      <li
        v-for="toast in toasts"
        :key="toast.id"
        class="toast-list-item m-2"
      >
        <div
          class="flex justify-between px-6 py-4 bg-white rounded border border-gray-300 shadow hover:bg-gray-50"
        >
          <div>
            <p>{{ toast.text }}</p>
          </div>
          <div class="ml-6 h-6 w-6">
            <a class="h-full cursor-pointer" @click="close(toast.id)">
              <XSolid />
            </a>
          </div>
        </div>
      </li>
    </transition-group>
  </div>
</template>
<script>
import XSolid from "@/components/icons/XSolid"

export default {
  name: "ToastsContainer",
  components: {
    XSolid,
  },
  props: {
    toasts: { type: Array, required: true, default: () => [] },
  },
  data () {
    return {
      animateData: true,
    }
  },
  computed: {
    claimForWatcher () {
      return Array(this.toasts.length)
    },
  },
  watch: {
    claimForWatcher: {
      handler (newVal, oldVal) {
        this.animateData = oldVal.length < newVal.length
      },
    },
  },
  methods: {
    close (id) {
      this.animateData = false
      this.$deleteToast(id)
    },
    isLastToast (toast, toasts) {
      return toast === toasts[toasts.length - 1]
    },
  },
}
</script>
<style>
.toast-list-item {
  transition: all;
  transition-duration: 350ms;
  transition-timing-function: ease-in-out;
  position: relative;
}
.toast-enter, .toast-leave-to {
  transform: translateX(100%);
}
.toast-leave, .toast-enter-to {
  transform: translateX(0);
}
</style>
