import { isDate } from "~/utils/common"

export const SecondsPerMinute = 60
export const SecondsPerHour = 60 * SecondsPerMinute
export const SecondsPerDay = 24 * SecondsPerHour
export const SecondsPerWeek = 7 * SecondsPerDay
export const SecondsPerMonth = 30.4375 * SecondsPerDay
export const SecondsPerYear = 365.25 * SecondsPerDay

/** @returns {Boolean} */
export function isValidDate (value) {
  return isDate(value) && isFinite(value)
}

/** @returns {String} */
export function relativeDate (date) {
  if (typeof date === 'string') date = new Date(date)
  if (!isValidDate(date)) return

  // XXX: Clean this up if you can!
  const seconds = Math.round((Date.now() - date.getTime()) / 1000)
  if (seconds < 0) return "Back to the future"

  if (seconds < 60) return 'Just now'
  const minutes = Math.floor(seconds / SecondsPerMinute)
  if (minutes === 1) return '1 minute ago'

  if (minutes < 60) return `${minutes} minutes ago`
  const hours = Math.floor(seconds / SecondsPerHour)
  if (hours === 1) return `1 hour ago`

  if (hours < 24) return `${hours} hours ago`
  const days = Math.floor(seconds / SecondsPerDay)
  if (days === 1) return `yesterday`

  if (days < 7) return `${days} days ago`
  const weeks = Math.floor(seconds / SecondsPerWeek)
  if (weeks === 1) return `1 week ago`

  if (weeks < 5) return `${weeks} weeks ago`
  const months = Math.floor(seconds / SecondsPerMonth)
  if (months === 1) return `1 month ago`

  if (months < 12) return `${months} months ago`
  const years = Math.floor(seconds / SecondsPerYear)
  if (years === 1) return `1 year ago`

  if (years < 5) return `${years} years ago`
  return `A long long time ago`
}
