<template>
  <div>
    <!-- Label -->
    <label v-if="label" class="block font-bold text-grey mb-2 mr-2">{{ label }}</label>

    <!-- Input -->
    <div class="relative">
      <input
        ref="input"
        v-model="inputValue"
        :type="passwordVisible ? 'text' : 'password'"
        :placeholder="placeholder || label || $t('Password')"
        :autofocus="autofocus"
        v-bind="$attrs"
        class="InputBox w-full"
        :class="{'invalid': v.$error}"
        v-on="listenersExceptInput"
      >
      <!-- Right icon -->
      <div ref="toggleIcon" class="absolute top-0 right-0 h-full w-12 flex justify-center items-center px-3">
        <button
          type="button"
          class="w-6"
          :class="v.$error ? 'text-red' : 'text-gray-400'"
          @click="passwordVisible = !passwordVisible"
        >
          <EyeOffSolid v-if="passwordVisible" />
          <EyeSolid v-else />
        </button>
      </div>
    </div>

    <!-- Input errors -->
    <InputError :v="v" :vMessages="vMessages" />

    <!--
      IMPORTANT: this hint is directly related to a business rule.
      Changing this wording implies updating validation logic too!
    -->
    <div v-if="showHints" class="text-gray-400 text-sm ml-1 mt-1">
      <span>{{ $t('Use at least 8 characters') }}</span>
    </div>
  </div>
</template>

<script>
import EyeOffSolid from "~/components/icons/EyeOffSolid"
import EyeSolid from "~/components/icons/EyeSolid"
import InputError from '~/components/forms/InputError'

export default {
  name: 'PasswordInput',

  components: {
    EyeOffSolid,
    EyeSolid,
    InputError,
  },

  props: {
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    value: { type: String, required: false, default: '' },
    v: { type: Object, required: false, default: () => ({}) },
    vMessages: { type: Object, required: false, default: () => ({}) },
    autofocus: { type: Boolean, required: false, default: false },
    showHints: { type: Boolean, required: false, default: false },
  },

  data () {
    return {
      passwordVisible: false,
    }
  },

  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    listenersExceptInput () {
      const { input: _, ...otherListeners } = this.$listeners
      return otherListeners
    },
  },

  mounted () {
    if (this.autofocus) this.$refs.input.focus()
    const iconWidth = this.$refs.toggleIcon.getBoundingClientRect().width
    this.$refs.input.style.paddingRight = `${iconWidth}px`
  },
}
</script>
