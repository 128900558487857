<template>
  <BaseModal :title="$t('The Workspace plan has been updated')" noEasyClose>
    <template v-slot:body>
      <div class="max-w-full" style="width: 496px">
        <p class="whitespace-pre-line mb-6">
          <span>{{ $t(`The workspace's billing plan has been updated by ${notifier}.`) }}</span>
          <span>{{ $t('You need to reload the app to properly synchronize your new environment.') }}</span>
        </p>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex flex-col-reverse xs:flex-row justify-end items-stretch xs:items-center pt-4 -m-2 xs:m-0 xs:ml-auto">
        <button
          class="Btn Btn-filled-red ml-3 m-2 xs:m-0 xs:ml-3"
          @click="reloadApp"
        >
          {{ $t('Reload Flightlog') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '~/components/modals/BaseModal'

export default {
  name: 'AppReloadModal',

  components: {
    BaseModal,
  },

  props: {
    notifierFullname: { type: String, required: false, default: '' },
  },

  computed: {
    notifier () {
      return this.notifierFullname || this.$t('one of your owners')
    },
  },

  methods: {
    reloadApp () {
      window.location.reload()
    },
  },
}
</script>
