<template>
  <div>
    <label v-if="label" class="block font-bold mb-2">{{ label }}</label>

    <div
      class="h-40 border-2 border-gray-300 rounded overflow-y-auto p-2"
      style="overscroll-behavior: contain"
    >
      <div v-for="g in groupsOfProjects" :key="g.id" class="mb-2">
        <!-- Group collapse -->
        <button class="w-full h-8 block p-1" @click="toggleOpen(g.id)">
          <div class="flex items-center">
            <!-- Toggle icon -->
            <div
              class="
                flex-shrink-0 w-6 rounded p-2px mr-2
                transform rotate-0 transition-transform duration-200 ease-in-out
              "
              :class="{'rotate-90': !!openedGroups[g.id]}"
            >
              <ChevronRightSolid />
            </div>

            <!-- Group name -->
            <div class="flex-grow text-left text-sm font-bold truncate">{{ g.name }}</div>
          </div>
        </button>

        <!-- Projects -->
        <template v-if="openedGroups[g.id]">
          <button
            v-for="p in g.projects"
            :key="p.id"
            class="w-full h-8 block border rounded p-1 mt-2"
            :class="value === p.id
              ? 'bg-primary-100 border-primary-900'
              : 'bg-white hover:bg-gray-50 border-white'
            "
            @click="$emit('input', value === p.id ? null : p.id)"
          >
            <div class="h-full flex items-center ml-6">
              <EmojiButton
                disabled
                :emoji="p.emoji"
                iconClass="w-6 p-2px"
                class="flex-shrink-0 w-8 text-lg text-gray-400 mr-2"
              />
              <div class="flex-grow text-left text-sm font-bold truncate">{{ p.name }}</div>
            </div>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { setReactiveObjectKey } from '~/utils/common'

import ChevronRightSolid from "~/components/icons/ChevronRightSolid"
import EmojiButton from '~/components/actions/EmojiButton'

export default {
  name: 'ProjectInput',

  components: {
    ChevronRightSolid,
    EmojiButton,
  },

  props: {
    value: { type: Number, required: false, default: null },
    groupsOfProjects: { type: Array, required: true, default: () => [] },
    label: { type: String, required: true, default: '' },
  },

  data () {
    return {
      openedGroups: {},
    }
  },

  methods: {
    toggleOpen (groupId) {
      setReactiveObjectKey(this.openedGroups, groupId, !this.openedGroups[groupId])
    },
  },
}
</script>
