<template>
  <!-- Wrapper -->
  <div class="relative">
    <!-- Top bar -->
    <DefaultTopBar
      :signinButton="signinButton"
      :signupButton="signupButton"
      class="fixed z-10 top-0"
    />

    <!-- Page content -->
    <div class="h-screen pt-topbar overflow-y-auto px-6 lg:px-0">
      <nuxt />
    </div>
  </div>
</template>

<script>
import DefaultTopBar from '~/components/bars/DefaultTopBar'

export default {
  name: 'DefaultLayout',

  components: {
    DefaultTopBar,
  },

  data () {
    return {
      signinButton: undefined,
      signupButton: undefined,
    }
  },

  provide () {
    return {
      layoutContext: this,
    }
  },
}
</script>
