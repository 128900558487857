<template>
  <div>
    <DraggableTreeNode
      v-for="node in tree.children"
      :key="node.path"
      :node="node"
      :depth="1"
      :draggedNode="draggedNode"
      :targetNode="targetNode"
      :targetArea="targetArea"
      :updateDraggedNode="updateDraggedNode"
      :updateTargetNode="updateTargetNode"
      :updateTargetArea="updateTargetAreaLazy"
      :moveNode="moveNode"
      :resetDrag="resetDrag"
      :resetTarget="resetTarget"
    >
      <template v-slot:node="scopedSlot">
        <slot name="node" v-bind="scopedSlot" />
      </template>
    </DraggableTreeNode>
  </div>
</template>

<script>
import { isStrictlyInside } from '~/utils/dom'
import { isNode, resolveNodeArea } from '~/utils/treenode'

import DraggableTreeNode from '@/components/lists/DraggableTreeNode'

export default {
  name: 'DraggableTree',

  components: {
    DraggableTreeNode,
  },

  props: {
    tree: { type: Object, required: true, default: () => ({}) },
    linkPrefix: { type: String, required: true, default: '' },
    canEdit: { type: Boolean, required: false, default: false },
    isDocEditable: { type: Function, required: false, default: () => true },
  },

  data () {
    return {
      draggedNode: undefined,
      targetNode: undefined,
      targetArea: '',
      targetEl: undefined,
      previousMouseY: undefined,
    }
  },

  methods: {
    updateDraggedNode (event, node) {
      if (!this.canEdit) return event.preventDefault()
      if (!this.isDocEditable(node.item)) return event.preventDefault()

      if (!isNode(node)) throw new Error('Not a valid augmented node')
      this.draggedNode = node
    },

    updateTargetNode (event, node) {
      if (!this.draggedNode) return // Must be currently dragging
      if (node === this.draggedNode) return // dragged node must not be a target
      if (node === this.targetNode) return // no need to update

      this.targetNode = node
      this.targetEl = event.target
      this.updateTargetArea(event.clientY, event.target)
      event.preventDefault()
    },

    updateTargetAreaLazy (event, node) {
      // First do not re evaluate anything if mouseY didn't change, but still preventDefault()
      // because drop event will not be fired if preventDefault() was not called on the last
      // fired dragover event
      if (this.previousMouseY === event.clientY) return event.preventDefault()
      if (!this.draggedNode) return // Must be currently dragging
      if (!this.targetNode) return // Must have a current target
      if (this.targetNode === this.draggedNode) return // ignore currently dragged node
      if (node !== this.targetNode) return // ignore events fired from other nodes (late events)
      this.previousMouseY = event.clientY

      this.updateTargetArea(event.clientY, event.target)
      event.preventDefault()
    },

    updateTargetArea (clientY, element) {
      const { y, height } = element.getBoundingClientRect()
      const area = resolveNodeArea(clientY, y, height)

      if (this.targetArea === area) return // Avoid sending unnecessary events
      this.targetArea = area
    },

    moveNode () {
      if (!this.canEdit || !this.draggedNode || !this.targetNode || !this.targetArea) return

      this.$emit('moveNode', {
        nodePath: this.draggedNode.path,
        targetPath: this.targetNode.path,
        targetArea: this.targetArea,
      })
      this.resetDrag()
      event.preventDefault()
    },

    resetDrag () {
      this.draggedNode = undefined
      this.resetTarget()
    },

    resetTarget (event, node) {
      // There is nothing to reset yet (also prevents breaking next check)
      if (!this.targetNode) return
      // The event can be triggered too late after target update, only reset if still the same
      if (node && node !== this.targetNode) return
      // The event can be triggered by child elements, only reset if outside of the box
      // IMPORTANT: we check if event is strictly inside because for some reasons,
      // dragleave and dragenter events are not registered properly on border pixels when mouse
      // is moving slowly... which causes target nodes to not update properly when leaving
      if (event && this.targetEl && isStrictlyInside(event, this.targetEl)) return

      this.$nextTick(() => {
        this.targetNode = undefined
        this.targetArea = ''
        this.targetEl = null
      })
    },
  },
}
</script>
