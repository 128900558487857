<template>
  <!-- Scrollable container -->
  <div class="fixed inset-0 z-40 w-screen h-screen bg-gray-900 bg-opacity-50 overflow-auto">
    <!-- Vertical container (center short modals, keep top margin for bigger modals) -->
    <div class="w-full min-h-screen flex justify-center items-center py-16" @mousedown.self="setEasyClosable" @click.self="easyClose">
      <!-- Modal -->
      <div class="relative max-w-full bg-white text-gray-900 rounded shadow-xl px-6 py-4" @mousedown="unsetEasyClosable" @mouseup="unsetEasyClosable">
        <!-- Modal header -->
        <header class="w-full flex justify-between items-center mb-4">
          <slot name="header">
            <div class="text-2xl font-extrabold text-left">{{ title || '{ No title }' }}</div>
          </slot>

          <!-- Top right corner close button -->
          <button
            v-if="$listeners.modalClose"
            type="button"
            class="flex-shrink-0 w-6 rounded p-2px"
            @click="$emit('modalClose')"
          >
            <XSolid />
          </button>
        </header>

        <!-- Modal body -->
        <slot name="body" />

        <!-- Modal footer -->
        <footer v-if="$slots.footer" class="mt-4">
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { createFocusTrap } from '~/utils/dom'

import XSolid from "~/components/icons/XSolid"

export default {
  name: 'BaseModal',

  components: {
    XSolid,
  },

  props: {
    title: { type: String, required: false, default: '' },
    noEasyClose: { type: Boolean, required: false, default: false },
  },

  data () {
    return {
      isEasyClosable: false,
    }
  },

  mounted () {
    this.onTabKey = createFocusTrap(this.$el)
    window.addEventListener('keydown', this.onTabKey)
    window.addEventListener('keyup', this.onEscape)
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.onTabKey)
    window.removeEventListener('keyup', this.onEscape)
  },

  methods: {
    setEasyClosable () {
      this.isEasyClosable = true
    },

    unsetEasyClosable () {
      this.isEasyClosable = false
    },

    easyClose () {
      if (this.noEasyClose) return
      if (!this.isEasyClosable) return
      this.$emit('modalClose')
    },

    onEscape (e) {
      if (e.code === 'Escape' || e.keyCode === 27/* IE */) this.easyClose()
    },
  },
}
</script>
