<template>
  <div class="w-screen h-screen bg-gray-900 bg-opacity-50 text-gray-900">
    <!-- Top bar -->
    <FormTopBar :topBarTitle="topBarTitle" @goBack="$router.back()" />
    <!-- Page content -->
    <div class="relative w-screen h-screen pt-topbar overflow-y-auto px-6">
      <div class="w-10/12 mx-auto py-4">
        <nuxt />
      </div>
    </div>
  </div>
</template>

<script>
import FormTopBar from '~/components/bars/FormTopBar'

export default {
  name: 'FullscreenLayout',

  components: {
    FormTopBar,
  },

  data () {
    return {
      topBarTitle: undefined,
    }
  },

  provide () {
    return {
      layoutContext: this,
    }
  },
}
</script>
