/**
 * This middleware can be used in pages where a billing payment method must be
 * associated with the current workspace.
 *
 * REQUIREMENTS:
 *   - Must be called after 'auth' middleware.
 *   - Must be called after 'workspace' middleware.
 *   - Must be called after 'billing' middleware.
 */
export default function paymentMethodRequired ({ store, params, redirect }) {
  console.info('[middleware::paymentMethodRequired]')

  const noPaymentMethod = !store.state.billing.paymentMethod
  if (noPaymentMethod) {
    redirect(`/workspace/${params.wsid}/settings/billing`)
  }
}
