<template>
  <div class="overflow-hidden duration-200 ease-in-out" style="transition-property:height,margin">
    <div ref="errorBox" class="relative flex bg-red-50 border-l-4 border-red rounded p-3">
      <!-- Left: Icon -->
      <div class="flex-shrink-0 w-6 text-red">
        <XCircleSolid />
      </div>

      <!-- Right: Description and actions -->
      <div class="flex-grow ml-2">
        <!-- Title -->
        <div class="text-red">{{ title }}</div>
        <!-- Description -->
        <div v-if="description" class="text-sm whitespace-pre-wrap my-1">{{ description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { isObject } from '~/utils/common'

import XCircleSolid from '~/components/icons/XCircleSolid'

const Kind = {
  NONE: 'no-error',
  // Ideally, these errors should be intercepted but might not
  SERVER_SIMPLE: 'server-simple-error',
  SERVER_COMPLEX: 'server-complex-error',
  // Shouldn't happen in prod (client validation must prevent it)
  VALIDATION: 'validation-error',
  STRIPE: 'stripe-error',
  // Unhandled errors that indicate development mistakes
  UNKNOWN: 'unknown-error',
}

export default {
  name: 'ErrorCard',

  components: {
    XCircleSolid,
  },

  props: {
    error: { type: Error, required: false, default: undefined },
  },

  computed: {
    errorDetail () {
      const e = this.error
      if (!e) return ''
      if (this.$nuxt.context.isDev) console.error('[ErrorCard]', e)
      return e.response ? e.response.data.detail : e.message
    },

    errorKind () {
      if (!this.error) return Kind.NONE
      if (this.error.response && this.errorDetail) {
        if (Array.isArray(this.errorDetail)) return Kind.VALIDATION
        if (isObject(this.errorDetail)) return Kind.SERVER_COMPLEX
        return Kind.SERVER_SIMPLE
      }
      if (this.error.type && this.error.type === 'Stripe') return Kind.STRIPE
      // This means development error requiring investigation.
      return undefined
    },

    title () {
      switch (this.errorKind) {
        case Kind.NONE: return ''
        case Kind.SERVER_SIMPLE: return this.$t(this.errorDetail)
        case Kind.SERVER_COMPLEX: return this.$t('Error')
        case Kind.VALIDATION: return this.$t('Form Error')
        case Kind.STRIPE: return this.$t(this.error.name)
        case Kind.UNKNOWN:
        default:
          return this.$t('Unexpected Error')
      }
    },

    description () {
      switch (this.errorKind) {
        case Kind.NONE: return ''
        case Kind.SERVER_SIMPLE: return ''
        case Kind.SERVER_COMPLEX: return prettyComplexError(this.errorDetail)
        case Kind.VALIDATION: return prettyValidationError(this.errorDetail)
        case Kind.STRIPE: return this.$t(this.error.messageStripe)
        case Kind.UNKNOWN:
        default:
          return this.$t('An unknown error has occured. Please contact support for help.')
      }
    },
  },

  watch: {
    error: 'applyVisualState',
  },

  mounted () {
    this.applyVisualState()
  },

  methods: {
    applyVisualState () {
      const root = this.$el
      const errorBox = this.$refs.errorBox
      if (!root || !errorBox) {
        throw new Error('Elements are not all ready. Should be executed after mounted hook')
      }
      if (this.error) {
        window.requestAnimationFrame(() => {
          const height = `${this.$refs.errorBox.getBoundingClientRect().height}px`
          root.style.height = height
          root.style.removeProperty('margin') // removes margin added by hideErrorCard
          root.classList.add('Shaker')
          window.setTimeout(() => root.classList.remove('Shaker'), 500)
        })
      } else {
        root.style.height = 0
        root.style.margin = 0 // only relevant if a margin is set on the component
      }
    },
  },
}

function prettyValidationError (detail) {
  return detail.map(d => `${d.loc.join('.')}: ${d.msg}`).join('\n')
}

function prettyComplexError (detail) {
  return Object.keys(detail).map(k => `${k}: ${detail[k]}`).join('\n')
}
</script>
