<template>
  <BaseModal :title="$t('Your authentication has expired')" noEasyClose>
    <template v-slot:body>
      <div class="max-w-full py-6" style="width: 496px">
        <SigninForm @signin="$emit('modalClose')" />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '~/components/modals/BaseModal'
import SigninForm from '~/components/forms/SigninForm.vue'

export default {
  name: 'SignedOutModal',

  components: {
    BaseModal,
    SigninForm,
  },
}
</script>
