<template>
  <button
    v-bind="$attrs"
    :type="type"
    :disabled="disabled || loading"
    :class="{
      'cursor-pointer': !disabled && !loading,
      'cursor-wait relative opacity-75 loading': loading,
      'cursor-not-allowed': disabled,
    }"
    v-on="$listeners"
  >
    <!-- Button content -->
    <div :class="{'opacity-25': loading}">
      <slot>{{ text || 'No text' }}</slot>
    </div>
    <!-- Loading effect -->
    <div v-if="loading" class="absolute inset-0 flex justify-center items-center">
      <svg viewBox="0 0 24 24" height="75%">
        <circle
          cx="12"
          cy="12"
          r="8"
          stroke-dasharray="2 23"
          stroke-width="8"
          fill="transparent"
          stroke-linecap="round"
          class="stroke-current opacity-75 origin-center"
          style="animation: double-spin 1.8s infinite cubic-bezier(0.4, 0.2, 0.6, 0.8)"
        />
      </svg>
    </div>
  </button>
</template>

<script>

export default {
  name: 'SmartButton',

  props: {
    text: { type: String, required: false, default: '' },
    type: { type: String, required: false, default: 'button' },
    loading: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
  },
}
</script>

<style>
@keyframes double-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(720deg); }
}
</style>
