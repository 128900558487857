<template>
  <div class="h-screen p-topbar overflow-y-auto">
    <div class="flex flex-col justify-center items-center">
      <!-- Illustration -->
      <img :src="illustration" class="flex-shrink-0 block w-56 mt-10 mb-1">

      <!-- Title -->
      <h2 class="block text-4xl font-extrabold mb-6">{{ $t(title) }}</h2>

      <!-- Description -->
      <div class="text-center whitespace-pre-line mb-2">{{ $t(description) }}</div>
      <div v-if="detail" class="text-sm text-gray-700">Reason: {{ $t(detail) }}</div>

      <nuxt-link v-slot="{ href }" :to="actionLink" class="mt-6">
        <a :href="href" class="Btn Btn-filled ml-2" @click="tryAgain(actionLink)">
          <span>{{ $t(buttonLabel) }}</span>
        </a>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { isId } from '~/utils/common'
import { routeIncludes } from '~/utils/route'

export default {
  props: {
    error: { type: Object, required: true, default: () => {} },
  },

  computed: {
    ...mapState('user', {
      currentUser: 'me',
    }),

    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),

    title () {
      if (this.error.title) {
        return this.error.title
      }
      switch (this.error.statusCode) {
        case 404: return 'Something’s missing …'
        case 403: return 'Not enough privileges …'
        case 410: return 'Resource is gone …'
        case 408: return 'Connection Lost'
        default: return 'Something’s wrong …'
      }
    },

    description () {
      if (this.error.description) {
        return this.error.description
      }
      switch (this.error.statusCode) {
        case 404: return 'The page you requested could not be found.'
        case 403: return 'The resource you tried to access requires more privileges.'
          + '\nContact the workspace owner for more information.'
        case 410: return 'The resource you tried to access does not exist anymore.'
          + '\nEither it or its containing workspace has been deleted'
        case 408: return 'Could not connect to the flightlog.\n'
          + 'You may have lost your internet access or the app is unavailable.'
        default: return 'An unknown error has occured.'
      }
    },

    illustration () {
      switch (this.error.statusCode) {
        case 404: return '/illustration/error-missing.png'
        case 403: return '/illustration/error-noaccess.png'
        case 410: return '/illustration/error-missing.png'
        case 408: return '/illustration/error-logic.png'
        default: return '/illustration/error-logic.png'
      }
    },

    detail () {
      return this.error.response && this.error.response.data.detail
    },

    dashboardAvailable () {
      return this.currentUser && isId(this.workspaceId)
    },

    buttonLabel () {
      return this.error.actionLabel || (
        this.dashboardAvailable
          ? 'Back to Dashboard'
          : 'Back to Homepage'
      )
    },

    actionLink () {
      return this.error.actionLink || (
        this.dashboardAvailable
          ? `/workspace/${this.workspaceId}`
          : '/account/signin'
      )
    },
  },

  methods: {
    tryAgain (target) {
      // If the target route is already the current route
      // reload the page instead of navigating because it would have no effect
      // ensure target is a route object and not just a path
      if (typeof target === 'string') {
        target = this.$router.resolve(target).route
      }
      if (routeIncludes(this.$route, target)) {
        window.location.reload()
        return
      }
      this.$router.push(target)
    },
  },

  head () {
    return {
      title: `${this.$t(this.title)} | Flightlog`,
    }
  },
}
</script>
