import Vue from 'vue'

import { waitForNuxtStart } from '~/utils/common'
import { insertDivAtStartOfBody } from '~/utils/dom'

import GlobalAlertBar from '~/components/bars/GlobalAlertBar'

function topAlert (context) {
  Vue.use({
    install (Vue, options) {
      const ALERT_ID = 'global-alert'

      waitForNuxtStart(() => {
        insertDivAtStartOfBody(ALERT_ID)

        const alertVm = new Vue({
          ...GlobalAlertBar,
          store: context.store,
          router: context.app.router,
        })

        alertVm.$mount(`#${ALERT_ID}`)
      })
    },
  })
}

export default topAlert
