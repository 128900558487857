<template>
  <!-- Top bar -->
  <div class="fixed z-40 top-0 w-screen h-topbar bg-white border-b-2 border-gray-100 px-6">
    <div class="w-full h-full flex justify-between items-center">
      <!-- Left (for alignment only) -->
      <div />

      <!-- Centered title -->
      <div class="font-bold">{{ topBarTitle }}</div>

      <!-- Right close button -->
      <button class="flex-shrink-0 w-6 rounded p-2px" @click="$emit('goBack')">
        <XSolid />
      </button>
    </div>
  </div>
</template>

<script>
import XSolid from "~/components/icons/XSolid"

export default {
  name: "FormTopBar",

  components: {
    XSolid,
  },

  props: {
    topBarTitle: { type: String, required: false, default: '' },
  },
}
</script>
