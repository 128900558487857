<template>
  <div>
    <!-- Group action link -->
    <GroupActionLink
      :group="group"
      :opened="isOpened"
      class="mb-1"
      @toggleOpen="$store.dispatch('group/toggleOpen', group.id)"
    />

    <!-- Group opened but not yet loaded -->
    <div v-if="isOpened && !detail">
      {{ $t('Loading...') }}
    </div>

    <template v-else-if="isOpened && detail">
      <!-- Team -->
      <div class="h-8 flex justify-between items-center p-1 mb-1">
        <div class="text-sm ml-1">{{ $t('Group team') }}</div>
        <Avatars
          :users="detail.users"
          color="primary"
          :addRoute="canInvite ? `/workspace/${workspaceId}/group/${group.id}?modal=add-users` : ''"
          class="flex-shrink-0 h-6"
          :tooltipText="$t('Add a user to the group team')"
        />
      </div>

      <!-- Create project link (only if no projects) -->
      <div v-if="createProjectButtonVisible" class="pl-4 mb-2">
        <nuxt-link :to="`/workspace/${workspaceId}/project/create?groupid=${group.id}`">
          <AddAction :label="$t('Create project')" />
        </nuxt-link>
      </div>

      <!-- Projects -->
      <div v-for="project in detail.projects" class="pl-4 mb-1">
        <!-- Project action link -->
        <ProjectActionLink
          :project="project"
          :groupId="group.id"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { MenuGroupFilter } from '~/utils/constants'
import { isAtLeastEdit, isAtLeastInvite } from '~/utils/rights'

import AddAction from '~/components/actions/AddAction'
import Avatars from '~/components/cards/Avatars'
import GroupActionLink from '~/components/menus/items/GroupActionLink'
import ProjectActionLink from '~/components/menus/items/ProjectActionLink'

export default {
  name: 'MainMenuGroup',

  components: {
    AddAction,
    Avatars,
    GroupActionLink,
    ProjectActionLink,
  },

  props: {
    group: { type: Object, required: true, default: () => ({}) },
    detail: { type: Object, required: false, default: null },
    isOpened: { type: Boolean, required: false, default: false },
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),

    ...mapState('group', [
      'menuGroupsFilter',
    ]),

    filterArchivedGroups () {
      return this.menuGroupsFilter === MenuGroupFilter.ARCHIVED_GROUPS.value
    },

    canInvite () {
      return isAtLeastInvite(this.group.current_user_right)
    },

    canEdit () {
      return isAtLeastEdit(this.group.current_user_right)
    },

    createProjectButtonVisible () {
      return this.canEdit && !this.detail.projects.length && !this.filterArchivedGroups
    },
  },
}
</script>
