import { scrollToElement } from '~/utils/dom'

export const formMixin = {
  data () {
    return {
      formMixin_busy: false,
      formMixin_error: undefined,
    }
  },

  methods: {
    /**
     * A wrapper to handle repetitive form submit tasks.
     * Handles both frontend validation and errors returned by the callback.
     */
    async formMixin_handleSubmit (handler) {
      this.formMixin_error = null
      if (this.$v) {
        // Allows repeating the error animation when trying to submit several times
        this.$v.$reset()
        window.requestAnimationFrame(() => {
          this.$v.$touch()
          if (this.$v.$invalid) {
            // Focus first error in page, must wait for next tick because dom was just updated
            // by $v.$touch()
            this.$nextTick(() => {
              scrollToElement('[data-form-error]')
            })
          }
        })
        if (this.$v.$invalid) return
      }
      if (this.formMixin_busy) return
      this.formMixin_busy = true

      // For most use cases, formMixin_busy should remain on success to prevent
      // executing several times the successful action
      // In the rare case where the formMixin_busy state must be reset on success
      // (i.e. in a multiple step form) we must reset the flag manually
      try {
        await handler.call(this) // handle both lambda and normal functions (in case whatever)
      } catch (error) {
        if (error.response?.status === 401) {
          this.$showSignedOutModal()
        } else {
          this.formMixin_error = error
        }
        this.formMixin_error = error
        this.formMixin_busy = false
      }
    },
  },
}
