
/**
* This middleware is meant for the team part of onboarding.
* It prevents access from anywhere but the group onboarding page.
*
* REQUIREMENTS: Must be called after 'auth' middleware.
*/
export default async function signupTeam ({ store, from, redirect }) {
  console.info('[middleware::signupTeam]')
  if (from.path !== '/signup/group') {
    const wsPath = await store.dispatch('workspace/fallbackWorkspacePath')
    return redirect(wsPath)
  }
}
