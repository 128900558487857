import Vue from 'vue'

import { insertDivAtEndOfBody } from '~/utils/dom'
import { waitForNuxtStart } from '~/utils/common'
import ToastsContainer from '~/components/ToastsContainer'

export default function toastPlugin (nuxtContext, inject) {
  // toast => { id: number, text: string }
  const toasts = []
  const TOAST_CLOSE_TIME = 4000
  const TOASTS_CONTAINER_ID = 'ToastContainer'
  const toastsVm = new Vue({
    ...ToastsContainer,
    $deleteToast,
    propsData: {
      toasts,
    },
  })

  inject('addToast', $addToast)
  inject('deleteToast', $deleteToast)

  waitForNuxtStart(() => {
    insertDivAtEndOfBody(TOASTS_CONTAINER_ID)
    toastsVm.$mount(`#${TOASTS_CONTAINER_ID}`)
  })

  function $deleteToast (id) {
    const index = toasts.findIndex(x => x.id === id)
    if (index > -1) {
      toasts.splice(index, 1)
    }
  }

  function $addToast ({ text }) {
    const maxId = toasts.length > 0
      ? toasts.reduce(
        (max, toasts) => (toasts.id > max ? toasts.id : max),
        toasts[0].id,
      )
      : 0
    const id = maxId + 1
    toasts.push({ id, text })
    setTimeout($deleteToast, TOAST_CLOSE_TIME, id)
  }
}
