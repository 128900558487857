<template>
  <div class="relative flex overflow-hidden">
    <!-- Workspace Menu -->
    <MainMenu
      :opened="menuOpened.main"
      @openedChanged="$store.commit('SYNC_MENU_OPENED', { main: $event })"
    />

    <!-- Right: Page -->
    <div class="flex-grow relative h-screen">
      <!-- Top bar -->
      <div
        class="absolute top-0 w-full h-topbar bg-white border-b-2 border-gray-100"
        :class="menuOpened.main ? 'pl-0' : 'pl-8 md:pl-12'"
        style="transition: padding 200ms ease-in-out"
      >
        <ActionTopBar :canEdit="currentlyMember" class="h-full">
          <template v-slot:default>
            <h3 class="text-sm font-bold">{{ $t('Settings & Members') }}</h3>
          </template>
        </ActionTopBar>
      </div>

      <!-- Content -->
      <div class="h-full flex pt-topbar">
        <!-- settings menu -->
        <OpenableSidePanel
          :opened="menuOpened.settings"
          keepWidthLg
          class="z-10 h-full"
          @openedChanged="$store.commit('SYNC_MENU_OPENED', { settings: $event })"
        >
          <div class="h-full bg-white border-r-2 border-gray-200 px-2 py-4">
            <template v-for="a in menuActions">
              <div v-if="a.separation" class="h-px bg-gray-50 my-4" />
              <nuxt-link
                v-else-if="currentRank >= a.requiredRank"
                :key="a.page"
                :to="a.page"
                active-class="bg-gray-100"
                class="relative block hover:bg-gray-50 rounded p-1 mb-2"
              >
                <!-- Floating Right: Warning icon -->
                <div
                  v-if="(pendingEmailReset && a.page === 'profile') || (billingError && a.page === 'billing')"
                  class="absolute right-0 text-red pointer-events-none mr-3"
                >
                  <ExclamationCircleSolid class="w-6 h-6" />
                </div>

                <!-- Icon and label -->
                <div class="flex items-center">
                  <div class="w-6 p-2px" :class="{'text-orange': a.icon === 'SparklesSolid'}">
                    <component :is="a.icon" />
                  </div>
                  <div class="text-sm font-bold ml-1">{{ $t(a.labelI18n) }}</div>
                </div>
              </nuxt-link>
            </template>
          </div>

          <!-- Menu show/hide button -->
          <div
            class="absolute top-0 right-0 h-topbar flex items-center pr-3 duration-200 ease-in-out"
            :class="{'opacity-0 invisible': hideToggleButton}"
            style="transition-property: opacity, visibility"
          >
            <button
              class="
                rounded w-6 h-6 text-gray-900 bg-gray-50 hover:bg-gray-30 p-2px
                transform transition-transform duration-200 ease-in-out translate-x-12
              "
              :class="{'rotate-180': !menuOpened.settings}"
              @click="$store.commit('SYNC_MENU_OPENED', { settings: !menuOpened.settings })"
            >
              <ChevronLeftSolid />
            </button>
          </div>
        </OpenableSidePanel>

        <!-- settings subpage -->
        <div class="flex-grow w-full h-full overflow-y-auto">
          <nuxt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { RoleToRank } from '~/utils/constants'

import ActionTopBar from "~/components/bars/ActionTopBar"
import ChevronLeftSolid from "~/components/icons/ChevronLeftSolid"
import CogSolid from '~/components/icons/CogSolid'
import CurrencyEuroSolid from '~/components/icons/CurrencyEuroSolid'
import ExclamationCircleSolid from '~/components/icons/ExclamationCircleSolid'
import MainMenu from "~/components/menus/MainMenu"
import OpenableSidePanel, { shouldBeClosed } from "~/components/menus/OpenableSidePanel"
import SparklesSolid from '~/components/icons/SparklesSolid'
import UserCircleSolid from '~/components/icons/UserCircleSolid'
import UserGroupSolid from '~/components/icons/UserGroupSolid'
import UserSolid from '~/components/icons/UserSolid'

const menuActions = [
  {
    page: 'profile',
    labelI18n: 'Profile & security',
    icon: 'UserCircleSolid',
    requiredRank: RoleToRank.GUEST,
  },
  { separation: true },
  {
    page: 'personal',
    labelI18n: 'Personal settings',
    icon: 'UserSolid',
    requiredRank: RoleToRank.GUEST,
  },
  {
    page: 'workspace',
    labelI18n: 'Workspace settings',
    icon: 'CogSolid',
    requiredRank: RoleToRank.GUEST,
  },
  {
    page: 'team',
    labelI18n: 'Members and Guests',
    icon: 'UserGroupSolid',
    requiredRank: RoleToRank.MEMBER,
  },
  {
    page: 'upgrade',
    labelI18n: 'Upgrade',
    icon: 'SparklesSolid',
    requiredRank: RoleToRank.OWNER,
  },
  {
    page: 'billing',
    labelI18n: 'Billing',
    icon: 'CurrencyEuroSolid',
    requiredRank: RoleToRank.OWNER,
  },
]

export default {
  name: 'SettingsLayout',

  components: {
    ActionTopBar,
    ChevronLeftSolid,
    CogSolid,
    CurrencyEuroSolid,
    ExclamationCircleSolid,
    MainMenu,
    OpenableSidePanel,
    SparklesSolid,
    UserCircleSolid,
    UserGroupSolid,
    UserSolid,
  },

  middleware: ['auth', 'workspace'],

  data () {
    return {
      menuActions,
      mainMenuShouldBeClosed: false,
    }
  },

  computed: {
    ...mapState([
      'menuOpened',
    ]),

    ...mapState('workspace', [
      'currentWorkspace',
    ]),

    ...mapGetters('user', [
      'pendingEmailReset',
    ]),

    ...mapGetters('workspace', [
      'currentRank',
      'currentlyMember',
    ]),

    ...mapGetters('billing', [
      'expiryError',
      'overseatError',
      'paymentError',
    ]),

    hideToggleButton () {
      return this.mainMenuShouldBeClosed && this.menuOpened.main
    },

    billingError () {
      return this.expiryError || this.overseatError || this.paymentError
    },
  },

  mounted () {
    this.updateShouldBeClosed()
    window.addEventListener('resize', this.updateShouldBeClosed)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.updateShouldBeClosed)
  },

  methods: {
    updateShouldBeClosed () {
      this.mainMenuShouldBeClosed = shouldBeClosed('xl')
    },
  },
}
</script>
