<template>
  <header class="h-full flex justify-between items-center border-b-2 border-primary-900 px-2">
    <div class="flex-shrink-0 flex">
      <!-- White dot -->
      <div class="self-end w-2 h-2 bg-white rounded-full" />
      <!-- Current workspace logo -->
      <div class="w-10 h-10 flex justify-center items-center rounded-full overflow-hidden">
        <img
          v-if="currentLogo && !hideLogo"
          :src="$mediaUrl(currentLogo)"
          alt="logo"
          class="w-full h-full object-cover"
          @error="hideLogo = true"
        >
        <div v-else class="relative w-full h-full flex justify-center items-center bg-primary-700 border-primary-300 rounded-full">
          <div class="text-white text-2xl font-bold">{{ currentName[0] }}</div>
          <nuxt-link
            v-if="currentlyAdmin"
            :to="`/workspace/${workspaceId}/settings/workspace?modal=change-logo`"
            class="absolute w-full h-full flex justify-center items-center transform scale-75 hover:scale-100 opacity-0 hover:opacity-100 duration-300 ease-in-out"
            style="transition-property: opacity,transform"
          >
            <div class="w-full h-full bg-primary-700 text-white rounded-full p-2">
              <PlusSolid />
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>

    <!-- Current Workspace User -->
    <div class="flex-grow sm:relative Dropdown-onFocus ml-2 w-full">
      <button ref="dropdownBtn" class="block rounded" @click="e => $refs.dropdownBtn.focus()">
        <div class="flex items-center">
          <!-- Current user info -->
          <div class="flex flex-col text-left" style="max-width: 170px">
            <div class="font-extrabold leading-tight truncate mb-1">{{ currentName }}</div>
            <div class="text-sm leading-tight truncate">{{ myFullname }}</div>
          </div>
          <!-- Dropdown button -->
          <div class="w-6">
            <ChevronDownSolid />
          </div>
        </div>
      </button>

      <!-- Dropdown content -->
      <MainMenuDropdown class="absolute z-20 left-0" />
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex"

import ChevronDownSolid from "~/components/icons/ChevronDownSolid"
import MainMenuDropdown from '~/components/dropdowns/MainMenuDropdown'
import PlusSolid from "~/components/icons/PlusSolid"

export default {
  name: 'MainMenuHeader',

  components: {
    ChevronDownSolid,
    MainMenuDropdown,
    PlusSolid,
  },

  data () {
    return {
      hideLogo: false,
    }
  },

  computed: {
    ...mapGetters('user', [
      'myFullname',
    ]),

    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),

    ...mapGetters('workspace', [
      'currentName',
      'currentLogo',
      'currentlyAdmin',
    ]),
  },
}
</script>
