<template>
  <ActionMenu
    :actions="actions"
    :nbActionsWhenCollapsed="collapseActions ? 3 : 0"
    style="width: 250px"
    @actionClicked="$emit('actionClicked', $event)"
  >
    <template v-if="withOutline" v-slot:bottomContent>
      <div class="h-10 flex items-center px-4">
        <span class="text-left text-gray-400 text-sm font-bold">{{ $t('Content') }}</span>
      </div>
      <div v-if="!outline.length" class="h-10 flex items-center px-4">
        <span class="text-left text-sm">{{ $t('No outline') }}</span>
      </div>
      <div v-for="title in outline" class="h-10 flex items-center px-4 ml-4">
        <button class="text-left text-sm truncate" @click="title.scrollInto()">{{ title.text }}</button>
      </div>
    </template>
  </ActionMenu>
</template>

<script>
import { mapState } from 'vuex'

import ActionMenu from '~/components/menus/ActionMenu'

export default {
  name: 'DocMenu',

  components: {
    ActionMenu,
  },

  props: {
    doc: { type: Object, required: true, default: () => {} },
    canEdit: { type: Boolean, required: false, default: false },
    isMember: { type: Boolean, required: false, default: false },
    withOutline: { type: Boolean, required: false, default: false },
    collapseActions: { type: Boolean, required: false, default: false },
    outline: { type: Array, required: false, default: () => [] },
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),

    ...mapState('project', {
      projectId: 'currentId',
    }),

    actions () {
      if (!this.canEdit) return
      return [
        {
          token: 'DUPLICATE',
          labelI18n: 'Duplicate',
          icon: 'DuplicateSolid',
        },
        {
          token: 'TOGGLE_TEMPLATE',
          labelI18n: 'Save as template',
          icon: 'TemplateSolid',
          toggleValue: this.doc.is_template,
        },
        {
          token: 'SHARE',
          labelI18n: 'Share',
          icon: 'ShareSolid',
        },
        {
          token: 'EXPORT',
          labelI18n: 'Export',
          icon: 'ExternalLinkSolid',
        },
        // ...(!this.isMember ? [] : [{
        //   token: 'TOGGLE_LOCKED',
        //   labelI18n: 'Lock editing',
        //   icon: 'LockClosedSolid',
        //   toggleValue: this.doc.is_locked,
        // }]),
        {
          token: this.doc.is_archived ? 'RESTORE' : 'ARCHIVE',
          labelI18n: this.doc.is_archived ? 'Restore' : 'Archive',
          icon: 'ArchiveSolid',
        },
        ...(!this.isMember ? [] : [{
          token: 'DELETE_DOC',
          labelI18n: 'Delete',
          icon: 'TrashSolid',
        }]),
      ]
    },

    docRoute () {
      return {
        name: `workspace-wsid-project-projectid-doc-docid`,
        params: { wsid: this.workspaceId, projectid: this.projectId, docid: this.doc.id },
        query: { ...this.$route.query },
      }
    },

  },
}
</script>
