<template>
  <div
    class="group relative flex items-center hover:bg-primary-900 rounded p-1"
    :class="{'bg-primary-800': isCurrent}"
  >
    <!-- Left: Toggle open button -->
    <button
      class="
        flex-shrink-0 relative z-10
        w-6 bg-primary-800 hover:bg-primary-700 rounded p-2px
        transform rotate-0 transition-transform duration-200 ease-in-out
      "
      :class="{'rotate-90': opened}"
      @click="$emit('toggleOpen')"
    >
      <ChevronRightSolid />
    </button>

    <!-- Left: Group name -->
    <nuxt-link :to="groupPath" class="group flex-grow truncate outline-none ml-1">
      <div class="absolute inset-0 w-full h-full group-focus:shadow-border-primary-200 rounded" />
      <div class="text-sm font-bold truncate">{{ group.name }}</div>
    </nuxt-link>

    <!-- Right: Other actions dropdown -->
    <ActionDropdown
      v-if="otherActions.length"
      :actions="otherActions"
      fromRight
      class="flex-shrink-0 h-6 z-10 ml-1"
      @dropdownAction="executeDropdownAction"
    >
      <button
        ref="dropdownBtn"
        class="
          w-6 bg-primary-800 hover:bg-primary-700 rounded p-2px
          opacity-0 group-hover:opacity-100 focus:opacity-100
        "
        @click="$refs.dropdownBtn.focus()"
      >
        <DotsVerticalSolid />
      </button>
    </ActionDropdown>

    <!-- Right: Action add -->
    <nuxt-link
      v-if="canEdit && !filterArchivedGroups"
      v-tooltip.slow="$t('Add a project')"
      :to="`/workspace/${workspaceId}/project/create?groupid=${group.id}`"
      class="
        flex-shrink-0 relative z-10
        block w-6 bg-white hover:bg-gray-100 text-primary rounded p-2px ml-1
        opacity-0 group-hover:opacity-100 focus:opacity-100
      "
    >
      <PlusSolid />
    </nuxt-link>

    <!-- Archived icon -->
    <div
      v-if="group.is_archived"
      class="absolute right-0 w-6 text-primary-300 m-1"
      style="padding:5px;"
    >
      <ArchiveSolid />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { MenuGroupFilter } from '~/utils/constants'
import { isAtLeastEdit, isAtLeastInvite, isAtLeastMember } from '~/utils/rights'

import ActionDropdown from '~/components/dropdowns/ActionDropdown'
import ArchiveSolid from '~/components/icons/ArchiveSolid'
import ChevronRightSolid from "~/components/icons/ChevronRightSolid"
import DotsVerticalSolid from "~/components/icons/DotsVerticalSolid"
import GroupArchiveModal from "~/components/modals/GroupArchiveModal"
import ConfirmModal from '~/components/modals/ConfirmModal'
import PlusSolid from "~/components/icons/PlusSolid"

export default {
  name: 'GroupActionLink',

  components: {
    ActionDropdown,
    ArchiveSolid,
    ChevronRightSolid,
    DotsVerticalSolid,
    PlusSolid,
  },

  props: {
    group: { type: Object, required: true, default: () => ({}) },
    opened: { type: Boolean, required: true, default: false },
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),

    ...mapState('group', [
      'menuGroupsFilter',
    ]),

    ...mapGetters('workspace', {
      workspaceName: 'currentName',
      currentRole: 'currentRole',
    }),

    filterArchivedGroups () {
      return this.menuGroupsFilter === MenuGroupFilter.ARCHIVED_GROUPS.value
    },

    groupPath () {
      return `/workspace/${this.workspaceId}/group/${this.group.id}`
    },

    isCurrent () {
      return this.$route.path === this.groupPath
    },

    canEdit () {
      return isAtLeastEdit(this.group.current_user_right)
    },

    canInvite () {
      return isAtLeastInvite(this.group.current_user_right)
    },

    isMemberOrHigherRole () {
      const result = isAtLeastMember(this.currentRole)
      return result
    },

    otherActions () {
      const actions = []
      if (this.canEdit) {
        actions.push({ path: `${this.groupPath}/settings`, labelI18n: 'Edit group settings' })
      }
      if (this.canInvite) {
        actions.push({ path: `${this.groupPath}?modal=add-users`, labelI18n: 'Add people' })
      }
      if (this.canEdit) {
        if (this.group.is_archived) {
          actions.push({ token: 'RESTORE', labelI18n: 'Restore' })
        } else {
          actions.push({ token: 'ARCHIVE', labelI18n: 'Archive' })
        }
      }
      if (this.canEdit && this.canInvite && this.isMemberOrHigherRole) {
        actions.push({ token: 'DELETE', labelI18n: 'Delete' })
      }
      return actions
    },
  },

  methods: {
    executeDropdownAction (actionToken) {
      switch (actionToken) {
        case 'RESTORE':
        case 'ARCHIVE':
          this.$showModal(GroupArchiveModal, {
            group: this.group,
            onConfirm: (action, result) => {
              if (result.data.success) {
                if (action === 'group/restoreGroup') {
                  this.$addToast({
                    text: this.$t('Group restored'),
                  })
                } else if (action === 'group/archiveGroup') {
                  this.$addToast({
                    text: this.$t('Group archived'),
                  })
                }
              }
            },
          })
          break
        case 'DELETE':
          this.$showModal(ConfirmModal, {
            title: this.$t('Delete group'),
            description: this.$t(`You are about to delete group: "${this.group.name}" from workspace: "${this.workspaceName}" and its content.\n\nThis action cannot be undone. We will delete all associated data immediatly after your confirmation. Be very careful.\n\nAre you sure you wish to continue?`),
            confirmLabel: this.$t('Yes, delete'),
            isCritical: true,
            onConfirm: async () => {
              const result = await this.$store.dispatch('group/deleteGroup', { groupId: this.group.id })
              if (result.data.success) {
                this.$addToast({
                  text: this.$t('Group deleted'),
                })
              }
              this.$closeModal()
            },
          })
          break
        default: throw new Error(`Unexpected action token: ${actionToken}`)
      }
    },
  },
}
</script>
