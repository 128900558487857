<template>
  <div class="w-full h-topbar bg-white border-b-2 border-gray-100 px-6">
    <div class="w-full h-full max-w-screen-xl flex justify-between items-center mx-auto">
      <!-- Left: Logo -->
      <nuxt-link class="flex-shrink-0" to="/">
        <img style="height:28px" src="/logos/flightlog-app-logo__color-and-text-gray-900.png">
      </nuxt-link>

      <!-- Right: Actions -->
      <div class="flex md:-mx-2">
        <nuxt-link
          v-if="signinButton > 0"
          to="/account/signin"
          class="flex-shrink-0 Btn Btn-wired Btn-sm w-button mx-2 md:block"
          :class="signinButton < 1 ? 'hidden' : ''"
        >
          {{ $t('Sign in') }}
        </nuxt-link>
        <nuxt-link
          v-if="signupButton > 0"
          to="/signup/email"
          class="flex-shrink-0 Btn Btn-filled Btn-sm w-button mx-2 md:block"
          :class="signupButton < 1 || signinButton > 0 ? 'hidden' : ''"
        >
          {{ $t('Try for free') }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DefaultTopBar',

  props: {
    signinButton: { type: Number, required: false, default: 0 },
    signupButton: { type: Number, required: false, default: 0 },
  },
}
</script>
