
export default async function invitationToken ({ store, query, redirect, error }) {
  console.info('[middleware::invitationToken]')
  const inviteToken = query.token
  const result = await store.dispatch('workspace/acceptInvite', inviteToken)
  if (result.error) return error(result.error)

  // Having an activationToken means the invited user's account is not yet active.
  // For this reason we need to redirect to the signup-profile process to delay
  // invite acceptation until after the user activates their account.
  // Having the inviteToken in the url guarantees that all needed information is
  // available to complete the process properly and avoids asking the user to find
  // the email invite link again.
  if (result.activationToken) {
    return redirect(`/signup/profile/?token=${result.activationToken}&inviteToken=${inviteToken}`)
  }
  // TODO: send toast to notify of workspace join
  redirect(`/workspace/${result.workspaceId}?fromInvite=1`)
}
