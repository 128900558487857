import {
  addLogOnCall,
  isId,
  isBoolean,
  isObject,
  iterObject,
  patchObject,
  removeFrom,
  setReactiveObjectKey,
  throwIfNot,
} from '~/utils/common'
import { MenuDocFilter } from '~/utils/constants'
import { isAtLeastEdit, isAtLeastInvite } from '~/utils/rights'
import {
  baseTree,
  isNode,
  getNodeRef,
  seekNodeById,
  moveNodeByRefs,
  augmentNode,
  augmentNodes,
  updatePaths,
} from '~/utils/treenode'

// =================================== STATE ===================================

export const state = () => ({
  listeningTopics: false,
  currentId: undefined,
  projectDetails: {},
  contextId: undefined,
  teamUsers: [],
  menuDocs: baseTree(),
  menuDocsFilter: MenuDocFilter.ACTIVE_DOCS.value,
  openedDocs: {},
  editableProjects: [],
  editableProjectsLoaded: false,
})

// ================================== GETTERS ==================================

/**
 * @type {import("vuex").GetterTree<typeof state>}
 */
export const getters = {
  currentProject: state => state.projectDetails[state.currentId],
  currentEmoji: (state, get) => get.currentProject?.emoji || '',
  currentName: (state, get) => get.currentProject?.name || '',
  currentPrivacy: (state, get) => get.currentProject?.privacy,
  currentRight: (state, get) => get.currentProject?.right,
  currentUsers: (state, get) => get.currentProject?.users,
  currentPlanning: (state, get) => get.currentProject?.planning || {},
  currentGroupId: (state, get) => get.currentProject?.group_id,
  currentParentGroupName: (state, get, rootState) => {
    if (!get.currentProject) return
    const group = rootState.group.groupDetails[get.currentProject.group_id]
    return group?.name || '{ Missing group name }'
  },
  currentUserRight: (state, get) => get.currentProject?.current_user_right,
  currentlyAbleToEdit: (state, get) => isAtLeastEdit(get.currentUserRight),
  currentlyAbleToInvite: (state, get) => isAtLeastInvite(get.currentUserRight),
}

// ================================= MUTATIONS =================================

/**
 * @type {import("vuex").MutationTree<typeof state>}
 */
export const mutations = {
  SET_LISTENING_TOPICS (state, payload) {
    state.listeningTopics = payload || false
  },
  SET_CURRENT_ID (state, projectId) {
    state.currentId = projectId || null
  },

  SET_PROJECT_DETAILS (state, payload) {
    if (!payload) state.projectDetails = {} // handle reset use case
    else {
      const { projectId, data } = payload
      if (!projectId || (data !== null && !isObject(data))) {
        throw new Error(`Invalid payload for SET_PROJECT_DETAILS: ${JSON.stringify(payload)}`)
      }
      setReactiveObjectKey(state.projectDetails, projectId, data)
    }
  },
  PATCH_PROJECT_DETAIL (state, { projectId, patch }) {
    const obj = state.projectDetails[projectId]
    if (obj) {
      patchObject(obj, patch)
    }
  },
  PATCH_PROJECT_DETAIL_USER (state, { userId, patch }) {
    for (const project of iterObject(state.projectDetails)) {
      for (const u of project.users) {
        if (u.id === userId) {
          patchObject(u, patch)
        }
      }
    }
  },
  REMOVE_PROJECT_DETAIL_USER (state, { userId, projectId = null }) {
    for (const project of iterObject(state.projectDetails, projectId)) {
      removeFrom(project.users, u => u.id === userId)
    }
  },

  SET_CONTEXT_ID (state, projectId) {
    // `contextId` is the last project id that was used to load contextual data.
    // It must not be confused with `currentId` which is the id of the current project.
    // When exiting current project, `currentId` is unset but the `contextId` remains,
    // which allows keeping previously loaded data in some cases.
    state.contextId = Number(projectId) || null
  },

  SET_EDITABLE_PROJECTS (state, projects) {
    state.editableProjects = projects || []
    state.editableProjectsLoaded = Array.isArray(projects)
  },
  PATCH_EDITABLE_PROJECT (state, { projectId, patch }) {
    for (const g of state.editableProjects) {
      for (const p of g.projects) {
        if (p.id === projectId) {
          patchObject(p, patch)
        }
      }
    }
  },
  REMOVE_EDITABLE_PROJECT (state, { projectId, groupId = null }) {
    for (const g of state.editableProjects) {
      if (groupId && groupId !== g.id) continue
      removeFrom(g.projects, p => p.id === projectId)
    }
  },
  PATCH_EDITABLE_PROJECT_GROUP (state, { groupId, patch }) {
    for (const g of state.editableProjects) {
      if (g.id === groupId) {
        patchObject(g, patch)
      }
    }
  },
  REMOVE_EDITABLE_PROJECT_GROUP (state, groupId) {
    removeFrom(state.editableProjects, g => g.id === groupId)
  },

  SET_TEAM_USERS (state, data) {
    state.teamUsers = data || []
  },
  PATCH_TEAM_USER (state, { userId, patch }) {
    for (const tu of state.teamUsers) {
      if (tu.user.id === userId) {
        patchObject(tu, patch)
      }
    }
  },
  REMOVE_TEAM_USER (state, userId) {
    removeFrom(state.teamUsers, tu => tu.user.id === userId)
  },

  SET_MENU_DOCS (state, menuDocs) {
    if (menuDocs) {
      augmentNodes(menuDocs, state.openedDocs)
      state.menuDocs = menuDocs
    } else {
      state.menuDocs = baseTree()
    }
  },
  SET_MENU_DOCS_FILTER (state, menuDocsFilter) {
    state.menuDocsFilter = menuDocsFilter || MenuDocFilter.ACTIVE_DOCS.value
  },
  SET_OPENED_DOC (state, payload) {
    if (!payload) state.openedDocs = {} // handle reset use case
    else {
      const { docId, opened } = payload
      if (!docId || !isBoolean(opened)) {
        throw new Error(`Invalid payload for SET_OPENED_DOC: ${JSON.stringify(payload)}`)
      }
      setReactiveObjectKey(state.openedDocs, docId, Boolean(opened))
    }
  },

  INSERT_MENU_NODE (state, { parentPath, newNode }) {
    const parent = getNodeRef(state.menuDocs, parentPath)
    augmentNode(newNode, { parent, opened: false })
    parent.children.unshift(newNode)
  },
  PATCH_MENU_NODE (state, { node, patch }) {
    patchObject(node, patch)
  },
  MOVE_MENU_NODE (state, { nodePath, targetPath, targetArea, updatedTree }) {
    const node = getNodeRef(state.menuDocs, nodePath)
    const target = getNodeRef(state.menuDocs, targetPath)
    moveNodeByRefs(node, target, targetArea)
    updatePaths(node, updatedTree)
  },
  REMOVE_MENU_NODE (state, docId) {
    const node = seekNodeById(state.menuDocs, docId)
    removeFrom(node.parent.children, n => n.path === node.path)
    if (node.opened) {
      state.openedDocs[docId] = false
    }
  },
}

// ================================== ACTIONS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeActions = {
  async _reset (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined)
    }
    await ctx.dispatch('unsubProjectTopics')
  },

  async create (ctx, { groupId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, groupId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const { data } = await this.$axios.post(`/projects`, body, { headers })

    await ctx.dispatch('onProjectCreate', { groupId })

    return data.created_id
  },

  async setCurrentProject (ctx, projectId) {
    if (projectId === ctx.state.currentId) return
    throwIfNot(isId, projectId)

    ctx.commit('SET_CURRENT_ID', projectId)
    if (!ctx.state.projectDetails[projectId]) {
      await ctx.dispatch('loadProjectDetails', projectId)
    }

    await ctx.dispatch('setProjectContext', projectId)
  },

  unsetCurrentProject (ctx) {
    ctx.commit('SET_CURRENT_ID')
  },

  async setProjectContext (ctx, projectId) {
    if (projectId === ctx.state.contextId) return

    // Reset previously loaded data
    this.commit('doc/SET_DOC_DETAIL')
    this.commit('doc/SET_TEMPLATES')
    this.commit('log/SET_LOG_CACHE')
    this.commit('log/SET_PROJECT_LOGS')
    this.commit('link/SET_IMPORTANT_LINKS')

    ctx.commit('SET_CONTEXT_ID', projectId)
    await ctx.dispatch('loadTeam', projectId)
    await ctx.dispatch('loadMenuDocs', projectId)

    if (!ctx.state.listeningTopics && process.browser && !this.state.publicMode) {
      ctx.commit('SET_LISTENING_TOPICS', true)
      const use = action => payload => this.dispatch(action, payload)
      this.$sse.sub('projectLinks:refresh', use('link/onProjectLinksRefresh'))
      this.$sse.sub('projectLogs:refresh', use('log/onProjectLogsRefresh'))
      this.$sse.sub('log:patch', use('log/onLogPatch'))
      this.$sse.sub('log:delete', use('log/onLogDelete'))
      this.$sse.sub('log:toggleArchive', use('log/onLogToggleArchive'))
      this.$sse.sub('doc:create', use('doc/onDocCreate'))
      this.$sse.sub('doc:move', use('doc/onDocMove'))
      this.$sse.sub('doc:delete', use('doc/onDocDelete'))
      this.$sse.sub('doc:patch', use('doc/onDocPatch'))
      this.$sse.sub('doc:toggleArchive', use('doc/onDocToggleArchive'))
      this.$sse.sub('externalLink:patch', use('link/onExternalLinkPatch'))
    }
  },

  async unsetProjectContext (ctx) {
    const projectId = ctx.state.contextId
    ctx.commit('SET_PROJECT_DETAILS', { projectId, data: null })
    ctx.commit('SET_CONTEXT_ID')
    ctx.commit('SET_TEAM_USERS')
    ctx.commit('SET_MENU_DOCS')
    ctx.commit('SET_CURRENT_ID')
    const loadedProjects = Object.values(ctx.state.projectDetails).filter(Boolean)
    if (!loadedProjects.length) {
      await ctx.dispatch('unsubProjectTopics')
    }
    await this.dispatch('doc/_reset')
    await this.dispatch('log/_reset')
    await this.dispatch('link/_reset')
  },

  unsubProjectTopics (ctx) {
    ctx.commit('SET_LISTENING_TOPICS', false)
    if (process.browser && !this.state.publicMode) {
      this.$sse.unsub('projectLinks:refresh')
      this.$sse.unsub('projectLogs:refresh')
      this.$sse.unsub('log:patch')
      this.$sse.unsub('log:toggleArchive')
      this.$sse.unsub('doc:create')
      this.$sse.unsub('doc:move')
      this.$sse.unsub('doc:patch')
      this.$sse.unsub('doc:toggleArchive')
      this.$sse.unsub('externalLink:patch')
    }
  },

  async loadEditableProjects (ctx, { useCache = false } = {}) {
    // NOTE: editableProjects are only usable by users with edit right, no need to check publicMode
    if (useCache === true && ctx.state.editableProjectsLoaded) return
    throwIfNot(isId, ctx.rootState.workspace.currentId)

    const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }

    const { data } = await this.$axios.get(`/projects/editable`, { headers })

    ctx.commit('SET_EDITABLE_PROJECTS', data)
  },

  async loadProjectDetails (ctx, projectId) {
    throwIfNot(isId, projectId)

    var data
    if (ctx.rootState.publicToken) {
      const publicToken = ctx.rootState.publicToken
      data = await this.$axios.$get(`/public/${publicToken}/project`)
    } else {
      throwIfNot(isId, ctx.rootState.workspace.currentId)
      const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }
      data = await this.$axios.$get(`/projects/${projectId}`, { headers })
    }

    ctx.commit('SET_PROJECT_DETAILS', { projectId, data })
  },

  async archiveProject (ctx, { projectId, groupId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.put(`/projects/${projectId}/archive`, null, { headers })

    await ctx.dispatch('onProjectToggleArchive', { projectId, archive: true })

    return result
  },

  async restoreProject (ctx, { projectId, groupId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.put(`/projects/${projectId}/restore`, null, { headers })

    await ctx.dispatch('onProjectToggleArchive', { projectId, archive: false })

    return result
  },

  async deleteProject (ctx, { projectId, groupId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, groupId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.delete(`/projects/${projectId}`, { headers })

    await ctx.dispatch('onProjectDelete', { projectId, groupId })

    return result
  },

  async loadTeam (ctx, projectId) {
    if (ctx.rootState.publicToken) return

    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, projectId)

    const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }

    const { data } = await this.$axios.get(`/projects/${projectId}/team`, { headers })

    ctx.commit('SET_TEAM_USERS', data)
    return data
  },

  async addToTeam (ctx, { projectId, emails }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }
    const body = { invites: emails }

    const result = await this.$axios.post(`/projects/${projectId}/team`, body, { headers })

    await ctx.dispatch('onProjectUserAdd', { projectId })

    return result
  },

  async updateUserRight (ctx, { projectId, userId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, userId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.put(`/projects/${projectId}/team/${userId}`, body, { headers })

    await ctx.dispatch('onProjectUserPatch', { projectId, userId, patch: body })
  },

  async removeFromTeam (ctx, { projectId, userId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, userId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.delete(`/projects/${projectId}/team/${userId}`, { headers })

    await ctx.dispatch('onProjectUserRemove', { projectId, userId })
  },

  async updateSettings (ctx, { groupId, projectId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    throwIfNot(isId, groupId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.put(`/projects/${projectId}`, body, { headers })

    await ctx.dispatch('onProjectPatch', { projectId, patch: body })
  },

  async updatePlanning (ctx, { projectId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.put(`/projects/${projectId}/planning`, body, { headers })

    await ctx.dispatch('onProjectPatch', { projectId, patch: { planning: body } })
  },

  async getShareToken (ctx, { projectId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, projectId)

    const headers = { 'X-Current-Workspace-Id': ctx.rootState.workspace.currentId }

    const { data } = await this.$axios.get(`/projects/${projectId}/share`, { headers })

    return data.share_token
  },

  async toggleShareToken (ctx, { projectId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const { data } = await this.$axios.put(`/projects/${projectId}/share`, null, { headers })

    const patch = { share_token: data.share_token }
    await ctx.dispatch('onProjectPatch', { projectId, patch })

    return data.share_token
  },

  async loadMenuDocs (ctx, projectId) {
    throwIfNot(isId, projectId)

    const params = { only: ctx.state.menuDocsFilter }

    var data
    if (ctx.rootState.publicToken) {
      const publicToken = ctx.rootState.publicToken
      data = await this.$axios.$get(`/public/${publicToken}/docs`, { params })
    } else {
      throwIfNot(isId, ctx.rootState.workspace.currentId)
      const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }
      data = await this.$axios.$get(`/projects/${projectId}/docs`, { headers, params })
    }

    ctx.commit('SET_MENU_DOCS', data)
    return data
  },

  async updateMenuDocsFilter (ctx, newValue) {
    if (newValue === ctx.state.menuDocsFilter) return

    ctx.commit('SET_MENU_DOCS_FILTER', newValue)

    await ctx.dispatch('loadMenuDocs', ctx.state.currentId)
  },

  toggleOpen (ctx, node) {
    throwIfNot(isNode, node)

    const docId = node.item.id
    const opened = !node.opened
    ctx.commit('SET_OPENED_DOC', { docId, opened })
    ctx.commit('PATCH_MENU_NODE', { node, patch: { opened } })
  },

  async openParentNodes (ctx, docId) {
    throwIfNot(isId, docId)
    var node = seekNodeById(ctx.state.menuDocs, docId)
    throwIfNot(isNode, node)
    while (node.parent && node.parent.item) {
      node = node.parent
      if (!node.opened) await ctx.dispatch('toggleOpen', node)
    }
  },

  async moveDocument (ctx, { nodePath, targetPath, targetArea }) {
    const projectId = ctx.state.currentId
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, projectId)
    // TODO: Will need to handle the move more safely:
    // 1. Make a backup of the tree to revert to if needed
    // 2. Immediately update the tree locally to get a quick feedback for the user
    // 3. Then, make the API call
    // 4. If it fails, revert the tree to its backup

    const url = `/projects/${projectId}/docs/move`
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }
    const body = {
      from_path: nodePath,
      to_path: targetPath,
      area: targetArea,
    }

    const { data: updatedTree } = await this.$axios.put(url, body, { headers })

    await this.dispatch('doc/onDocMove', {
      projectId,
      nodePath,
      targetPath,
      targetArea,
      updatedTree,
    })
  },
}

// ================================== EFFECTS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeEffects = {
  async onProjectCreate (ctx, { groupId }) {
    throwIfNot(isId, groupId)

    if (this.state.workspace.lastProjectsLoaded) {
      await this.dispatch('workspace/loadLastProjects')
    }

    if (this.state.group.groupDetails[groupId]) {
      await this.dispatch('group/loadGroupDetails', [groupId]) // add project to group.projects
    }

    if (groupId === this.state.group.contextId) {
      await this.dispatch('group/loadProjects', { groupId })
    }

    if (this.state.project.editableProjectsLoaded) {
      await this.dispatch('project/loadEditableProjects')
    }
  },

  async onProjectPatch (ctx, { projectId, patch }) {
    throwIfNot(isId, projectId)
    throwIfNot(isObject, patch)

    const groupChanged = 'group_id' in patch
    const privacyChanged = 'privacy' in patch
    // XXX: this flag is seriously simplified. It is not possible to reliably
    // tell if right change will have an effect since it depends on many
    // informations that may need to be loaded anyway
    const rightChangeHasEffects = 'right' in patch && !this.state.workspace.currentlyAdmin

    if (groupChanged || privacyChanged || rightChangeHasEffects) {
      // Execute a full reload to avoid complex edge case management

      // Reload menuGroups and groupDetails to remove no longer accessible resources
      await this.dispatch('group/loadMenuGroups')
      await this.dispatch('group/loadGroupDetails')

      const gxid = this.state.group.contextId
      if (gxid) {
        if (!this.state.group.groupDetails[gxid]) {
          // Current contextId is not in the groupDetails after reload
          // this means the current group is no longer accessible
          await this.dispatch('group/unsetGroupContext')
        } else {
          // There are several possible cases regarding the current group context:
          // 1. it contains the project which must be patched
          // 2. it misses the project which must be loaded
          // 3. it has the project which should be removed
          // XXX: Just reload all projects for now, this is not optimal at all! If there
          // are many projects in the group and the current context limit is disabled
          // TODO: execute the proper action for the exact case that is actually happening
          await this.dispatch('group/loadProjects', { groupId: gxid })
        }
      }

      if (this.state.project.editableProjectsLoaded) {
        // TODO: group_id -> only patch, privacy -> reload required
        await this.dispatch('project/loadEditableProjects')
      }

      if (this.state.doc.templateGroupsLoaded) {
        // TODO: group_id -> only patch, privacy -> reload required
        await this.dispatch('doc/loadTemplates')
      }

      // remove no longer accesible last projects
      if (this.state.workspace.lastProjectsLoaded) {
        // TODO: group_id -> only patch, privacy -> reload required
        await this.dispatch('workspace/loadLastProjects')
      }

      // reload in case project is no longer accessible
      await this.dispatch('project/loadProjectDetails', projectId)

      // exit group or project if no longer accessible
      const gcid = this.state.group.currentId
      const pcid = this.state.project.currentId
      const lostGroupAccess = gcid && !this.state.group.groupDetails[gcid]
      const lostProjectAccess = pcid && !this.state.project.projectDetails[pcid]

      if (lostGroupAccess || lostProjectAccess) {
        return this.$router.push(`/workspace/${this.state.workspace.currentId}`)
      }

      // If there is a current project, load missing parent group's data (if missing)
      const p = this.state.project.projectDetails[pcid]
      if (p && !this.state.group.groupDetails[p.group_id]) {
        await this.dispatch('group/toggleOpen', p.group_id)
      }
    } else {
      // Simply patch projects

      if (this.state.workspace.lastProjectsLoaded) {
        if (this.state.workspace.lastProjects.some(p => p.id === projectId)) {
          // Project is already in the list, patch it
          this.commit('workspace/PATCH_LAST_PROJECT', { projectId, patch })
        } else {
          // Project is missing, reload to add it
          await this.dispatch('workspace/loadLastProjects')
        }
      }

      this.commit('group/PATCH_GROUP_DETAIL_PROJECT', { projectId, patch })

      if (this.state.group.contextId) {
        if (this.state.group.currentProjects.list.some(p => p.id === projectId)) {
          // Project is already in the list, patch it
          this.commit('group/PATCH_CURRENT_PROJECT', { projectId, patch })
        } else {
          // Project is missing, reload to maybe add it
          // (cannot easily tell if the current filter matches edited project)
          await this.dispatch('group/loadProjects', { groupId: this.state.group.contextId })
        }
      }

      ctx.commit('PATCH_PROJECT_DETAIL', { projectId, patch })
      ctx.commit('PATCH_EDITABLE_PROJECT', { projectId, patch })
    }
  },

  async onProjectToggleArchive (ctx, { projectId, archive }) {
    throwIfNot(isId, projectId)
    throwIfNot(isBoolean, archive)

    const patch = { is_archived: archive }

    if (ctx.state.projectDetails[projectId]) {
      ctx.commit('PATCH_PROJECT_DETAIL', { projectId, patch })
    }
    if (ctx.state.editableProjectsLoaded) {
      if (archive) {
        ctx.commit('REMOVE_EDITABLE_PROJECT', { projectId })
      } else {
        await ctx.dispatch('loadEditableProjects')
      }
    }
    if (this.state.workspace.lastProjectsLoaded) {
      this.commit('workspace/PATCH_LAST_PROJECT', { projectId, patch })
    }

    const parentGroup = findParentGroup(this, projectId)
    if (parentGroup) {
      this.commit('group/PATCH_GROUP_DETAIL_PROJECT', {
        projectId,
        patch,
        parentGroupId: parentGroup.id,
      })
    }
    const found = this.state.group.currentProjects.list.some(p => p.id === projectId)
    if (found) {
      // If the project is currently in the list, the current filter matches
      // the previous archive state (but not the next).
      // In this case remove the project from the list
      this.commit('group/REMOVE_CURRENT_PROJECT', projectId)
    } else {
      // Otherwise refresh the whole list because it is missing and must be added.
      await this.dispatch('group/loadProjects', { groupId: parentGroup.id })
    }
  },

  async onProjectDelete (ctx, { projectId, groupId }) {
    throwIfNot(isId, projectId)
    const isProjectContext = projectId === ctx.rootState.project.contextId

    if (isProjectContext) {
      if (ctx.state.currentId) {
        this.$router.push(`/workspace/${ctx.rootState.workspace.currentId}/group/${groupId}`)
      }
    }
    await this.dispatch('group/loadGroupDetails')
    await this.dispatch('group/loadMenuGroups')
    await this.dispatch('workspace/loadLastProjects')
    await this.dispatch('group/loadProjects', { groupId })
  },

  async onProjectUserAdd (ctx, { projectId }) {
    throwIfNot(isId, projectId)

    // TODO: reload only if currentUser is the one added to the project
    // Reload in case current user gained access to the parent group of this project
    await this.dispatch('group/loadMenuGroups')

    // TODO: reload only if currentUser is the one added to the project
    // reload opened groups in case one of them is the parent of this project
    // (and current user gained access to the project)
    await this.dispatch('group/loadGroupDetails')

    if (ctx.state.projectDetails[projectId]) {
      await ctx.dispatch('loadProjectDetails', projectId)
    }
    if (projectId === ctx.state.contextId) {
      await ctx.dispatch('loadTeam', projectId)
    }
    if (this.state.group.contextId) {
      await this.dispatch('group/loadTeam', this.state.group.contextId)
    }

    if (this.state.workspace.teamUsersLoaded) {
      await this.dispatch('workspace/loadTeam')
    }
  },

  onProjectUserPatch (ctx, { projectId, userId, patch }) {
    throwIfNot(isId, projectId)
    throwIfNot(isId, userId)
    throwIfNot(isObject, patch)

    const isCurrentUser = userId === this.state.user.me.id
    const rightChanged = 'right' in patch
    if (isCurrentUser && rightChanged) {
      const rightPatch = { current_user_right: patch.right }
      const parentGroupId = ctx.state.projectDetails[projectId]?.group_id

      this.commit('group/PATCH_GROUP_DETAIL_PROJECT', {
        projectId,
        patch: rightPatch,
        parentGroupId,
      })

      if (ctx.state.projectDetails[projectId]) {
        ctx.commit('PATCH_PROJECT_DETAIL', { projectId, patch: rightPatch })
      }
    }

    if (projectId === ctx.state.contextId) {
      ctx.commit('PATCH_TEAM_USER', { userId, patch })
    }
  },

  async onProjectUserRemove (ctx, { projectId, userId }) {
    throwIfNot(isId, projectId)
    throwIfNot(isId, userId)

    const isCurrentUser = userId === this.state.user.me.id
    const isCurrentProject = projectId === ctx.state.currentId

    if (isCurrentUser) {
      // Prefer reloading to avoid complex edge case management

      // Reload menuGroups and groupDetails to remove no longer accessible resources
      await this.dispatch('group/loadMenuGroups')
      await this.dispatch('group/loadGroupDetails')

      // Reload groups of templates
      if (this.state.doc.templateGroupsLoaded) {
        await this.dispatch('doc/loadTemplates')
      }

      const gxid = this.state.group.contextId
      const lostGroupContext = gxid && !this.state.group.groupDetails[gxid]
      const loadedGroupContext = gxid && this.state.group.groupDetails[gxid]
      if (lostGroupContext) {
        await this.dispatch('group/unsetGroupContext')
      }
      if (loadedGroupContext) {
        // group context is maintained, remove the user from child project's users
        this.commit('group/REMOVE_PROJECT_USER', { userId, projectId })
      }

      // Reload target project data to check if still accessible
      await this.dispatch('project/loadProjectDetails', projectId)
      const project = ctx.state.projectDetails[projectId]
      if (project) {
        // still accessible, remove user from project
        this.commit('workspace/REMOVE_LAST_PROJECTS_USER', { projectId, userId })
        if (loadedGroupContext) {
          // remove user from project in current projects
          this.commit('group/REMOVE_CURRENT_PROJECTS_USER', { userId, projectId })
        }
        ctx.commit('REMOVE_TEAM_USER', userId)
      } else {
        // no longer accessible
        this.commit('workspace/REMOVE_LAST_PROJECT', projectId)
        if (loadedGroupContext) {
          // remove project from group current projects
          this.commit('group/REMOVE_CURRENT_PROJECT', projectId)
        }
        ctx.commit('REMOVE_EDITABLE_PROJECT', { projectId })
        if (projectId === ctx.state.contextId) {
          await ctx.dispatch('unsetProjectContext')
        }
      }

      // exit group or project if no longer accessible
      const gcid = this.state.group.currentId
      const lostGroupAccess = gcid && !this.state.group.groupDetails[gcid]
      const lostProjectAccess = isCurrentProject && !ctx.state.projectDetails[projectId]

      if (lostGroupAccess || lostProjectAccess) {
        return this.$router.push(`/workspace/${this.state.workspace.currentId}`)
      }
    } else {
      // Remove user from loaded projects
      this.commit('workspace/REMOVE_LAST_PROJECTS_USER', { projectId, userId })
      if (this.state.group.contextId) {
        this.commit('group/REMOVE_PROJECT_USER', { projectId, userId })
        this.commit('group/REMOVE_CURRENT_PROJECTS_USER', { userId, projectId })
      }
      ctx.commit('REMOVE_PROJECT_DETAIL_USER', { projectId, userId })
      ctx.commit('REMOVE_TEAM_USER', userId)
    }
  },
}

function findParentGroup (store, projectId) {
  for (const group of iterObject(store.state.group.groupDetails)) {
    if (group.projects.some(p => p.id === projectId)) {
      return group
    }
  }
}

addLogOnCall(storeActions)
addLogOnCall(storeEffects)

export const actions = {
  ...storeActions,
  ...storeEffects,
}
