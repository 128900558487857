<template>
  <!-- Panel container -->
  <div
    class="flex-shrink-0 relative ease-in-out"
    :class="opened ? responsiveWidth : 'w-0'"
    style="transition-property: width"
    :style="`transition-duration: ${duration}ms`"
  >
    <!-- Pannel absolute wrapper -->
    <div
      ref="panel"
      class="absolute right-0 w-sidemenu h-full transform ease-in-out"
      :class="{[`translate-x-full ${responsiveTranslate}`]: opened}"
      style="transition-property: transform"
      :style="`transition-duration: ${duration}ms`"
    >
      <!-- Panel content -->
      <slot name="default" />
    </div>
  </div>
</template>

<script>
import { isStrictlyInside } from '~/utils/dom'

const breakpoints = {
  lg: { screen: 1024, widthClass: 'lg:w-sidemenu', translateClass: 'lg:translate-x-0' },
  xl: { screen: 1280, widthClass: 'xl:w-sidemenu', translateClass: 'xl:translate-x-0' },
}

export function shouldBeClosed (size) {
  const config = breakpoints[size]
  if (!config) throw new Error(`Unexpected breakpoint size: ${size}.`)
  return window.innerWidth < config.screen
}

export default {
  name: 'OpenableSidePanel',

  props: {
    opened: { type: Boolean, required: false, default: undefined },
    /**
     * All of the logic associated to this flag follows a choice of layout:
     * -> Main panel should disapear first, contextual panel has more importance.
     *
     * Given that choice, there are 2 consequences if "the screen is too small":
     * 1. When this component is mounted, the panel is closed.
     * 2. At all time, the panel takes no space (is absolute).
     *
     * "The screen is too small" means: "less than lg" if keepWidthLg is true else "less than xl"
     */
    keepWidthLg: { type: Boolean, required: false, default: false },
  },

  data () {
    return {
      duration: 200,
      moving: false,
      movingTimer: undefined,
    }
  },

  computed: {
    responsive () {
      return this.keepWidthLg ? breakpoints.lg : breakpoints.xl
    },

    responsiveWidth () {
      return this.responsive.widthClass
    },

    responsiveTranslate () {
      return this.responsive.translateClass
    },
  },

  watch: {
    opened: {
      immediate: true,
      handler (opened) {
        clearTimeout(this.movingTimer)
        this.moving = true
        this.movingTimer = setTimeout(() => (this.moving = false), this.duration)
      },
    },
  },

  mounted () {
    if (this.opened === undefined) {
      const shouldBeOpened = window.innerWidth >= this.responsive.screen
      this.$emit('openedChanged', shouldBeOpened)
    }
    window.addEventListener('click', this.onClickOutside)
  },

  beforeDestroy () {
    window.removeEventListener('click', this.onClickOutside)
  },

  methods: {
    onClickOutside (mouseEvent) {
      if (isStrictlyInside(mouseEvent, this.$refs.panel)) return
      if (this.moving) return

      if (this.opened) {
        // if screen is too small, menu should be closed when clicking outside
        const shouldBeOpened = window.innerWidth >= this.responsive.screen
        if (!shouldBeOpened) this.$emit('openedChanged', false)
      }
    },
  },
}
</script>
