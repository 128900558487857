<template>
  <div class="px-2 py-4">
    <MenuTreeHeader
      :dropdownActions="menuDocFilterActions"
      :label="menuDocsLabel"
      :disabled="$store.state.publicMode === 'doc'"
      @dropdownAction="executeDropdownAction"
    >
      <button
        v-if="canCreate"
        v-tooltip.slow="$t('Create a new document')"
        class="flex-shrink-0 w-6 bg-gray-900 hover:bg-gray-800 text-white rounded p-2px"
        @click="createNewDocumentation()"
      >
        <PlusSolid />
      </button>
    </MenuTreeHeader>

    <!-- No documentations placeholder -->
    <div v-if="!docTree.children.length" class="text-center mt-20">
      <div class="w-20 text-gray-300 p-1 mx-auto mb-2">
        <DocumentOutlineSlim />
      </div>

      <template v-if="filterActiveDocs">
        <div class="text-sm">{{ $t('No documentation yet.') }}</div>
        <button v-if="currentlyAbleToEdit" class="text-sm" @click="createNewDocumentation()">
          <span>{{ $t('Add your first page') }}</span>
        </button>
      </template>
      <template v-else>
        <div class="text-sm">{{ $t('No archived documentations.') }}</div>
      </template>
    </div>

    <!-- Documentation Tree -->
    <DraggableTree
      v-if="projectId"
      :tree="docTree"
      :linkPrefix="`${projectPath}/doc`"
      :isDocEditable="isDocEditable"
      :canEdit="currentlyAbleToEdit"
      @moveNode="$store.dispatch('project/moveDocument', $event)"
    >
      <template v-slot:node="{ node: slotNode, depth: slotDepth }">
        <div class="py-2px">
          <DocActionLink
            :key="slotNode.item.id"
            :projectId="projectId"
            :doc="slotNode.item"
            :openable="slotNode.children.length > 0"
            :opened="slotNode.opened"
            :canCreate="slotDepth < treeMaxDepth && canCreate"
            :canEditDoc="isDocEditable(slotNode.item)"
            :draggable="isDocEditable(slotNode.item)"
            @toggleOpen="toggleOpen(slotNode)"
            @createDoc="createNewDocumentation(slotNode)"
          />
        </div>
      </template>
    </DraggableTree>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { MenuDocFilter, getFilterLabel } from '~/utils/constants'
import { MAX_DEPTH as treeMaxDepth } from '~/utils/treenode'

import ConfirmModal from '~/components/modals/ConfirmModal'
import DocActionLink from '~/components/menus/items/DocActionLink'
import DocumentOutlineSlim from '~/components/icons/DocumentOutlineSlim'
import DraggableTree from '@/components/lists/DraggableTree'
import MenuTreeHeader from '~/components/menus/items/MenuTreeHeader'
import PlusSolid from "~/components/icons/PlusSolid"

const menuDocFilterActions = Object.keys(MenuDocFilter).map(token => ({
  token,
  labelI18n: MenuDocFilter[token].labelI18n,
}))

export default {
  name: 'ProjectDocsMenu',

  components: {
    DraggableTree,
    DocActionLink,
    DocumentOutlineSlim,
    MenuTreeHeader,
    PlusSolid,
  },

  data () {
    return {
      menuDocFilterActions,
      treeMaxDepth,
    }
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
      currentWorkspace: 'currentWorkspace',
    }),

    ...mapGetters('workspace', [
      'currentlyMember',
    ]),

    ...mapState('project', {
      projectId: 'currentId',
      docTree: 'menuDocs',
      menuDocsFilter: 'menuDocsFilter',
    }),

    ...mapGetters('project', [
      'currentlyAbleToEdit',
    ]),

    projectPath () {
      return `/workspace/${this.workspaceId}/project/${this.projectId}`
    },

    menuDocsLabel () {
      return this.$t(getFilterLabel(MenuDocFilter, this.menuDocsFilter))
    },

    filterActiveDocs () {
      return this.menuDocsFilter === MenuDocFilter.ACTIVE_DOCS.value
    },

    canCreate () {
      return this.currentlyAbleToEdit && this.filterActiveDocs
    },
  },

  methods: {
    isDocEditable (doc) {
      if (doc.is_locked && !this.currentlyMember) return false
      return this.currentlyAbleToEdit
    },

    executeDropdownAction (actionToken) {
      const value = MenuDocFilter[actionToken].value
      this.$store.dispatch('project/updateMenuDocsFilter', value)
    },

    toggleOpen (node) {
      this.$store.dispatch('project/toggleOpen', node)
    },

    async createNewDocumentation (node) {
      if (!this.currentWorkspace.can_add_docs) {
        this.$showModal(ConfirmModal, {
          title: this.$t(`Can't create more documents`),
          description: this.$t(`You have reached the maximum number of documents you can add to your workspace with a free plan. You need to upgrade your subscription plan to add more documents.`),
          confirmLabel: this.$t('Upgrade'),
          onConfirm: () => {
            this.$router.push(`/workspace/${this.workspaceId}/settings/upgrade`)
            this.$closeModal()
          },
        })
      } else {
      // execute api call to create a new doc with parent path if nested
        const createdId = await this.$store.dispatch('doc/createDoc', {
          projectId: this.projectId,
          parentPath: node ? node.path : '',
        })
        if (node && !node.opened) this.toggleOpen(node)
        this.$router.push(`${this.projectPath}/doc/${createdId}`)
      }
    },
  },
}
</script>
