<template>
  <OpenableSidePanel
    :opened="opened"
    keepWidthLg
    class="z-10 h-full"
    @openedChanged="$emit('openedChanged', $event)"
  >
    <div class="h-full flex flex-col bg-gray-30 border-r-2 border-gray-200">
      <ProjectPagesMenu class="flex-shrink-0" />

      <!-- separation -->
      <div class="flex-shrink-0 h-0 border-b-2 border-gray-200" />

      <!-- Documentations -->
      <ProjectDocsMenu class="flex-grow overflow-y-auto" />

      <template v-if="$store.state.publicMode">
        <!-- separation -->
        <div class="flex-shrink-0 h-0 border-b-2 border-gray-200" />

        <!-- Flightlog logo -->
        <div class="flex-shrink-0 p-4 pt-2">
          <div class="text-gray-400 text-xs font-medium mb-2">{{ $t('Powered by') }}</div>
          <div class="flex items-end">
            <div class="h-6">
              <a href="https://get.flightlog-app.io/" target="_blank" rel="noopener noreferrer">
                <img
                  src="/logos/flightlog-app-logo__color-and-text-gray-900.png"
                  alt="Get flightlog!"
                  class="h-full"
                >
              </a>
            </div>
            <div class="text-xs text-gray-400 ml-2 mb-2px">{{ version }}</div>
          </div>
        </div>
      </template>
    </div>

    <!-- Menu show/hide button -->
    <div
      class="absolute top-0 flex items-center p-3 duration-200 ease-in-out"
      :class="{'opacity-0 invisible': hideToggleButton, 'mt-10': archiveBarVisible}"
      style="left: 100%; transition-property: opacity, visibility"
    >
      <button
        class="
          w-6 h-6 text-gray-900 bg-gray-50 hover:bg-gray-30 rounded p-2px
          transform transition-transform duration-200 ease-in-out
        "
        :class="{'rotate-180': !opened}"
        @click="$emit('openedChanged', !opened)"
      >
        <ChevronLeftSolid />
      </button>
    </div>
  </OpenableSidePanel>
</template>

<script>
import { mapGetters } from 'vuex'

import ChevronLeftSolid from "~/components/icons/ChevronLeftSolid"
import OpenableSidePanel, { shouldBeClosed } from "~/components/menus/OpenableSidePanel"
import ProjectPagesMenu from "~/components/menus/ProjectPagesMenu"
import ProjectDocsMenu from "~/components/menus/ProjectDocsMenu"

export default {
  name: 'ProjectMenu',

  components: {
    ChevronLeftSolid,
    OpenableSidePanel,
    ProjectDocsMenu,
    ProjectPagesMenu,
  },

  props: {
    opened: { type: Boolean, required: false, default: undefined },
  },

  data () {
    return {
      mainMenuShouldBeClosed: false,
      version: this.$config.VERSION_NAME,
    }
  },

  computed: {
    ...mapGetters('doc', [
      'currentDoc',
    ]),

    archiveBarVisible () {
      return this.currentDoc ? this.currentDoc.is_archived : false
    },

    hideToggleButton () {
      return this.mainMenuShouldBeClosed && this.$store.state.menuOpened.main
    },
  },

  mounted () {
    this.updateShouldBeClosed()
    window.addEventListener('resize', this.updateShouldBeClosed)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.updateShouldBeClosed)
  },

  methods: {
    updateShouldBeClosed () {
      this.mainMenuShouldBeClosed = shouldBeClosed('xl')
    },
  },
}
</script>
