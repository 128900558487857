<template>
  <BaseDropdown :disabled="disabled" :fromRight="fromRight">
    <template v-slot:button>
      <slot name="default" />
    </template>

    <template v-slot:buttonContent>
      <slot name="buttonContent" />
    </template>

    <template v-slot:content>
      <ActionMenu :actions="actions" @actionClicked="emitDropdownAction" />
    </template>
  </BaseDropdown>
</template>

<script>
import BaseDropdown from '~/components/dropdowns/BaseDropdown'
import ActionMenu from '~/components/menus/ActionMenu'

export default {
  name: 'ActionDropdown',

  components: {
    ActionMenu,
    BaseDropdown,
  },

  props: {
    actions: { type: Array, required: true, default: () => [] },
    disabled: { type: Boolean, required: false, default: false },
    fromRight: { type: Boolean, required: false, default: false },
  },

  methods: {
    emitDropdownAction (token) {
      document.activeElement.blur()
      this.$emit('dropdownAction', token)
    },
  },
}
</script>
