<template>
  <BaseModal :title="$t(conf.title)" @modalClose="close">
    <template v-slot:body>
      <div class="max-w-full" style="width: 544px">
        <!-- Error hint -->
        <ErrorCard :error="error" class="mb-6" />

        <!-- Share toggle -->
        <SwitchInput v-model="shareTokenToggler">
          <template v-slot:default>
            <div class="ml-2">{{ $t('Public link: ' + (shareToken ? 'On' : 'Off')) }}</div>
          </template>
        </SwitchInput>

        <!-- Hint -->
        <div class="mt-2">
          {{ $t('Anyone who has the link can access. No sign-in required. If you no longer need this link, desactivate it.') }}
        </div>

        <!-- Share link display -->
        <div class="flex items-center text-gray-400 border-2 border-gray-300 rounded p-1 mt-4">
          <input
            ref="linkToCopy"
            type="text"
            disabled
            :value="publicLink"
            :placeholder="$t('No share link')"
            class="flex-grow bg-white px-2"
          >

          <button
            type="button"
            :disabled="!shareToken"
            class="flex-shrink-0 Btn Btn-filled Btn-xs"
            @click="copyToClipboard"
          >
            <span>{{ $t(linkCopied ? 'Copied!' : 'Copy') }}</span>
          </button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '~/components/modals/BaseModal'
import SwitchInput from '~/components/inputs/SwitchInput'
import ErrorCard from '~/components/cards/ErrorCard'

export default {
  name: 'ShareModal',

  components: {
    BaseModal,
    SwitchInput,
    ErrorCard,
  },

  props: {
    resourceType: { type: String, required: true, default: '' },
    resource: { type: Object, required: true, default: () => ({}) },
  },

  data () {
    return {
      linkCopied: false,
      error: undefined,
    }
  },

  computed: {
    conf () {
      const r = this.resource
      switch (this.resourceType) {
        case 'project':
          return {
            title: 'Share project',
            routePath: `/workspace/public/project/${r.id}`,
            toggleAction: 'project/toggleShareToken',
            togglePayload: { projectId: r.id },
          }

        case 'doc':
          return {
            title: 'Share document',
            routePath: `/workspace/public/project/${r.project_id}/doc/${r.id}`,
            toggleAction: 'doc/toggleShareToken',
            togglePayload: { projectId: r.project_id, docId: r.id },
          }

        default: throw new Error(`Unexpected shared resource type: ${this.resourceType}`)
      }
    },

    shareToken () {
      return this.resource.share_token
    },

    publicLink () {
      if (!this.shareToken) return ''
      const queryString = `?publicMode=${this.resourceType}&publicToken=${this.shareToken}`
      // TODO: adapter quand on passera en SSR (window non disponible côté serveur)
      return window.location.origin + this.conf.routePath + queryString
    },

    shareTokenToggler: {
      get () { return Boolean(this.shareToken) },
      async set (v) { await this.toggleShareToken() },
    },
  },

  methods: {
    async toggleShareToken () {
      try {
        await this.$store.dispatch(this.conf.toggleAction, this.conf.togglePayload)
      } catch (e) {
        this.error = e
      }
    },

    copyToClipboard () {
      const el = this.$refs.linkToCopy
      el.removeAttribute('disabled')
      el.select()

      try {
        document.execCommand('copy')
        this.linkCopied = true
      } catch (err) {
        this.error = err
      }

      el.blur()
      window.getSelection().removeAllRanges()
      el.setAttribute('disabled', 'disabled')
    },

    close () {
      this.$emit('modalClose')
    },
  },
}
</script>
