
/**
* This middleware is meant for the group part of onboarding.
* It prevents access from anywhere but the workspace onboarding page.
*
* REQUIREMENTS: Must be called after 'auth' middleware.
*/
export default async function signupGroup ({ store, from, redirect }) {
  console.info('[middleware::signupGroup]')
  if (from.path !== '/signup/workspace') {
    const wsPath = await store.dispatch('workspace/fallbackWorkspacePath')
    return redirect(wsPath)
  }
}
