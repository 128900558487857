import Vue from 'vue'

export default ({ $config, req, $axios, route, error: nuxtError, redirect }) => {
  const host = process.server ? req.headers.host : window.location.host
  const API_URL = $config.API_URL || `https://${host}/api`
  const MEDIA = $config.MEDIA_URL || `https://${host}/media`
  const WS = $config.WEBSOCKET_URL || `wss://${host}/ws`

  Vue.use({
    install (Vue) {
      Vue.prototype.$mediaUrl = filename => `${MEDIA}/${filename}`
      Vue.prototype.$wsDocUrl = docId => `${WS}/docs/${docId}/live`
    },
  })

  $axios.setBaseURL(API_URL)

  $axios.onError((error) => {
    if (!error.response) {
      if (error.message === 'Network Error') {
        throw nuxtError({
          statusCode: 408,
          actionLink: route,
          actionLabel: 'Try again',
        })
      }
      throw nuxtError({
        description: error.message,
        actionLink: route,
        actionLabel: 'Try again',
      })
    }
  })
}
