/**
* This middleware must be used in routes that require a workspace context.
*
* REQUIREMENTS: Must be called after 'auth' middleware.
*/
export default async function workspace ({ store, params, redirect, error }) {
  console.info('[middleware::workspace]')
  /**
   * Special public mode
   * No authentication is required in this mode. Because of that,
   * there are no current workspaces, so abort workspace resolution,
   * but set the current id to reduce side effects. It should be "public" in this case.
   */
  if (store.state.publicMode) {
    const wsid = params.wsid || 'public' // should always be 'public' in this case
    return store.commit('workspace/SET_CURRENT_ID', wsid)
  }

  // Normal mode
  const workspaceId = Number(params.wsid)
  if (!workspaceId) {
    return error({ statusCode: 404 })
  }

  // Load user personal workspaces
  const pws = await store.dispatch('workspace/loadWorkspaces', { useCache: true })
  // If the user has no workspaces, they must create one.
  if (!pws.length) {
    return redirect('/signup/workspace')
  }

  // Ensure the given workspace id is known
  if (!pws.some(pw => pw.workspace.id === workspaceId)) {
    return error({ statusCode: 404 })
  }

  // Start a workspace context
  await store.dispatch('workspace/setCurrentWorkspace', workspaceId)
}
