const middleware = {}

middleware['activeSubscriptionRequired'] = require('../middleware/activeSubscriptionRequired.js')
middleware['activeSubscriptionRequired'] = middleware['activeSubscriptionRequired'].default || middleware['activeSubscriptionRequired']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['billing'] = require('../middleware/billing.js')
middleware['billing'] = middleware['billing'].default || middleware['billing']

middleware['context/doc'] = require('../middleware/context/doc.js')
middleware['context/doc'] = middleware['context/doc'].default || middleware['context/doc']

middleware['context/group'] = require('../middleware/context/group.js')
middleware['context/group'] = middleware['context/group'].default || middleware['context/group']

middleware['context/log'] = require('../middleware/context/log.js')
middleware['context/log'] = middleware['context/log'].default || middleware['context/log']

middleware['context/project'] = require('../middleware/context/project.js')
middleware['context/project'] = middleware['context/project'].default || middleware['context/project']

middleware['customerRequired'] = require('../middleware/customerRequired.js')
middleware['customerRequired'] = middleware['customerRequired'].default || middleware['customerRequired']

middleware['emailReset'] = require('../middleware/emailReset.js')
middleware['emailReset'] = middleware['emailReset'].default || middleware['emailReset']

middleware['invitation'] = require('../middleware/invitation.js')
middleware['invitation'] = middleware['invitation'].default || middleware['invitation']

middleware['noauth'] = require('../middleware/noauth.js')
middleware['noauth'] = middleware['noauth'].default || middleware['noauth']

middleware['passwordReset'] = require('../middleware/passwordReset.js')
middleware['passwordReset'] = middleware['passwordReset'].default || middleware['passwordReset']

middleware['paymentMethodRequired'] = require('../middleware/paymentMethodRequired.js')
middleware['paymentMethodRequired'] = middleware['paymentMethodRequired'].default || middleware['paymentMethodRequired']

middleware['signupGroup'] = require('../middleware/signupGroup.js')
middleware['signupGroup'] = middleware['signupGroup'].default || middleware['signupGroup']

middleware['signupProfile'] = require('../middleware/signupProfile.js')
middleware['signupProfile'] = middleware['signupProfile'].default || middleware['signupProfile']

middleware['signupTeam'] = require('../middleware/signupTeam.js')
middleware['signupTeam'] = middleware['signupTeam'].default || middleware['signupTeam']

middleware['signupWorkspace'] = require('../middleware/signupWorkspace.js')
middleware['signupWorkspace'] = middleware['signupWorkspace'].default || middleware['signupWorkspace']

middleware['tokenQuery'] = require('../middleware/tokenQuery.js')
middleware['tokenQuery'] = middleware['tokenQuery'].default || middleware['tokenQuery']

middleware['workspace'] = require('../middleware/workspace.js')
middleware['workspace'] = middleware['workspace'].default || middleware['workspace']

middleware['workspaceCreateDemo'] = require('../middleware/workspaceCreateDemo.js')
middleware['workspaceCreateDemo'] = middleware['workspaceCreateDemo'].default || middleware['workspaceCreateDemo']

export default middleware
