import {
  addLogOnCall,
  isBoolean,
  isId,
  isObject,
  iterObject,
  map,
  patchObject,
  removeFrom,
  setReactiveObjectKey,
  throwIfNot,
} from '~/utils/common'
import { MenuGroupFilter, ProjectFilter, GROUP_PROJECTS_LIMIT } from '~/utils/constants'
import { isAtLeastEdit, isAtLeastInvite } from '~/utils/rights'

// =================================== STATE ===================================

export const state = () => ({
  currentId: undefined,
  menuGroups: [],
  menuGroupsFilter: MenuGroupFilter.ALL_GROUPS.value,
  menuGroupsLoaded: false,
  groupDetails: {},
  openedInMenu: {},
  contextId: undefined,
  groupUsers: [],
  projectUsers: [],
  currentProjects: {
    list: [],
    filter: ProjectFilter.ACTIVE_PROJECTS.value,
    limit: GROUP_PROJECTS_LIMIT, // load action will limit its result to that number if defined
  },
})

// ================================== GETTERS ==================================

/**
 * @type {import("vuex").GetterTree<typeof state>}
 */
export const getters = {
  noGroupsInMenu: state => state.menuGroups.length === 0,
  currentGroup: state => state.groupDetails[state.currentId],
  currentName: (state, get) => get.currentGroup?.name || '',
  currentPrivacy: (state, get) => get.currentGroup?.privacy,
  currentRight: (state, get) => get.currentGroup?.right,
  currentUserRight: (state, get) => get.currentGroup?.current_user_right,
  currentlyAbleToEdit: (state, get) => isAtLeastEdit(get.currentUserRight),
  currentlyAbleToInvite: (state, get) => isAtLeastInvite(get.currentUserRight),
}

// ================================= MUTATIONS =================================

/**
 * @type {import("vuex").MutationTree<typeof state>}
 */
export const mutations = {
  SET_MENU_GROUPS (state, payload) {
    state.menuGroups = payload || []
    state.menuGroupsLoaded = Array.isArray(payload)
  },
  PATCH_MENU_GROUP (state, { groupId, patch }) {
    for (const g of state.menuGroups) {
      if (g.id === groupId) {
        patchObject(g, patch)
      }
    }
  },
  REMOVE_MENU_GROUP (state, groupId) {
    removeFrom(state.menuGroups, g => g.id === groupId)
  },
  SET_MENU_GROUPS_FILTER (state, menuGroupsFilter) {
    state.menuGroupsFilter = menuGroupsFilter || this.$localStorage.userMenuGroupPref.get() || MenuGroupFilter.ALL_GROUPS.value
  },
  SET_OPENED_IN_MENU (state, payload) {
    if (!payload) state.openedInMenu = {} // handle reset use case
    else {
      const { groupId, opened } = payload
      if (!groupId || !isBoolean(opened)) {
        throw new Error(`Invalid payload for SET_OPENED_IN_MENU: ${JSON.stringify(payload)}`)
      }
      setReactiveObjectKey(state.openedInMenu, groupId, Boolean(opened))
    }
  },
  SET_GROUP_DETAILS (state, payload) {
    if (!payload) state.groupDetails = {} // handle reset use case
    else {
      const { groupId, data } = payload
      if (!groupId || (data !== null && !isObject(data))) {
        throw new Error(`Invalid payload for SET_GROUP_DETAILS: ${JSON.stringify(payload)}`)
      }
      setReactiveObjectKey(state.groupDetails, groupId, data)
    }
  },
  PATCH_GROUP_DETAIL (state, { groupId, patch }) {
    patchObject(state.groupDetails[groupId], patch)
  },
  PATCH_GROUP_DETAIL_USER (state, { userId, patch }) {
    for (const group of iterObject(state.groupDetails)) {
      for (const u of group.users) {
        if (u.id === userId) {
          patchObject(u, patch)
        }
      }
    }
  },
  REMOVE_GROUP_DETAIL_USER (state, { userId, groupId = null }) {
    for (const group of iterObject(state.groupDetails, groupId)) {
      removeFrom(group.users, u => u.id === userId)
    }
  },
  PATCH_GROUP_DETAIL_PROJECT (state, { projectId, patch, parentGroupId = null }) {
    for (const group of iterObject(state.groupDetails, parentGroupId)) {
      const project = group.projects.find(p => p.id === projectId)
      if (project) {
        patchObject(project, patch)
        break
      }
    }
  },
  SET_CURRENT_ID (state, groupId) {
    state.currentId = Number(groupId) || null
  },
  SET_CONTEXT_ID (state, groupId) {
    // `contextId` is the last group id that was used to load contextual data.
    // It must not be confused with `currentId` which is the id of the current group.
    // When exiting current group, `currentId` is unset but the `contextId` remains,
    // which allows keeping previously loaded data in some cases.
    state.contextId = Number(groupId) || null
  },
  SET_TEAM_USERS (state, data = {}) {
    state.groupUsers = data.group_users || []
    state.projectUsers = data.project_users || []
  },
  PATCH_GROUP_USER (state, { userId, patch }) {
    state.groupUsers.forEach((tu) => {
      if (tu.user.id === userId) {
        patchObject(tu, patch)
      }
    })
  },
  REMOVE_GROUP_USER (state, userId) {
    removeFrom(state.groupUsers, tu => tu.user.id === userId)
  },
  PATCH_PROJECT_USER (state, { userId, patch }) {
    state.projectUsers.forEach((tu) => {
      if (tu.user.id === userId) {
        patchObject(tu, patch)
      }
    })
  },
  REMOVE_PROJECT_USER (state, { userId, projectId = null }) {
    const predicate = projectId !== null
      ? tu => tu.user.id === userId && tu.projects.includes(projectId)
      : tu => tu.user.id === userId
    removeFrom(state.projectUsers, predicate)
  },
  SET_CURRENT_PROJECTS (state, payload) {
    patchObject(state.currentProjects, payload || /* Or reset to default values */ {
      list: [],
      filter: ProjectFilter.ACTIVE_PROJECTS.value,
      limit: GROUP_PROJECTS_LIMIT,
    })
  },
  PATCH_CURRENT_PROJECT (state, { projectId, patch }) {
    for (const p of state.currentProjects.list) {
      if (p.id === projectId) {
        patchObject(p, patch)
      }
    }
    if (patch.last_update) {
      // reorder projects by descending last_update
      state.currentProjects.list.sort((a, b) => {
        if (a.last_update < b.last_update) return 1
        if (a.last_update > b.last_update) return -1
        return 0
      })
    }
  },
  REMOVE_CURRENT_PROJECT (state, projectId) {
    removeFrom(state.currentProjects.list, p => p.id === projectId)
  },
  PATCH_CURRENT_PROJECTS_USER (state, { userId, patch }) {
    for (const p of state.currentProjects.list) {
      for (const u of p.users) {
        if (u.id === userId) {
          patchObject(u, patch)
        }
      }
    }
  },
  REMOVE_CURRENT_PROJECTS_USER (state, { userId, projectId = null }) {
    for (const p of state.currentProjects.list) {
      if (projectId && projectId !== p.id) continue
      removeFrom(p.users, u => u.id === userId)
    }
  },
}

// ================================== ACTIONS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeActions = {
  _reset (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined)
    }
  },

  async create (ctx, payload) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const { data } = await this.$axios.post(`/groups`, payload, { headers })

    await ctx.dispatch('onGroupCreate')

    return data.created_id
  },

  async setCurrentGroup (ctx, groupId) {
    if (groupId === ctx.state.currentId) return
    throwIfNot(isId, groupId)

    ctx.commit('SET_CURRENT_ID', groupId)
    if (!ctx.state.groupDetails[groupId]) {
      await ctx.dispatch('loadGroupDetails', [groupId])
    }

    await ctx.dispatch('setGroupContext', groupId)
  },

  unsetCurrentGroup (ctx) {
    ctx.commit('SET_CURRENT_ID')
  },

  async setGroupContext (ctx, groupId) {
    if (groupId === ctx.state.contextId) return
    ctx.commit('SET_CONTEXT_ID', groupId)

    await ctx.dispatch('loadTeam', groupId)
    await ctx.dispatch('loadProjects', { groupId })
  },

  unsetGroupContext (ctx) {
    const groupId = ctx.state.contextId

    ctx.commit('SET_GROUP_DETAILS', { groupId, data: null })
    ctx.commit('SET_OPENED_IN_MENU', { groupId, opened: false })
    ctx.commit('SET_CONTEXT_ID')
    ctx.commit('SET_TEAM_USERS')
    ctx.commit('SET_CURRENT_PROJECTS')
  },

  unsetGroupData (ctx, groupId) {
    if (ctx.state.groupDetails[groupId]) {
      ctx.commit('SET_GROUP_DETAILS', { groupId, data: null })
    }
    if (ctx.state.openedInMenu[groupId]) {
      ctx.commit('SET_OPENED_IN_MENU', { groupId, opened: false })
    }
  },

  async archiveGroup (ctx, { groupId, with_projects }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, groupId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }
    const params = { with_projects }
    const result = await this.$axios.put(`/groups/${groupId}/archive`, null, { headers, params })

    await ctx.dispatch('onGroupToggleArchive', {
      groupId,
      archive: true,
      withProjects: with_projects || false,
    })

    return result
  },

  async restoreGroup (ctx, { groupId, with_projects }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, groupId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }
    const params = { with_projects }
    const result = await this.$axios.put(`/groups/${groupId}/restore`, null, { headers, params })

    await ctx.dispatch('onGroupToggleArchive', {
      groupId,
      archive: false,
      withProjects: with_projects || false,
    })

    return result
  },

  async deleteGroup (ctx, { groupId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.delete(`/groups/${groupId}`, { headers })

    await ctx.dispatch('onGroupDelete', {
      groupId,
    })

    return result
  },

  async loadMenuGroups (ctx, { useCache = false } = {}) {
    // NOTE: menuGroups are only accessible to signed users, no need to check for publicMode
    if (useCache === true && ctx.state.menuGroupsLoaded) return

    throwIfNot(isId, ctx.rootState.workspace.currentId)

    const params = { only: ctx.state.menuGroupsFilter }
    const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }

    const { data: menuGroups } = await this.$axios.get(`/groups/names`, { params, headers })

    ctx.commit('SET_MENU_GROUPS', menuGroups)
  },

  async updateMenuGroupsFilter (ctx, newValue) {
    if (newValue === ctx.state.menuGroupsFilter) return

    this.$localStorage.userMenuGroupPref.set(newValue)
    ctx.commit('SET_MENU_GROUPS_FILTER', newValue)

    await ctx.dispatch('loadMenuGroups')
  },

  async toggleOpen (ctx, groupId) {
    throwIfNot(isId, groupId)

    const opened = !ctx.state.openedInMenu[groupId]
    ctx.commit('SET_OPENED_IN_MENU', { groupId, opened })
    // load group data if missing
    if (opened && !ctx.state.groupDetails[groupId]) {
      await ctx.dispatch('loadGroupDetails', [groupId])
    }
    // TODO: save opened groups (by workspace) to localStorage/cookie
  },

  async loadGroupDetails (ctx, groupIds) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    // Default to all loaded groups
    if (!groupIds) groupIds = map(iterObject(ctx.state.groupDetails), g => g.id)
    if (!groupIds.length) return // abort if there are no groups to reload

    const params = { ids: groupIds.join() }
    const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }

    const groups = await this.$axios.$get(`/groups`, { params, headers })

    // Update recovered data
    const recoveredIds = new Set()
    for (const group of groups) {
      recoveredIds.add(group.id)
      ctx.commit('SET_GROUP_DETAILS', { groupId: group.id, data: group })
    }

    // Remove no longer accessible data
    for (const groupId of groupIds) {
      if (!recoveredIds.has(groupId)) {
        ctx.commit('SET_GROUP_DETAILS', { groupId, data: null })
        ctx.commit('SET_OPENED_IN_MENU', { groupId, opened: false })
      }
    }
  },

  async loadTeam (ctx, groupId) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, groupId)

    const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }

    const { data: teamData } = await this.$axios.get(`/groups/${groupId}/team`, { headers })

    ctx.commit('SET_TEAM_USERS', teamData)
    return teamData
  },

  async loadProjects (ctx, { groupId, useCache = false }) {
    if (useCache && ctx.state.contextId === groupId) return

    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, groupId)

    const headers = { 'x-current-workspace-id': ctx.rootState.workspace.currentId }
    const params = {
      group_id: groupId,
      limit: ctx.state.currentProjects.limit,
      only: ctx.state.currentProjects.filter,
    }

    const { data } = await this.$axios.get(`/projects`, { params, headers })

    ctx.commit('SET_CURRENT_PROJECTS', { list: data })
    return data
  },

  async updateProjectsFilter (ctx, { groupId, newValue }) {
    if (newValue === ctx.state.currentProjects.filter) return

    ctx.commit('SET_CURRENT_PROJECTS', { filter: newValue })

    await ctx.dispatch('loadProjects', { groupId })
  },

  async addToTeam (ctx, { groupId, emails }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, groupId)

    const body = { invites: emails }
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.post(`/groups/${groupId}/team`, body, { headers })

    await ctx.dispatch('onGroupUserAdd', { groupId })

    return result
  },

  async updateUserRight (ctx, { groupId, userId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, groupId)
    throwIfNot(isId, userId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.put(`/groups/${groupId}/team/${userId}`, body, { headers })

    await ctx.dispatch('onGroupUserPatch', { groupId, userId, patch: body })
  },

  async removeFromTeam (ctx, { groupId, userId }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, groupId)
    throwIfNot(isId, userId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.delete(`/groups/${groupId}/team/${userId}`, { headers })

    await ctx.dispatch('onGroupUserRemove', { groupId, userId })
  },

  async updateSettings (ctx, { groupId, ...body }) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)
    throwIfNot(isId, groupId)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.put(`/groups/${groupId}`, body, { headers })

    await ctx.dispatch('onGroupPatch', { groupId, patch: body })
  },

  async toggleProjectsLimit (ctx, groupId) {
    throwIfNot(isId, groupId)

    const projects = ctx.state.currentProjects
    ctx.commit('SET_CURRENT_PROJECTS', { limit: projects.limit ? undefined : GROUP_PROJECTS_LIMIT })

    // XXX: always refresh when enabling (even if we already loaded projects before)
    // XXX: simply cut existing projects otherwise
    if (projects.limit) {
      ctx.commit('SET_CURRENT_PROJECTS', { list: projects.list.slice(0, projects.limit) })
    } else {
      await ctx.dispatch('loadProjects', { groupId })
    }
  },
}

// ================================== EFFECTS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeEffects = {
  async onGroupCreate (ctx) {
    if (ctx.state.menuGroupsFilter === MenuGroupFilter.ARCHIVED_GROUPS.value) return

    await ctx.dispatch('loadMenuGroups')
    // NOTE: Reloading groups of editable projects is not necessary because a created group
    // cannot contain any project
  },

  async onGroupPatch (ctx, { groupId, patch }) {
    throwIfNot(isId, groupId)
    throwIfNot(isObject, patch)

    const namePatch = 'name' in patch ? { name: patch.name } : null
    const privacyChanged = 'privacy' in patch
    const wasAccessible = ctx.state.menuGroups.some(g => g.id === groupId)

    if (privacyChanged) {
      // Reloading menuGroups is required because:
      // - a previously inaccessible group could become accessible
      // - a group currently accessible could become inaccessible
      // - current_user_right may change and must be recovered from the API
      await ctx.dispatch('loadMenuGroups')
    } else if (namePatch) {
      ctx.commit('PATCH_MENU_GROUP', { groupId, patch: namePatch })
    }

    if (ctx.state.groupDetails[groupId]) {
      await ctx.dispatch('loadGroupDetails', [groupId])
    }

    const menuGroup = ctx.state.menuGroups.find(g => g.id === groupId)

    // no longer accessible
    if (!menuGroup && wasAccessible) {
      if (groupId === ctx.state.contextId) {
        await ctx.dispatch('unsetGroupContext')
      } else {
        await ctx.dispatch('unsetGroupData', groupId)
      }
      this.commit('project/REMOVE_EDITABLE_PROJECT_GROUP', groupId)
      this.commit('doc/REMOVE_TEMPLATE_GROUP', groupId)

      if (groupId === ctx.state.currentId) {
        this.$router.push(`/workspace/${this.state.workspace.currentId}`)
      }
    }

    // accessible
    if (menuGroup) {
      if (!wasAccessible) {
        // If it became accessible, update loaded resources
        if (this.state.project.editableProjectsLoaded) {
          await this.dispatch('project/loadEditableProjects')
        }
        if (this.state.doc.templateGroupsLoaded) {
          await this.dispatch('doc/loadTemplates')
        }
      } else if (namePatch) {
        this.commit('project/PATCH_EDITABLE_PROJECT_GROUP', { groupId, patch: namePatch })
        this.commit('doc/PATCH_TEMPLATE_GROUP', { groupId, patch: namePatch })
      }
    }
  },

  async onGroupToggleArchive (ctx, { groupId, archive, withProjects }) {
    throwIfNot(isId, groupId)
    throwIfNot(isBoolean, archive)
    throwIfNot(isBoolean, withProjects)

    const workspaceId = this.state.workspace.currentId
    const isCurrentGroup = groupId === ctx.state.currentId
    const currentProjectIsChild = groupId === this.getters['project/currentGroupId']
    const onArchivedGroups = ctx.state.menuGroupsFilter === MenuGroupFilter.ARCHIVED_GROUPS.value
    const onArchivedProjects = ctx.state.currentProjects.filter === ProjectFilter.ARCHIVED_PROJECTS.value

    const target = ctx.state.groupDetails[groupId]
    if (target) {
      const patch = { is_archived: archive }
      if (withProjects) patch.projects = target.projects.map(p => ({ ...p, is_archived: archive }))
      ctx.commit('PATCH_GROUP_DETAIL', { groupId, patch })
    }

    if (!isCurrentGroup && currentProjectIsChild && !archive && onArchivedGroups) {
      // Special case:
      // - except if target group is current group (because in this case we redirect below)
      // - there is a current project and it is a child of target group
      // - the action is to restore and the current "menu groups filter" is incorrect
      await ctx.dispatch('updateMenuGroupsFilter', MenuGroupFilter.ALL_GROUPS.value)
    } else if (archive !== onArchivedGroups) {
      // most probable case, the group was visible in the menu and should no longer be
      ctx.commit('REMOVE_MENU_GROUP', groupId)
    } else {
      // exceptional case, current "menu groups filter" already matches the new archive state
      // -> reload to add the group in menu
      await ctx.dispatch('loadMenuGroups')
    }

    if (withProjects) {
      // Patch all loaded projects that are children of the target group
      for (const project of iterObject(this.state.project.projectDetails)) {
        if (project.group_id === groupId) {
          this.commit('project/PATCH_PROJECT_DETAIL', {
            projectId: project.id,
            patch: { is_archived: archive },
          })
        }
      }

      // Update all workspace last projects
      if (this.state.workspace.lastProjectsLoaded) {
        const updated = this.state.workspace.lastProjects.map(p => ({ ...p, is_archived: archive }))
        this.commit('workspace/SET_LAST_PROJECTS', updated)
      }

      // Reload or remove group current projects
      if (groupId === ctx.state.contextId) {
        if (archive === onArchivedProjects) { // Add child projects to currentProjects
          await ctx.dispatch('loadProjects', { groupId })
        } else { // Remove child projects from currentProjects
          ctx.commit('SET_CURRENT_PROJECTS', { list: [] })
        }
      }
    }

    // Archived projects and groups do not appear in this resource, so toggling archive
    // will always change it and thus requires a reload.
    if (this.state.project.editableProjectsLoaded) {
      await this.dispatch('project/loadEditableProjects')
    }

    if (isCurrentGroup) {
      // Current group is the target group, redirect outside
      return this.$router.push(`/workspace/${workspaceId}`)
    }

    // If child projects become archived and current project is among them, redirect outside
    if (archive && withProjects && currentProjectIsChild) {
      return this.$router.push(`/workspace/${workspaceId}`)
    }
  },

  async onGroupDelete (ctx, { groupId }) {
    throwIfNot(isId, groupId)
    const isGroupContext = groupId === this.state.group.contextId

    if (isGroupContext) {
      if (ctx.state.contextId) {
        this.$router.push(`/workspace/${this.state.workspace.currentId}`)
      }
    }
    await this.dispatch('group/loadGroupDetails')
    await this.dispatch('group/loadMenuGroups')
    await this.dispatch('workspace/loadLastProjects')
  },

  async onGroupUserAdd (ctx, { groupId }) {
    throwIfNot(isId, groupId)

    // TODO: Reload ONLY IF current user is among added users OR if groupId is known
    await ctx.dispatch('loadMenuGroups')

    // If group has already been loaded, update its users
    if (ctx.state.groupDetails[groupId]) {
      await ctx.dispatch('loadGroupDetails', [groupId])
    }

    // If target group is current context, update users in group team
    if (groupId === ctx.state.contextId) {
      await ctx.dispatch('loadTeam', groupId)
    }

    if (this.state.workspace.teamUsersLoaded) {
      await this.dispatch('workspace/loadTeam')
    }
  },

  onGroupUserPatch (ctx, { groupId, userId, patch }) {
    throwIfNot(isId, groupId)
    throwIfNot(isId, userId)
    throwIfNot(isObject, patch)

    const rightChanged = 'right' in patch
    const isCurrentUser = userId === this.state.user.me.id

    // To receive this action, the target user must be in group users
    // If right changed and target user is the current user, update current_user_right
    if (rightChanged && isCurrentUser) {
      const rightPatch = { current_user_right: patch.right }
      if (ctx.state.groupDetails[groupId]) {
        ctx.commit('PATCH_GROUP_DETAIL', { groupId, patch: rightPatch })
      }
      ctx.commit('PATCH_MENU_GROUP', { groupId, patch: rightPatch })
    }

    if (ctx.state.contextId === groupId) {
      ctx.commit('PATCH_GROUP_USER', { userId, patch })
    }
  },

  async onGroupUserRemove (ctx, { groupId, userId }) {
    throwIfNot(isId, groupId)
    throwIfNot(isId, userId)

    const isCurrentUser = userId === this.state.user.me.id
    const isCurrentGroup = groupId === ctx.state.currentId
    const isGroupContext = groupId === ctx.state.contextId
    const filterOwnGroups = ctx.state.menuGroupsFilter === MenuGroupFilter.MY_GROUPS.value

    if (isCurrentUser) {
      // Because target is current user, if filter currently shows "my groups"
      // and current group is the target group, change filter to where the current
      // group will then be visible (active or archive)
      if (isCurrentGroup && filterOwnGroups) {
        const nextFilter = ctx.getters.currentGroup.is_archived
          ? MenuGroupFilter.ARCHIVED_GROUPS.value
          : MenuGroupFilter.ALL_GROUPS.value
        await ctx.dispatch('updateMenuGroupsFilter', nextFilter)
      } else {
        await ctx.dispatch('loadMenuGroups')
      }

      const group = ctx.state.menuGroups.find(g => g.id === groupId)
      if (!group) {
        // Group is no longer accessible
        // reload last projects in case some of them are no longer accessible
        if (this.state.workspace.lastProjectsLoaded) {
          // TODO: set projects where group_id !== groupId
          await this.dispatch('workspace/loadLastProjects')
        }

        if (isGroupContext) {
          await ctx.dispatch('unsetGroupContext')
        } else {
          await ctx.dispatch('unsetGroupData', groupId)
        }

        for (const project of iterObject(this.state.project.projectDetails)) {
          // if target group is not project's group id, project cannot be affected
          if (groupId !== project.group_id) continue
          // Otherwise project is no longer accessible, delete its data
          this.commit('project/SET_PROJECT_DETAILS', { projectId: project.id, data: null })
        }

        this.commit('project/REMOVE_EDITABLE_PROJECT_GROUP', groupId)
        this.commit('doc/REMOVE_TEMPLATE_GROUP', groupId)

        // exit group or project if no longer accessible
        const pcid = this.state.project.currentId
        const lostProjectAccess = pcid && !this.state.project.projectDetails[pcid]

        if (isCurrentGroup || lostProjectAccess) {
          this.$router.push(`/workspace/${this.state.workspace.currentId}`)
        }
      } else {
        // Group is still accessible.
        if (isGroupContext) {
          // Must reload because user is no longer in group users,
          // and may appear in child projects' users instead
          await ctx.dispatch('loadTeam', groupId)
        }
        if (ctx.state.groupDetails[groupId]) {
          ctx.commit('REMOVE_GROUP_DETAIL_USER', { groupId, userId })
          ctx.commit('PATCH_GROUP_DETAIL', {
            groupId,
            patch: { current_user_right: group.current_user_right },
          })
        }
      }
    } else {
      this.commit('workspace/REMOVE_LAST_PROJECTS_USER', { userId })
      if (ctx.state.groupDetails[groupId]) {
        ctx.commit('REMOVE_GROUP_DETAIL_USER', { groupId, userId })
      }
      if (isGroupContext) {
        // Must reload because user is no longer in group users,
        // and may appear in child projects' users instead
        await ctx.dispatch('loadTeam', groupId)
        ctx.commit('REMOVE_CURRENT_PROJECTS_USER', { userId })
      }
    }
  },
}

addLogOnCall(storeActions)
addLogOnCall(storeEffects)

export const actions = {
  ...storeActions,
  ...storeEffects,
}
