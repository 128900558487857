const lib = require('emoji.json')
export const emojis = Object.create(null)
for (const e of lib) {
  emojis[e.char] = e.name
}

const _emojisChars = Object.keys(emojis)
const _emojisNames = Object.values(emojis)

export function filterEmojis (searchValue) {
  if (!searchValue) return _emojisChars

  return _emojisChars.filter((char, index) => _emojisNames[index].includes(searchValue))
}
