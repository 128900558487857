
/**
 * This middleware is meant for the workspace creation step of the onboarding process.
 *
 * REQUIREMENTS:
 *   - Must be called after 'auth' middleware.
 */
export default async function signupWorkspace ({ store, route, query, redirect }) {
  console.info('[middleware::signupWorkspace]')

  if (query.process === 'onboarding') {
    var wsPath = await store.dispatch('workspace/fallbackWorkspacePath')
    const newName = store.$router.resolve(wsPath).route.name
    if (newName !== route.name) {
      return redirect(wsPath)
    }
  } else {
    await store.dispatch('workspace/loadWorkspaces', { useCache: true })
  }
}
