import { emailResetError } from "~/utils/errors"

/**
* This middleware is meant for the email reset page.
*/
export default async function emailReset ({ store, query, error }) {
  console.info('[middleware::emailReset]')
  if (!query.token) return error({ statusCode: 404 })

  try {
    await store.dispatch('user/resetEmail', query.token)
  } catch (e) {
    return error(emailResetError(e))
  }
}
