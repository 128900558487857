<template>
  <BaseModal @modalClose="$emit('modalClose')">
    <template v-slot:header>
      <!-- Decoration -->
      <div class="absolute w-6 p-2px text-gray-400 m-1">
        <SearchSolid />
      </div>

      <!-- Input -->
      <input
        ref="searchInput"
        v-model="search"
        type="search"
        :placeholder="$t('Search for anything')"
        class="
          w-full max-w-xs bg-white text-sm font-bold placeholder-gray-400 p-1 pl-8 mr-6
          outline-none border-b-2 border-white focus:border-primary-700
        "
      >
    </template>
    <template v-slot:body>
      <div class="overflow-y-scroll h-screen max-w-full" style="width: 728px; max-height: 540px;">
        <div :class="search === '' ? 'mb-32' : ''">
          <!-- No results found div -->
          <div v-if="noResults" class="flex flex-col items-center text-center mt-32">
            <img class="w-64" src="/illustration/no-result.png">
            <h2 class="text-2xl font-extrabold mt-6">{{ $t('No results found') }}</h2>
            <p class="text-gray-700 text-sm mt-2">{{ $t('It seems we can\'t find any results based on your search.') }}</p>
          </div>

          <div v-if="!hasFound && !noResults" class="flex flex-col items-center text-center mt-32">
            <img class="w-64" src="/illustration/search.png">
            <p class="text-gray-700 text-sm whitespace-pre-line mt-6">{{ $t('Search for groups, projects, documents or logs.\nType at least the first three letters of your search terms.') }}</p>
          </div>
          <!-- Group -->
          <div v-if="searchGroups.length">
            <div class="flex justify-center items-center mb-6">
              <div class="flex-grow h-px bg-gray-200 my-auto" />
              <div
                class="text-lg text-gray-300 mx-6 font-semibold"
              >
                <span>{{ $t('Group') }}</span>
              </div>
              <div class="flex-grow h-px bg-gray-200 my-auto" />
            </div>
            <div
              v-for="group in searchGroups"
              :key="`${group.id}-${group.title}`"
              class="flex items-center mb-6"
            >
              <div class="flex-shrink-0 w-8 p-1">
                <UserGroupSolid />
              </div>
              <div class="flex-grow ml-4 font-semibold" @click="$emit('modalClose')">
                <nuxt-link :to="`/workspace/${workspaceId}/group/${group.id}`">{{ group.title }}</nuxt-link>
              </div>
              <div v-if="group.is_archived" class="flex-shrink-0 w-5 h-5 text-gray-400">
                <ArchiveSolid />
              </div>
            </div>
          </div>

          <!-- Project -->
          <div v-if="searchProjects.length">
            <div class="flex justify-center items-center mb-6">
              <div class="flex-grow h-px bg-gray-200 my-auto" />
              <div
                class="text-gray-300 mx-6 font-semibold text-lg"
              >
                <span>{{ $t('Project') }}</span>
              </div>
              <div class="flex-grow h-px bg-gray-200 my-auto" />
            </div>
            <div
              v-for="project in searchProjects"
              :key="`${project.id}-${project.title}`"
              class="flex items-center mb-6"
            >
              <div class="flex-shrink-0 w-8 p-1 rounded bg-gray-30 text-center">
                <span v-if="project.emoji">{{ project.emoji }}</span>
                <span v-else><PuzzleSolid /></span>
              </div>
              <div class="flex-grow flex flex-col ml-4">
                <div class="font-semibold" @click="$emit('modalClose')">
                  <nuxt-link :to="`/workspace/${workspaceId}/project/${project.id}/logs`">{{ project.title }}</nuxt-link>
                </div>
                <div class="text-sm text-gray-700">
                  <span>{{ $t('in') }}</span> <p v-for="path in project.location" class="inline">{{ path.name }} <span v-if="project.location.length > 1">/</span></p>
                </div>
              </div>
              <div v-if="project.is_archived" class="flex-shrink-0 w-5 h-5 text-gray-400">
                <ArchiveSolid />
              </div>
            </div>
          </div>

          <!-- Document -->
          <div v-if="searchDocuments.length">
            <div class="flex justify-center items-center mb-6">
              <div class="flex-grow h-px bg-gray-200 my-auto" />
              <div
                class="text-gray-300 mx-6 font-semibold text-lg"
              >
                <span>{{ $t('Document') }}</span>
              </div>
              <div class="flex-grow h-px bg-gray-200 my-auto" />
            </div>
            <div
              v-for="doc in searchDocuments"
              :key="`${doc.id}-${doc.title}`"
              class="flex items-center mb-6"
            >
              <div class="flex-shrink-0 w-8 p-1 rounded bg-gray-30 text-center">
                <span v-if="doc.emoji">{{ doc.emoji }}</span>
                <span v-else><DocumentSolid /></span>
              </div>
              <div class="flex-grow flex flex-col ml-4">
                <div class="font-semibold" @click="$emit('modalClose')">
                  <nuxt-link :to="`/workspace/${workspaceId}/project/${doc.location[1].id}/doc/${doc.id}`">{{ doc.title }}</nuxt-link>
                </div>
                <div class="text-sm text-gray-700">
                  <span>{{ $t('in') }}</span> <p v-for="(path, pathIndex) in doc.location" class="inline">{{ path.name }} <span v-if="doc.location.length > 1 && pathIndex !== doc.location.length - 1">/ </span></p>
                </div>
              </div>
              <div v-if="doc.is_archived" class="flex-shrink-0 w-5 h-5 text-gray-400">
                <ArchiveSolid />
              </div>
            </div>
          </div>

          <div v-if="searchImpDocuments.length">
            <!-- Important docs -->
            <div>
              <div class="flex justify-center items-center mb-6">
                <div class="flex-grow h-px bg-gray-200 my-auto" />
                <div
                  class="text-gray-300 mx-6 font-semibold text-lg"
                >
                  <span>{{ $t('Important documents') }}</span>
                </div>
                <div class="flex-grow h-px bg-gray-200 my-auto" />
              </div>
              <div
                v-for="imp in searchImpDocuments"
                :key="`${imp.id}-${imp.title}`"
                class="flex items-center mb-6"
              >
                <div class="flex-shrink-0 w-8 p-1 rounded bg-gray-30 text-center">
                  <span v-if="imp.emoji === 'ExternalLinkOutline'"><ExternalLinkSolid /></span>
                  <span v-else><StarSolid /></span>
                </div>
                <div class="flex flex-col ml-4">
                  <div class="font-semibold" @click="$emit('modalClose')">
                    <a v-if="imp.url.startsWith('http')" :href="imp.url" target="_blank" rel="noopener noreferrer">{{ imp.title }}</a>
                    <nuxt-link v-else :to="`/workspace/${workspaceId}/project/${imp.location[1].id}/important-docs`">{{ imp.title }}</nuxt-link>
                  </div>
                  <div class="text-sm text-gray-700">
                    <span>{{ $t('in') }}</span> <p v-for="(path, pathIndex) in imp.location" class="inline">{{ path.name }} <span v-if="imp.location.length > 1 && pathIndex !== imp.location.length - 1">/ </span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Logs -->
          <div v-if="searchLogs.length">
            <div class="flex justify-center items-center mb-6">
              <div class="flex-grow h-px bg-gray-200 my-auto" />
              <div
                class="text-gray-300 mx-6 font-semibold text-lg"
              >
                <span>{{ $t('Logs') }}</span>
              </div>
              <div class="flex-grow h-px bg-gray-200 my-auto" />
            </div>
            <div
              v-for="log in searchLogs"
              :key="`${log.id}-${log.title}`"
              class="flex items-center mb-6"
            >
              <div class="flex-shrink-0 w-8 p-1 rounded bg-gray-30 text-center">
                <span v-if="log.emoji">{{ log.emoji }}</span>
                <span v-else><FlagSolid /></span>
              </div>
              <div class="flex-grow flex flex-col ml-4">
                <div class="font-semibold" @click="$emit('modalClose')">
                  <nuxt-link :to="`/workspace/${workspaceId}/project/${log.location[1].id}/logs`">{{ log.title }}</nuxt-link>
                </div>
                <div class="text-sm text-gray-700">
                  <span>{{ $t('in') }}</span> <p v-for="(path, pathIndex) in log.location" class="inline">{{ path.name }} <span v-if="log.location.length > 1 && pathIndex !== log.location.length - 1">/ </span></p>
                </div>
              </div>
              <div v-if="log.is_archived" class="flex-shrink-0 w-5 h-5 text-gray-400">
                <ArchiveSolid />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from 'vuex'
import { anyWordMatches } from '~/utils/word'

import ArchiveSolid from '~/components/icons/ArchiveSolid'
import BaseModal from '~/components/modals/BaseModal'
import DocumentSolid from "~/components/icons/DocumentSolid"
import ExternalLinkSolid from '~/components/icons/ExternalLinkSolid'
import FlagSolid from '~/components/icons/FlagSolid'
import PuzzleSolid from "~/components/icons/PuzzleSolid"
import SearchSolid from "~/components/icons/SearchSolid"
import StarSolid from '~/components/icons/StarSolid'
import UserGroupSolid from '~/components/icons/UserGroupSolid'

export default {
  name: 'SearchModal',

  components: {
    ArchiveSolid,
    BaseModal,
    DocumentSolid,
    ExternalLinkSolid,
    FlagSolid,
    PuzzleSolid,
    SearchSolid,
    StarSolid,
    UserGroupSolid,
  },

  props: {
    workspaceId: { type: Number, required: true, default: null },
  },

  async fetch () {
    await this.$store.dispatch('search/loadGlobalList', this.workspaceId)
  },

  data () {
    return {
      search: '',
    }
  },

  computed: {
    ...mapState('search', [
      'searchGlobalList',
    ]),

    noResults () {
      if (
        this.search.length >= 1 &&
        !this.searchGroups.length &&
        !this.searchProjects.length &&
        !this.searchDocuments.length &&
        !this.searchImpDocuments.length &&
        !this.searchLogs.length
      ) {
        return true
      }
      return false
    },

    hasFound () {
      if (
        this.search.length >= 1 &&
        (
          this.searchGroups.length ||
          this.searchProjects.length ||
          this.searchDocuments.length ||
          this.searchImpDocuments.length ||
          this.searchLogs.length
        )
      ) {
        return true
      }
      return false
    },

    searchGroups () {
      if (this.searchGlobalList && this.search.length >= 1) {
        return this.searchGlobalList.groups.filter((group) => {
          return anyWordMatches(this.search, [group.title])
        })
      }
      return []
    },

    searchProjects () {
      if (this.searchGlobalList && this.search.length >= 1) {
        return this.searchGlobalList.projects.filter((project) => {
          return anyWordMatches(this.search, [project.title])
        })
      }
      return []
    },

    searchDocuments () {
      if (this.searchGlobalList && this.search.length >= 1) {
        return this.searchGlobalList.docs.filter((doc) => {
          return anyWordMatches(this.search, [doc.title])
        })
      }
      return []
    },

    searchImpDocuments () {
      if (this.searchGlobalList && this.search.length >= 1) {
        return this.searchGlobalList.important_docs.filter((imp) => {
          return anyWordMatches(this.search, [imp.title])
        })
      }
      return []
    },

    searchLogs () {
      if (this.searchGlobalList && this.search.length >= 1) {
        return this.searchGlobalList.logs.filter((log) => {
          return anyWordMatches(this.search, [log.title, log.description])
        })
      }
      return []
    },
  },

  mounted () {
    this.$refs.searchInput.focus()
  },
}
</script>
