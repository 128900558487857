<template>
  <div class="px-2 py-4">
    <ProjectPageLink
      :route="teamRoute"
      :label="$t(TitleByPage[Page.TEAM])"
      icon="UserGroupSolid"
    />
    <ProjectPageLink
      :route="logsRoute"
      :label="$t(TitleByPage[Page.LOGS])"
      icon="FlagSolid"
    />
    <ProjectPageLink
      :route="planningRoute"
      :label="$t(TitleByPage[Page.PLANNING])"
      icon="CalendarSolid"
    />
    <ProjectPageLink
      :route="importantDocsRoute"
      :label="$t(TitleByPage[Page.IMPORTANT_DOCS])"
      icon="StarSolid"
    />

    <!-- Project team users -->
    <div v-if="!$store.state.publicMode" class="absolute z-10 top-0 right-0 px-2 py-4">
      <div class="p-1">
        <Avatars
          :users="currentUsers"
          :addRoute="addUserRoute"
          class="h-6"
          :tooltipText="$t('Add a user to the project team')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Avatars from "~/components/cards/Avatars"
import ProjectPageLink from "~/components/menus/items/ProjectPageLink"

export const Page = {
  TEAM: 'team',
  LOGS: 'logs',
  PLANNING: 'planning',
  IMPORTANT_DOCS: 'important-docs',
}

export const TitleByPage = {
  [Page.TEAM]: 'Project team',
  [Page.LOGS]: 'Logs & Activity',
  [Page.PLANNING]: 'Planning',
  [Page.IMPORTANT_DOCS]: 'Important documents',
}

export default {
  name: 'ProjectPagesMenu',

  components: {
    Avatars,
    ProjectPageLink,
  },

  data () {
    return {
      Page,
      TitleByPage,
    }
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),

    ...mapState('project', {
      projectId: 'currentId',
    }),

    ...mapGetters('project', [
      'currentUsers',
      'currentlyAbleToInvite',
    ]),

    teamRoute () {
      if (this.$store.state.publicMode) return ''
      return this.menuRouteOptions(Page.TEAM)
    },

    logsRoute () {
      if (this.$store.state.publicMode === 'doc') return ''
      return this.menuRouteOptions(Page.LOGS)
    },

    planningRoute () {
      if (this.$store.state.publicMode === 'doc') return ''
      return this.menuRouteOptions(Page.PLANNING)
    },

    importantDocsRoute () {
      if (this.$store.state.publicMode === 'doc') return ''
      return this.menuRouteOptions(Page.IMPORTANT_DOCS)
    },

    addUserRoute () {
      if (!this.currentlyAbleToInvite) return ''
      return this.menuRouteOptions(Page.TEAM, { modal: 'add-users' })
    },
  },

  methods: {
    menuRouteOptions (pageName, query = {}) {
      return {
        name: `workspace-wsid-project-projectid-${pageName}`,
        params: { wsid: this.workspaceId, projectid: this.projectId },
        query: { ...this.$route.query, ...query },
      }
    },
  },
}
</script>
