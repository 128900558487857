import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Vue from 'vue'

import { formattedPrice } from '~/utils/billing'
import { octets } from '~/utils/common'
import { relativeDate } from '~/utils/date'

dayjs.extend(localizedFormat)

Vue.use({
  install (Vue, options) {
    Vue.prototype.$relativeDate = relativeDate
    // XXX: Currently fixed to lang=fr and currency=euro
    Vue.prototype.$currency = v => formattedPrice('fr-FR', 'EUR', v)
    // XXX: This format is completly static with European's one
    Vue.prototype.$shortDate = date => dayjs(date).format('L')
    Vue.prototype.$longDate = date => dayjs(date).format('LL')
    Vue.prototype.$capitalizeFirst = (s) => {
      if (!s) return ''
      return s[0].toUpperCase() + s.substr(1).toLowerCase()
    }

    Vue.prototype.$simulatedAPIFileSizeError = function SizeError (maxSize) {
      const sizeError = new Error()
      sizeError.response = {
        data: { detail: `File size must be lower than ${octets(maxSize)}` },
      }
      return sizeError
    }
  },
})

export default ({ $config, store }, inject) => {
  function $canCreateDemoWorkspace (workspaceId) {
    const isTemplateWorkspace = workspaceId === $config.TEMPLATE_WSID
    return isTemplateWorkspace && store.getters['workspace/currentlyMember']
  }

  inject('canCreateDemoWorkspace', $canCreateDemoWorkspace)
}
