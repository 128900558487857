/**
 * Sets current doc context in the store.
 *
 * REQUIREMENTS:
 *   - Must be called after 'auth' middleware.
 *   - Must be called after 'workspace' middleware.
 *   - Must be called after 'project' middleware.
 *   - The route must contain docid.
 */
export default {
  async enter ({ store, params, error }) {
    console.info('[middleware::context.doc.enter]')
    const projectId = Number(params.projectid) // already validated by project context
    const docId = Number(params.docid)
    if (!docId) {
      return error({ statusCode: 404, message: 'Missing doc id' })
    }
    await store.dispatch('doc/setCurrentDoc', { projectId, docId })
  },

  async leave ({ store, params }) {
    if (!params.docid) {
      console.info('[middleware::context.doc.leave]')
      await store.dispatch('doc/unsetCurrentDoc')
    }
  },
}
