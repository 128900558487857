import { passwordResetError } from "~/utils/errors"

/**
* This middleware is meant for the password reset page.
*/
export default async function passwordReset ({ store, query, error }) {
  console.info('[middleware::passwordReset]')
  if (!query.token) return error({ statusCode: 404 })

  try {
    await store.$axios.get(`/users/password-reset/${query.token}`)
  } catch (e) {
    return error(passwordResetError(e))
  }
}
