<template>
  <BaseModal :title="title" @modalClose="$emit('modalClose')">
    <template v-slot:body>
      <div class="mb-4 max-w-full" style="width: 496px">
        <p class="whitespace-pre-line">{{ description }}</p>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex flex-col-reverse xs:flex-row justify-end items-stretch xs:items-center pt-4 -m-2 xs:m-0 xs:ml-auto">
        <button type="button" class="Btn Btn-wired-white m-2 xs:m-0" @click="$emit('modalClose')">
          <span>{{ $t('Cancel') }}</span>
        </button>
        <button class="Btn Btn-filled m-2 xs:m-0 xs:ml-3" @click="confirm">
          <span>{{ confirmLabel }}</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '~/components/modals/BaseModal'

export default {
  name: 'ProjectArchiveModal',

  components: {
    BaseModal,
  },

  props: {
    project: { type: Object, required: true, default: () => ({}) },
    groupId: { type: Number, required: true, default: 0 },
    workspaceId: { type: Number, required: true, default: 0 },
    onConfirm: { type: Function, required: true, default: (result, action) => {} },
  },

  computed: {
    title () {
      const text = this.project.is_archived ? 'Restore project' : 'Archive project'
      return this.$t(text)
    },

    description () {
      const text = this.project.is_archived
        ? 'Do you really want to restore this project?'
        : 'Archiving is useful to clean your workspace if you don’t think you’ll need this project any longer. It will still be available in searches and the archived section.'
      return this.$t(text)
    },

    confirmLabel () {
      const text = this.project.is_archived ? 'Restore the project' : 'Yes, archive the project'
      return this.$t(text)
    },
  },

  methods: {
    async confirm () {
      this.$emit('modalClose')
      const action = this.project.is_archived ? 'project/restoreProject' : 'project/archiveProject'
      const result = await this.$store.dispatch(action, { projectId: this.project.id, groupId: this.groupId })
      this.onConfirm(result, action)
      this.$router.push(`/workspace/${this.workspaceId}/group/${this.groupId}`)
    },
  },
}
</script>
