import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dcf5b476 = () => interopDefault(import('../pages/account/index.js' /* webpackChunkName: "pages/account/index" */))
const _1f25e440 = () => interopDefault(import('../pages/invitation.js' /* webpackChunkName: "pages/invitation" */))
const _41c7e204 = () => interopDefault(import('../pages/signup/index.js' /* webpackChunkName: "pages/signup/index" */))
const _05263e46 = () => interopDefault(import('../pages/workspace/index.js' /* webpackChunkName: "pages/workspace/index" */))
const _c0bd1cd4 = () => interopDefault(import('../pages/account/email-reset.vue' /* webpackChunkName: "pages/account/email-reset" */))
const _46324cce = () => interopDefault(import('../pages/account/password-lost.vue' /* webpackChunkName: "pages/account/password-lost" */))
const _55f1a4d6 = () => interopDefault(import('../pages/account/password-reset.vue' /* webpackChunkName: "pages/account/password-reset" */))
const _302eb1cc = () => interopDefault(import('../pages/account/signin.vue' /* webpackChunkName: "pages/account/signin" */))
const _9a2aa78a = () => interopDefault(import('../pages/signup/email.vue' /* webpackChunkName: "pages/signup/email" */))
const _47b9a4c4 = () => interopDefault(import('../pages/signup/group.vue' /* webpackChunkName: "pages/signup/group" */))
const _7c583608 = () => interopDefault(import('../pages/signup/profile.vue' /* webpackChunkName: "pages/signup/profile" */))
const _74e2858e = () => interopDefault(import('../pages/signup/team.vue' /* webpackChunkName: "pages/signup/team" */))
const _00ecd40e = () => interopDefault(import('../pages/signup/verify-email.vue' /* webpackChunkName: "pages/signup/verify-email" */))
const _ac8c6598 = () => interopDefault(import('../pages/signup/workspace.vue' /* webpackChunkName: "pages/signup/workspace" */))
const _bfb77d0e = () => interopDefault(import('../pages/workspace/_wsid/index.vue' /* webpackChunkName: "pages/workspace/_wsid/index" */))
const _62009c7b = () => interopDefault(import('../pages/workspace/_wsid/create-demo.vue' /* webpackChunkName: "pages/workspace/_wsid/create-demo" */))
const _666443c6 = () => interopDefault(import('../pages/workspace/_wsid/group/index.js' /* webpackChunkName: "pages/workspace/_wsid/group/index" */))
const _8523a7a8 = () => interopDefault(import('../pages/workspace/_wsid/project/index.js' /* webpackChunkName: "pages/workspace/_wsid/project/index" */))
const _582b7740 = () => interopDefault(import('../pages/workspace/_wsid/settings/index.js' /* webpackChunkName: "pages/workspace/_wsid/settings/index" */))
const _f3c78596 = () => interopDefault(import('../pages/workspace/_wsid/group/create.vue' /* webpackChunkName: "pages/workspace/_wsid/group/create" */))
const _424da41b = () => interopDefault(import('../pages/workspace/_wsid/project/create.vue' /* webpackChunkName: "pages/workspace/_wsid/project/create" */))
const _de99ec8a = () => interopDefault(import('../pages/workspace/_wsid/settings/billing/index.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/billing/index" */))
const _f1fd805a = () => interopDefault(import('../pages/workspace/_wsid/settings/personal.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/personal" */))
const _75e32534 = () => interopDefault(import('../pages/workspace/_wsid/settings/profile.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/profile" */))
const _69b0baf0 = () => interopDefault(import('../pages/workspace/_wsid/settings/team.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/team" */))
const _4c850c59 = () => interopDefault(import('../pages/workspace/_wsid/settings/upgrade.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/upgrade" */))
const _c480b5dc = () => interopDefault(import('../pages/workspace/_wsid/settings/workspace.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/workspace" */))
const _5321c59c = () => interopDefault(import('../pages/workspace/_wsid/settings/billing/form/customer.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/billing/form/customer" */))
const _77f01842 = () => interopDefault(import('../pages/workspace/_wsid/settings/billing/form/cycle.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/billing/form/cycle" */))
const _bf385c48 = () => interopDefault(import('../pages/workspace/_wsid/settings/billing/form/payment-method.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/billing/form/payment-method" */))
const _4d706e06 = () => interopDefault(import('../pages/workspace/_wsid/settings/billing/form/plan.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/billing/form/plan" */))
const _433f1e6a = () => interopDefault(import('../pages/workspace/_wsid/settings/billing/form/seats.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/billing/form/seats" */))
const _3b50fb40 = () => interopDefault(import('../pages/workspace/_wsid/settings/billing/funnel/customer.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/billing/funnel/customer" */))
const _a3671ee0 = () => interopDefault(import('../pages/workspace/_wsid/settings/billing/funnel/finalization.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/billing/funnel/finalization" */))
const _b703252c = () => interopDefault(import('../pages/workspace/_wsid/settings/billing/funnel/payment-method.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/billing/funnel/payment-method" */))
const _f61243ea = () => interopDefault(import('../pages/workspace/_wsid/settings/billing/funnel/plan.vue' /* webpackChunkName: "pages/workspace/_wsid/settings/billing/funnel/plan" */))
const _73a23e97 = () => interopDefault(import('../pages/workspace/_wsid/group/_groupid/index.vue' /* webpackChunkName: "pages/workspace/_wsid/group/_groupid/index" */))
const _ba7734d0 = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/index.js' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/index" */))
const _7ca27dee = () => interopDefault(import('../pages/workspace/_wsid/group/_groupid/settings.vue' /* webpackChunkName: "pages/workspace/_wsid/group/_groupid/settings" */))
const _1830ba91 = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/important-docs.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/important-docs" */))
const _4971a97d = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/logs/index.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/logs/index" */))
const _54c2cfaa = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/planning/index.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/planning/index" */))
const _5c3cf16e = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/settings.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/settings" */))
const _388adc28 = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/team.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/team" */))
const _0c77d0e1 = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/logs/create.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/logs/create" */))
const _483a589c = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/planning/fullscreen.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/planning/fullscreen" */))
const _5835dace = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/doc/_docid/index.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/doc/_docid/index" */))
const _2ffb20f2 = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/logs/_logid/index.js' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/logs/_logid/index" */))
const _ce299218 = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/doc/_docid/print.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/doc/_docid/print" */))
const _40ba7344 = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/logs/_logid/edit.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/logs/_logid/edit" */))
const _19859546 = () => interopDefault(import('../pages/workspace/_wsid/project/_projectid/logs/_logid/send-notification.vue' /* webpackChunkName: "pages/workspace/_wsid/project/_projectid/logs/_logid/send-notification" */))
const _a1596bba = () => interopDefault(import('../pages/index.js' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _dcf5b476,
    name: "account"
  }, {
    path: "/invitation",
    component: _1f25e440,
    name: "invitation"
  }, {
    path: "/signup",
    component: _41c7e204,
    name: "signup"
  }, {
    path: "/workspace",
    component: _05263e46,
    name: "workspace"
  }, {
    path: "/account/email-reset",
    component: _c0bd1cd4,
    name: "account-email-reset"
  }, {
    path: "/account/password-lost",
    component: _46324cce,
    name: "account-password-lost"
  }, {
    path: "/account/password-reset",
    component: _55f1a4d6,
    name: "account-password-reset"
  }, {
    path: "/account/signin",
    component: _302eb1cc,
    name: "account-signin"
  }, {
    path: "/signup/email",
    component: _9a2aa78a,
    name: "signup-email"
  }, {
    path: "/signup/group",
    component: _47b9a4c4,
    name: "signup-group"
  }, {
    path: "/signup/profile",
    component: _7c583608,
    name: "signup-profile"
  }, {
    path: "/signup/team",
    component: _74e2858e,
    name: "signup-team"
  }, {
    path: "/signup/verify-email",
    component: _00ecd40e,
    name: "signup-verify-email"
  }, {
    path: "/signup/workspace",
    component: _ac8c6598,
    name: "signup-workspace"
  }, {
    path: "/workspace/:wsid",
    component: _bfb77d0e,
    name: "workspace-wsid"
  }, {
    path: "/workspace/:wsid/create-demo",
    component: _62009c7b,
    name: "workspace-wsid-create-demo"
  }, {
    path: "/workspace/:wsid/group",
    component: _666443c6,
    name: "workspace-wsid-group"
  }, {
    path: "/workspace/:wsid/project",
    component: _8523a7a8,
    name: "workspace-wsid-project"
  }, {
    path: "/workspace/:wsid/settings",
    component: _582b7740,
    name: "workspace-wsid-settings"
  }, {
    path: "/workspace/:wsid/group/create",
    component: _f3c78596,
    name: "workspace-wsid-group-create"
  }, {
    path: "/workspace/:wsid/project/create",
    component: _424da41b,
    name: "workspace-wsid-project-create"
  }, {
    path: "/workspace/:wsid/settings/billing",
    component: _de99ec8a,
    name: "workspace-wsid-settings-billing"
  }, {
    path: "/workspace/:wsid/settings/personal",
    component: _f1fd805a,
    name: "workspace-wsid-settings-personal"
  }, {
    path: "/workspace/:wsid/settings/profile",
    component: _75e32534,
    name: "workspace-wsid-settings-profile"
  }, {
    path: "/workspace/:wsid/settings/team",
    component: _69b0baf0,
    name: "workspace-wsid-settings-team"
  }, {
    path: "/workspace/:wsid/settings/upgrade",
    component: _4c850c59,
    name: "workspace-wsid-settings-upgrade"
  }, {
    path: "/workspace/:wsid/settings/workspace",
    component: _c480b5dc,
    name: "workspace-wsid-settings-workspace"
  }, {
    path: "/workspace/:wsid/settings/billing/form/customer",
    component: _5321c59c,
    name: "workspace-wsid-settings-billing-form-customer"
  }, {
    path: "/workspace/:wsid/settings/billing/form/cycle",
    component: _77f01842,
    name: "workspace-wsid-settings-billing-form-cycle"
  }, {
    path: "/workspace/:wsid/settings/billing/form/payment-method",
    component: _bf385c48,
    name: "workspace-wsid-settings-billing-form-payment-method"
  }, {
    path: "/workspace/:wsid/settings/billing/form/plan",
    component: _4d706e06,
    name: "workspace-wsid-settings-billing-form-plan"
  }, {
    path: "/workspace/:wsid/settings/billing/form/seats",
    component: _433f1e6a,
    name: "workspace-wsid-settings-billing-form-seats"
  }, {
    path: "/workspace/:wsid/settings/billing/funnel/customer",
    component: _3b50fb40,
    name: "workspace-wsid-settings-billing-funnel-customer"
  }, {
    path: "/workspace/:wsid/settings/billing/funnel/finalization",
    component: _a3671ee0,
    name: "workspace-wsid-settings-billing-funnel-finalization"
  }, {
    path: "/workspace/:wsid/settings/billing/funnel/payment-method",
    component: _b703252c,
    name: "workspace-wsid-settings-billing-funnel-payment-method"
  }, {
    path: "/workspace/:wsid/settings/billing/funnel/plan",
    component: _f61243ea,
    name: "workspace-wsid-settings-billing-funnel-plan"
  }, {
    path: "/workspace/:wsid/group/:groupid",
    component: _73a23e97,
    name: "workspace-wsid-group-groupid"
  }, {
    path: "/workspace/:wsid/project/:projectid",
    component: _ba7734d0,
    name: "workspace-wsid-project-projectid"
  }, {
    path: "/workspace/:wsid/group/:groupid/settings",
    component: _7ca27dee,
    name: "workspace-wsid-group-groupid-settings"
  }, {
    path: "/workspace/:wsid/project/:projectid/important-docs",
    component: _1830ba91,
    name: "workspace-wsid-project-projectid-important-docs"
  }, {
    path: "/workspace/:wsid/project/:projectid/logs",
    component: _4971a97d,
    name: "workspace-wsid-project-projectid-logs"
  }, {
    path: "/workspace/:wsid/project/:projectid/planning",
    component: _54c2cfaa,
    name: "workspace-wsid-project-projectid-planning"
  }, {
    path: "/workspace/:wsid/project/:projectid/settings",
    component: _5c3cf16e,
    name: "workspace-wsid-project-projectid-settings"
  }, {
    path: "/workspace/:wsid/project/:projectid/team",
    component: _388adc28,
    name: "workspace-wsid-project-projectid-team"
  }, {
    path: "/workspace/:wsid/project/:projectid/logs/create",
    component: _0c77d0e1,
    name: "workspace-wsid-project-projectid-logs-create"
  }, {
    path: "/workspace/:wsid/project/:projectid/planning/fullscreen",
    component: _483a589c,
    name: "workspace-wsid-project-projectid-planning-fullscreen"
  }, {
    path: "/workspace/:wsid/project/:projectid/doc/:docid",
    component: _5835dace,
    name: "workspace-wsid-project-projectid-doc-docid"
  }, {
    path: "/workspace/:wsid/project/:projectid/logs/:logid",
    component: _2ffb20f2,
    name: "workspace-wsid-project-projectid-logs-logid"
  }, {
    path: "/workspace/:wsid/project/:projectid/doc/:docid?/print",
    component: _ce299218,
    name: "workspace-wsid-project-projectid-doc-docid-print"
  }, {
    path: "/workspace/:wsid/project/:projectid/logs/:logid/edit",
    component: _40ba7344,
    name: "workspace-wsid-project-projectid-logs-logid-edit"
  }, {
    path: "/workspace/:wsid/project/:projectid/logs/:logid/send-notification",
    component: _19859546,
    name: "workspace-wsid-project-projectid-logs-logid-send-notification"
  }, {
    path: "/",
    component: _a1596bba,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
