<template>
  <div class="relative w-full h-full flex justify-between items-center px-2 sm:px-6">
    <!-- Left: Title -->
    <slot name="default" />

    <!-- Right: links (public mode) -->
    <div v-if="$store.state.publicMode" class="flex md:-mx-2">
      <nuxt-link to="/account/signin" class="flex-shrink-0 Btn Btn-wired Btn-sm w-button mx-2">
        {{ $t('Sign in') }}
      </nuxt-link>
      <nuxt-link to="/signup/email" class="flex-shrink-0 Btn Btn-filled Btn-sm w-button mx-2">
        {{ $t('Try for free') }}
      </nuxt-link>
    </div>

    <!-- Right: Actions (user with edit rights) -->
    <div v-else-if="canEdit" class="flex-shrink-0 flex justify-end items-center">
      <!-- Inline (leading actions) -->
      <slot name="leadingContextualActions" />

      <!-- Dropdown (other actions) -->
      <div v-if="$slots.otherContextualActions" class="flex-shrink-0 relative h-6 Dropdown-onFocus mr-4">
        <!-- Dropdown button -->
        <button
          ref="dropdownBtn2"
          class="w-6 hover:bg-gray-50 rounded p-2px"
          @click="e => $refs.dropdownBtn2.focus()"
        >
          <DotsVerticalSolid />
        </button>

        <!-- Dropdown content -->
        <div class="Dropdown-content absolute z-20 right-0 bg-white text-gray-900 whitespace-no-wrap rounded shadow-dropdown py-2">
          <slot name="otherContextualActions" />
        </div>
      </div>

      <!-- Dropdown (main actions) -->
      <div v-if="showGlobalActionsBtn" class="flex-shrink-0 relative Dropdown-onFocus h-6">
        <!-- Dropdown button -->
        <button
          ref="dropdownBtn1"
          class="w-6 bg-primary hover:bg-primary-800 focus:bg-primary-900 text-white rounded p-2px"
          @click="e => $refs.dropdownBtn1.focus()"
        >
          <PlusSolid />
        </button>

        <!-- Dropdown content -->
        <div class="Dropdown-content absolute z-20 right-0 bg-white text-gray-900 whitespace-no-wrap rounded shadow-dropdown py-2">
          <!-- Contextual actions -->

          <slot name="contextualActions" />

          <!-- Separation -->
          <div v-if="$slots.contextualActions" class="w-full h-px bg-gray-100 my-2" />

          <!-- Global actions -->

          <slot name="globalActions">
            <!-- Create new group -->
            <button
              type="button"
              class="h-10 flex w-full items-center text-sm hover:bg-gray-50 px-4"
              @click="createGroup"
            >
              <span>{{ $t('Create new group') }}</span>
            </button>

            <!-- Create new project -->
            <nuxt-link
              :to="`/workspace/${$store.state.workspace.currentId}/project/create?groupid=${$store.state.group.currentId}`"
              class="h-10 flex items-center text-sm hover:bg-gray-50 px-4"
            >
              <span>{{ $t('Create new project') }}</span>
            </nuxt-link>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ConfirmModal from '~/components/modals/ConfirmModal'
import DotsVerticalSolid from "~/components/icons/DotsVerticalSolid"
import PlusSolid from "~/components/icons/PlusSolid"

export default {
  name: 'ActionTopBar',

  components: {
    DotsVerticalSolid,
    PlusSolid,
  },

  props: {
    canEdit: { type: Boolean, required: false, default: true },
    showGlobalActionsBtn: { type: Boolean, required: false, default: true },
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
      currentWorkspace: 'currentWorkspace',
    }),
  },

  methods: {
    createGroup () {
      if (!this.currentWorkspace.can_add_groups) {
        this.$showModal(ConfirmModal, {
          title: this.$t(`Can't create more groups`),
          description: this.$t(`You have reached the maximum number of groups you can add to your workspace with a free plan. You need to upgrade your subscription plan to add more groups.`),
          confirmLabel: this.$t('Upgrade'),
          onConfirm: () => {
            this.$router.push(`/workspace/${this.workspaceId}/settings/upgrade`)
            this.$closeModal()
          },
        })
      } else {
        this.$router.push(`/workspace/${this.workspaceId}/group/create`)
      }
    },
  },
}
</script>
