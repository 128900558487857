import Vue from 'vue'

import { insertDivAtEndOfBody } from '~/utils/dom'

import AppReloadModal from '~/components/modals/AppReloadModal'
import SignedOutModal from '~/components/modals/SignedOutModal'

export default function modalPlugin (nuxtContext, inject) {
  const modalVms = []

  inject('showAppReloadModal', $showAppReloadModal)
  inject('showSignedOutModal', $showSignedOutModal)
  inject('showModal', $showModal)
  inject('closeModal', $closeModal)

  function $showAppReloadModal (notifierFullname) {
    $showModal(AppReloadModal, {
      notifierFullname,
    })
  }

  function $showSignedOutModal () {
    $showModal(SignedOutModal)
  }

  function $showModal (Component, propsData = {}, eventHandlers = {}) {
    const modalId = `modal-${modalVms.length + 1}`

    // Inject element in DOM
    insertDivAtEndOfBody(modalId)

    const vm = new Vue({
      ...Component,
      router: nuxtContext.app.router,
      store: nuxtContext.store,
      $config: nuxtContext.$config,
      $showModal,
      $closeModal,
      propsData,
    })

    if (!eventHandlers.modalClose) eventHandlers.modalClose = $closeModal
    for (const name in eventHandlers) {
      vm.$on(name, eventHandlers[name])
    }
    vm.$mount(`#${modalId}`)

    // Multiple modals can be opened at the same time, they stack over each other
    modalVms.push(vm)
  }

  function $closeModal () {
    const vm = modalVms.pop()
    if (!vm) return

    vm.$el.remove()
    vm.$destroy()
  }
}
