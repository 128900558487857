import { activationError, passwordResetError } from "~/utils/errors"
import { patchObject, throwIfNot, isId, addLogOnCall, extractProperties } from "~/utils/common"
import { SecondsPerMinute } from "~/utils/date"

// =================================== STATE ===================================

export const state = () => ({
  me: undefined,
})

// ================================== GETTERS ==================================

export const getters = {
  myAvatar: state => state.me ? state.me.avatar : '',
  myEmail: state => state.me ? state.me.email : '',
  myUserId: state => state.me ? state.me.id : '',
  myFullname: state => state.me ? state.me.fullname : '',
  myFirstname: state => state.me ? state.me.fullname.split(' ')[0] : '',
  myInitial: state => state.me ? state.me.initial : '',
  pendingEmailReset: state => state.me?.pending_email_reset || false,
}

// ================================= MUTATIONS =================================

/**
 * @type {import("vuex").MutationTree<typeof state>}
 */
export const mutations = {
  SET_ME (state, userData) {
    state.me = userData || null
  },
  PATCH_ME (state, patch) {
    patchObject(state.me, patch)
  },
}

// ================================== ACTIONS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeActions = {
  _reset (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined)
    }
  },

  async getAccessToken (ctx, payload) {
    const formData = new FormData()
    formData.append('username', payload.email)
    formData.append('password', payload.password)

    const { data: authAccess } = await this.$axios.post(`/auth/signin`, formData, {
      withCredentials: true, // Allow set-cookie headers
    })

    await ctx.dispatch('setToken', authAccess)
  },

  async refreshAccessToken (ctx) {
    try {
      const { data: authAccess } = await this.$axios.post('/auth/refresh', null, {
        withCredentials: true, // Send cookies with the request
      })
      await ctx.dispatch('setToken', authAccess)
      return authAccess
    } catch (e) {
      console.error('[refresh Error] e =', e)
    }
  },

  async signout (ctx) {
    // Will set an expired (and thus unusable) refresh cookie
    await this.$axios.post(`/auth/signout`, null, {
      withCredentials: true, // Allow set-cookie headers
    })
    this.$axios.setToken(false)
    this.$localStorage.authAccess.del()
    this.$localStorage.currentUserId.del()
    // TODO: Find a way to prevent brief disappearance of data in the UI until navigation occurs
    await this.dispatch('resetAllContexts')
  },

  async sendEmailLink (ctx, payload) {
    const body = {
      email: payload.email,
    }
    await this.$axios.post(`/users/signup/email`, body)
  },

  async sendPasswordResetLink (ctx, body) {
    await this.$axios.post(`/users/password-lost`, body)
  },

  async resetPassword (ctx, body) {
    try {
      await this.$axios.post(`/users/password-reset`, body)
      if (ctx.state.me) {
        await ctx.dispatch('signout')
      }
    } catch (e) {
      return passwordResetError(e)
    }
  },

  async sendEmailResetLink (ctx, body) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.post(`/users/me/email-reset`, body, { headers })

    await ctx.dispatch('onUserPatch', {
      userId: ctx.state.me.id,
      patch: { pending_email_reset: true },
    })
  },

  async resetEmail (ctx, emailResetToken) {
    await this.$axios.post(`/users/confirm-email-reset/${emailResetToken}`)

    // Not using store-effects here because this is a specific subcase of onUserPatch where:
    // we either have no user and must do nothing,
    // or we have a user and must signout.
    // In the latter case, we should provide a payload with an email that we don't have
    if (ctx.state.me) {
      await ctx.dispatch('signout')
    }
  },

  async signup (ctx, body) {
    var authAccess
    try {
      const res = await this.$axios.post(`/users/signup/profile`, body, {
        withCredentials: true, // Allow set-cookie headers
      })
      authAccess = res.data
    } catch (e) {
      return activationError(e)
    }

    await ctx.dispatch('setToken', authAccess)
    await ctx.dispatch('loadProfile')
    await ctx.dispatch('onUserPatch', {
      userId: ctx.state.me.id,
      patch: { fullname: body.fullname },
    })
  },

  async loadProfile (ctx, { useCache = false } = {}) {
    if (useCache === true && ctx.state.me) {
      if (ctx.state.me.id === this.$localStorage.currentUserId.get()) {
        return ctx.state.me
      }
    }

    try {
      const { data: userData } = await this.$axios.get(`/users/me`)

      ctx.commit('SET_ME', userData)
      this.$localStorage.currentUserId.set(userData.id)

      return userData
    } catch (e) {
      if (!e.response || e.response.status !== 401) throw e

      await ctx.dispatch('signout')
      return null
    }
  },

  async updateProfile (ctx, body) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.put(`/users/me`, body, { headers })

    await ctx.dispatch('onUserPatch', { userId: ctx.state.me.id, patch: body })

    return result
  },

  async updatePassword (ctx, payload) {
    await this.$axios.put(`/users/me/password`, payload)
  },

  async deleteAccount (ctx, payload) {
    throwIfNot(isId, ctx.rootState.workspace.currentId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.post(`/users/me/delete`, payload, { headers })

    await ctx.dispatch('onUserDelete', { userId: ctx.state.me.id })
  },

  loadToken (ctx) {
    const { token_type, access_token, expires_at } = this.$localStorage.authAccess.get() || {}
    if (!token_type || !access_token || !expires_at) return

    // Is the token's expiry in the past (or just about to)?
    // Consider an expiry with only 1 minute remaining as past to account
    // for possible imprecision
    const aboutNow = Date.now() - SecondsPerMinute
    const expiry = new Date(expires_at).getTime()
    if (aboutNow >= expiry) return

    this.$axios.setToken(access_token, token_type)
    return access_token
  },

  setToken (ctx, { token_type, access_token, expires_at }) {
    this.$localStorage.authAccess.set({ token_type, access_token, expires_at })
    this.$axios.setToken(access_token, token_type)
  },

  async isAuthenticated (ctx) {
    if (await ctx.dispatch('loadToken')) {
      if (await ctx.dispatch('loadProfile', { useCache: true })) {
        return true
      }
    }

    // First attempt failed, try and refresh the auth access token
    await ctx.dispatch('refreshAccessToken')

    if (await ctx.dispatch('loadToken')) {
      if (await ctx.dispatch('loadProfile')) {
        return true
      }
    }

    // Second attempt failed, use is not signed in
    return false
  },
}

// ================================== EFFECTS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeEffects = {
  async onUserPatch (ctx, { userId, patch }) {
    // If not currently signed in, there cannot be anything that needs be updated, simply abort.
    if (!ctx.state.me) return

    const isCurrentUser = ctx.state.me.id === userId
    const userPatch = extractProperties(patch, ['email', 'fullname', 'avatar'])
    const emailChanged = patch.pending_email_reset === false && 'email' in patch

    if (isCurrentUser) {
      if (emailChanged) {
        // Signout for added security
        await ctx.dispatch('signout')
        return this.$router.push('/account/signin')
      }

      ctx.commit('PATCH_ME', patch)
    }

    if (userPatch) {
      const teamUserPayload = { userId, patch: { user: userPatch } }
      this.commit('workspace/PATCH_TEAM_USER', teamUserPayload)
      this.commit('group/PATCH_GROUP_USER', teamUserPayload)
      this.commit('group/PATCH_PROJECT_USER', teamUserPayload)
      this.commit('project/PATCH_TEAM_USER', teamUserPayload)

      const userPayload = { userId, patch: userPatch }
      this.commit('workspace/PATCH_LAST_PROJECTS_USER', userPayload)
      this.commit('group/PATCH_GROUP_DETAIL_USER', userPayload)
      this.commit('group/PATCH_CURRENT_PROJECTS_USER', userPayload)
      this.commit('project/PATCH_PROJECT_DETAIL_USER', userPayload)
    }

    if (this.state.log.projectLogsLoaded) {
      const creator = extractProperties(patch, ['fullname', 'avatar'])
      if (creator) this.commit('log/PATCH_PROJECT_LOG_CREATOR', { userId, creator })
    }
  },

  async onUserDelete (ctx, { userId }) {
    if (ctx.state.me.id === userId) {
      await ctx.dispatch('signout')
      return this.$router.push('/account/signin')
    }

    this.commit('workspace/REMOVE_TEAM_USER', userId)
    this.commit('group/REMOVE_GROUP_USER', userId)
    this.commit('group/REMOVE_PROJECT_USER', { userId })
    this.commit('project/REMOVE_TEAM_USER', userId)

    this.commit('workspace/REMOVE_LAST_PROJECTS_USER', { userId })
    this.commit('group/REMOVE_GROUP_DETAIL_USER', { userId })
    this.commit('group/REMOVE_CURRENT_PROJECTS_USER', { userId })
    this.commit('project/REMOVE_PROJECT_DETAIL_USER', { userId })

    if (this.state.log.projectLogsLoaded) {
      this.commit('log/PATCH_PROJECT_LOG_CREATOR', { userId, creator: null })
    }
  },
}

addLogOnCall(storeActions)
addLogOnCall(storeEffects)

export const actions = {
  ...storeActions,
  ...storeEffects,
}
