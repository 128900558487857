<template>
  <div
    data-form-error
    class="h-0 opacity-0 text-red text-sm ease-in-out"
    style="transition-property: opacity, height"
    :style="`transition-duration: ${duration}ms`"
  >
    <div class="pt-1 pl-1">
      <!-- Invalid params (normal field) -->
      <template v-for="param in invalidParams">
        <div>{{ getMessage(param) }}</div>
      </template>

      <!-- Invalid params (repeatable field) -->
      <template v-for="{ index, param } in repeatableInvalidParams">
        <div>{{ index }}. {{ getMessage(param) }}</div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputError',

  props: {
    v: { type: Object, required: true, default: () => ({}) },
    vMessages: { type: Object, required: false, default: () => ({}) },
  },

  messagesByType: {
    // NOTE: This should only contain common messages that are generic enough.
    // On the contrary, params like "minLength" or "maxLength" depend a lot on the context
    // because they could be a string length or a number of item in a list and so on...
    // Use the vMessages prop to define those other cases or to override default messages.
    required: "This field is required",
    url: "Invalid url",
    unique: "This value is already used",
    email: "Invalid email address",
    domain: "Invalid email domain",
    password: "This password is not strong enough",
    noDuplicates: "This field must contain no duplicate values",
    notCurrentEmail: "Cannot be your current email",
  },

  data () {
    return {
      duration: 200,
      invalidParams: [],
      repeatableInvalidParams: [],
    }
  },

  watch: {
    'v.$error' (newValue) {
      if (newValue) {
        this.updateInvalidParams()
        setTimeout(() => {
          this.$el.style.opacity = 1
          this.$el.style.height = `${this.$el.scrollHeight}px`
        }, 0)
      } else {
        this.$el.style.opacity = 0
        this.$el.style.height = 0
        setTimeout(() => {
          this.updateInvalidParams()
        }, this.duration)
      }
    },
  },

  methods: {
    getMessage (param) {
      // if a param is specified, take it, even if it is falsy
      if (param in this.vMessages) return this.$t(this.vMessages[param])
      return this.$t(this.$options.messagesByType[param] || param)
    },

    updateInvalidParams () {
      this.invalidParams = getInvalidParams(this.v)
      const repeatableParams = []
      if (this.v.$each) {
        // NOTE: $iter is not an array but an object with index like properties
        const iter = this.v.$each.$iter
        // Build a flat list of invalid params with initial index (+1 to start at 1)
        for (const indexLike in iter) {
          for (const param of getInvalidParams(iter[indexLike])) {
            repeatableParams.push({ index: Number(indexLike) + 1, param })
          }
        }
      }
      this.repeatableInvalidParams = repeatableParams
    },
  },
}

function getInvalidParams (validator) {
  const result = []
  for (const errorType in validator.$params) {
    if (validator[errorType] === false) result.push(errorType)
  }
  return result
}
</script>
