import { isId, throwIfNot } from "~/utils/common"
import { MenuGroupFilter } from "~/utils/constants"

/**
 * Sets current project context in the store.
 *
 * REQUIREMENTS:
 *   - Must be called after 'auth' middleware.
 *   - Must be called after 'workspace' middleware.
 *   - The route must contain projectid.
 */
export default {
  async enter ({ store, params, error }) {
    console.info('[middleware::context.project.enter]')
    const projectId = Number(params.projectid)
    if (!projectId) {
      return error({ statusCode: 404, message: 'Missing project id' })
    }
    await store.dispatch('project/setCurrentProject', projectId)

    if (store.state.publicToken) return

    const projectGroupId = store.getters['project/currentGroupId']
    throwIfNot(isId, projectGroupId)

    const isInMenuGroups = () => store.state.group.menuGroups.some(g => g.id === projectGroupId)

    // Ensure groupDetails are loaded
    var group = store.state.group.groupDetails[projectGroupId]
    if (!group) {
      await store.dispatch('group/loadGroupDetails', [projectGroupId])
      group = store.state.group.groupDetails[projectGroupId]
    }

    if (!isInMenuGroups()) {
      const filter = store.state.group.menuGroupsFilter

      if (group.is_archived && filter !== MenuGroupFilter.ARCHIVED_GROUPS.value) {
        // group is archived and menuGroups do not show archived groups
        await store.dispatch('group/updateMenuGroupsFilter', MenuGroupFilter.ARCHIVED_GROUPS.value)
      } else if (!group.is_archived && filter !== MenuGroupFilter.ALL_GROUPS.value) {
        // group is active and menuGroups do not show active groups
        await store.dispatch('group/updateMenuGroupsFilter', MenuGroupFilter.ALL_GROUPS.value)
      } else {
        // Unexpected case: group should be visible but is missing (not accessible ?)
        throw new Error('Unexpected missing group in menuGroups')
      }

      // Now that menuGroups is reloaded
      if (!isInMenuGroups()) {
        throw new Error('Unexpected missing group in menuGroups')
      }
    }

    const isOpenedInMenu = store.state.group.openedInMenu[projectGroupId]
    if (!isOpenedInMenu) {
      await store.dispatch('group/toggleOpen', projectGroupId)
    }
  },

  async leave ({ store, params }) {
    if (!params.projectid) {
      console.info('[middleware::context.project.leave]')
      await store.dispatch('project/unsetCurrentProject')
    }
  },
}
