import { tabbable } from '~/utils/tabbable'

export function isStrictlyInside (mouseEvent, element) {
  const { clientX: x, clientY: y } = mouseEvent
  const { top, right, bottom, left } = element.getBoundingClientRect()
  return (x > left && x < right && y > top && y < bottom)
}

export function insertDivAtStartOfBody (id) {
  const el = document.createElement('div')
  el.setAttribute('id', id)
  document.body.prepend(el)
  return el
}

export function insertDivAtEndOfBody (id) {
  const el = document.createElement('div')
  el.setAttribute('id', id)
  document.body.appendChild(el)
  return el
}

export function createFocusTrap (containerEl) {
  /**
   * @param {KeyboardEvent} keyboardEvent
   */
  function trapFocus (keyboardEvent) {
    if (keyboardEvent.key !== 'Tab') return
    const nodes = tabbable(containerEl)
    var trapIndex = closestFocusTarget(keyboardEvent, containerEl, nodes)
    if (trapIndex !== null) {
      keyboardEvent.preventDefault()
      nodes[trapIndex].focus()
    }
  }
  return trapFocus
}

export function closestFocusTarget (keyboardEvent, containerEl, tabbableNodes) {
  const end = tabbableNodes.length - 1
  const index = tabbableNodes.indexOf(keyboardEvent.target)
  const forward = keyboardEvent.shiftKey === false

  if (index === end && forward) return 0 // from end to start
  if (index === 0 && !forward) return end // from start to end
  if (index !== -1) return null // valid position inside, let default focus happen

  // No currently active element:
  // guess where the browser will try to focus based on current selection
  const pointer = document.getSelection().focusNode
  const nodesOfModal = Array.from(containerEl.querySelectorAll('*'))
  const indexOfSelection = nodesOfModal.indexOf(pointer)
  if (indexOfSelection === -1) return 0

  // prepare next nodes from closest node to furthest (in the correct direction)
  const nodesToCheck = forward
    ? nodesOfModal.slice(indexOfSelection)
    : nodesOfModal.slice(0, indexOfSelection).reverse()

  for (const n of nodesToCheck) {
    const i = tabbableNodes.indexOf(n)
    if (i !== -1) return i // found tabbable node before reaching end of container
  }
  return forward ? 0 : end
}

export function scrollToElement (selector) {
  const el = document.querySelector(selector)
  if (el) el.scrollIntoView({ behavior: 'smooth', block: 'center' })
  else window.scrollTo({ behavior: 'smooth', top: 0 })
}
