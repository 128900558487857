var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tooltip",staticClass:"fixed z-50 pointer-events-none transition-opacity ease-in-out",class:[
    !_vm.active ? 'opacity-0' : 'opacity-100',
    _vm.forceWrap ? 'text-center' : 'whitespace-no-wrap' ],style:({
    'transition-duration': (_vm.duration + "ms"),
    'transition-delay': (_vm.transitionDelay + "ms"),
    'left': (_vm.left + "px"),
    'top': (_vm.top + "px"),
    'width': _vm.width ? (_vm.width + "px") : 'initial',
  }),attrs:{"id":_vm.tooltipId}},[_c('div',{staticClass:"fixed w-0 h-0 text-gray transform -translate-x-1/2",class:_vm.tooltipIsAbove
      ? 'translate-y-full rotate-180'
      : '-translate-y-full rotate-0',staticStyle:{"border-color":"transparent","border-bottom-color":"currentColor"},style:({
      'border-width': (_vm.arrowWidth + "px"),
      'left': _vm.arrowLeft ? (_vm.arrowLeft + "px") : '50%',
    })}),_vm._v(" "),_c('div',{staticClass:"bg-gray text-white rounded shadow-dropdown",staticStyle:{"padding":"1px 8px"}},[_c('div',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.text || '{ Missing tooltip text }'))])])])}
var staticRenderFns = []

export { render, staticRenderFns }