<template>
  <div>
    <!-- Error hint -->
    <ErrorCard :error="formMixin_error" class="mb-6" />

    <!-- Form -->
    <form novalidate @submit.prevent="submit">
      <EmailInput
        v-model="email"
        :v="$v.email"
        autofocus
        class="mb-4"
      />
      <PasswordInput
        v-model="password"
        :v="$v.password"
        class="mb-4"
      />

      <!-- Action: submit -->
      <SmartButton
        type="submit"
        :text="$t('Continue')"
        :loading="$v.$loading || formMixin_busy"
        class="Btn Btn-filled w-full mb-4"
      />

      <!-- Action: other -->
      <div class="text-center">
        <nuxt-link to="/account/password-lost" class="text-primary">{{ $t('Forgot password?') }}</nuxt-link>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

import { formMixin } from '~/utils/form'

import ErrorCard from '~/components/cards/ErrorCard'
import SmartButton from '~/components/actions/SmartButton'
import EmailInput from '~/components/inputs/EmailInput'
import PasswordInput from '~/components/inputs/PasswordInput'

export default {
  name: 'SigninForm',

  components: {
    EmailInput,
    ErrorCard,
    PasswordInput,
    SmartButton,
  },

  mixins: [formMixin],

  data () {
    return {
      email: '',
      password: '',
    }
  },

  validations: {
    email: { required, email },
    password: { required },
  },

  methods: {
    submit () {
      this.formMixin_handleSubmit(async () => {
        const throwAuthError = () => {
          const error = new Error("Authentication error")
          error.response = { data: { detail: "Incorrect email or password" } }
          throw error
        }

        try {
          await this.$store.dispatch('user/getAccessToken', {
            email: this.email,
            password: this.password,
          })
        } catch (e) {
          throwAuthError()
        }

        const user = await this.$store.dispatch('user/loadProfile')
        if (!user) {
          // Logicly improbable but technichly possible. Be safe
          throwAuthError()
        }

        this.$emit('signin')
      })
    },
  },
}
</script>
