/**
 * This middleware can be used in pages where billing details are needed.
 * This is reserved to owners.
 *
 * REQUIREMENTS:
 *   - Must be called after 'auth' middleware.
 *   - Must be called after 'workspace' middleware.
 */
export default async function billing ({ store, params, redirect, error }) {
  console.info('[middleware::billing]')

  if (!store.getters['workspace/currentlyOwner']) {
    return error({ statusCode: 403 })
  }

  await store.dispatch('billing/loadBillingInfo', { useCache: true })
}
