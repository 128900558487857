import { getStripePrice } from "~/utils/billing"
import { addLogOnCall } from "~/utils/common"

// =================================== STATE ===================================

export const state = () => ({
  billingInfoLoaded: false,
  prices: {},
  customer: undefined,
  paymentMethod: undefined,
  cycleEndDate: undefined,
  trialEndDate: undefined,
  taxRate: undefined,
  pendingChanges: {},
  invoicesLoaded: false,
  invoices: [],
})

// ================================== GETTERS ==================================

/**
 * @type {import("vuex").GetterTree<typeof state>}
 */
export const getters = {
  currentBilling: (state, get, rootState) => rootState.workspace.currentWorkspace?.billing,
  expiryError: (state, get) => get.currentBilling?.expiry_error,
  overseatError: (state, get) => get.currentBilling?.overseat_error,
  paymentError: (state, get) => get.currentBilling?.payment_error,
  subscriptionId: (state, get) => get.currentBilling?.stripe_sub_id,
  activeSubscription: (state, get) => get.currentBilling?.is_active || false,
  pendingSubscription: (state, get) => (get.subscriptionId && !get.activeSubscription) || false,
  canceledSubscription: (state, get) => get.currentBilling?.is_canceled || false,
  trialAvailable: (state, get) => get.currentBilling?.is_trial_available || false,
  currentPlan: (state, get) => get.currentBilling?.plan,
  currentCycle: (state, get) => get.currentBilling?.cycle,
  currentSeats: (state, get) => get.currentBilling?.nb_seats,
  inPremiumPlan: (state, get) => get.currentPlan === 'PREMIUM',
  inProPlan: (state, get) => get.currentPlan === 'PRO',
  inMonthlyCycle: (state, get) => get.currentCycle === 'monthly',
  inYearlyCycle: (state, get) => get.currentCycle === 'yearly',
  currentMonthlyPrice: (state, get) => getStripePrice(get.currentPlan, 'monthly', state.prices),
  currentYearlyPrice: (state, get) => getStripePrice(get.currentPlan, 'yearly', state.prices),
  currentYearlyMonthPrice: (state, get) => get.currentYearlyPrice / 12,
}

// ================================= MUTATIONS =================================

/**
 * @type {import("vuex").MutationTree<typeof state>}
 */
export const mutations = {
  SET_BILLING_INFO (state, payload) {
    state.billingInfoLoaded = Boolean(payload)
    if (!payload) payload = {}

    state.prices = payload.prices || {}
    state.taxRate = payload.tax_rate || null
    state.customer = payload.customer || null
    state.paymentMethod = payload.payment_method || null
    state.cycleEndDate = payload.cycle_end_date || null
    state.trialEndDate = payload.trial_end_date || null
    state.pendingChanges = payload.pending_changes || {}
  },
  PATCH_BILLING_INFO (state, patch) {
    if ('prices' in patch) state.prices = patch.prices
    if ('tax_rate' in patch) state.taxRate = patch.tax_rate
    if ('customer' in patch) state.customer = patch.customer
    if ('payment_method' in patch) state.paymentMethod = patch.payment_method
    if ('cycle_end_date' in patch) state.cycleEndDate = patch.cycle_end_date
    if ('trial_end_date' in patch) state.trialEndDate = patch.trial_end_date
    if ('pending_changes' in patch) state.pendingChanges = patch.pending_changes
  },
  SET_INVOICES (state, payload) {
    state.invoicesLoaded = Boolean(payload)
    state.invoices = payload || []
  },
}

// ================================== ACTIONS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeActions = {
  _reset (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined)
    }
  },

  async loadBillingInfo (ctx, { useCache = false } = {}) {
    if (useCache === true && ctx.state.billingInfoLoaded) return

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const { data } = await this.$axios.get(`/billing`, { headers })

    ctx.commit('SET_BILLING_INFO', data)
  },

  async getInvoices (ctx, { limit, year } = {}) {
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
    }
    const params = { limit, year }

    const { data } = await this.$axios.get(`/billing/invoices`, { headers, params })

    return data
  },

  async loadInvoices (ctx, { useCache = false } = {}) {
    if (useCache === true && ctx.state.invoicesLoaded) return

    const data = await ctx.dispatch('getInvoices', { limit: 3 })

    ctx.commit('SET_INVOICES', data)
  },

  async getSubscription (ctx) {
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
    }

    const { data } = await this.$axios.get(`/billing/subscription`, { headers })

    return data
  },

  async createTrialSubscription (ctx, payload) {
    const workspaceId = this.state.workspace.currentId
    const headers = {
      'x-current-workspace-id': workspaceId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.post(`/billing/trial`, payload, { headers })

    // NOTE: The following is only suitable after this specific action because
    // only the owner can create a subscription.
    // There is no need to reload the full app because there cannot be any
    // access changes for the owner. Additionally, the owner is guaranted to
    // have access to everything.
    await this.dispatch('workspace/loadCurrentWorkspace')
    // Update personal workspace after current workspace has been updated
    const pwPatch = { workspace: { billing: ctx.getters.currentBilling } }
    this.commit('workspace/PATCH_PERSONAL_WORKSPACE', { workspaceId, patch: pwPatch })
    await this.dispatch('billling/loadBillingInfo')
    await this.dispatch('billling/loadInvoices')
  },

  async createSubscription (ctx, payload) {
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
    }

    await this.$axios.post(`/billing/subscription`, payload, { headers })
  },

  async updateSubscriptionPlan (ctx, newPlan) {
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
    }
    const body = { plan: newPlan }

    await this.$axios.put(`/billing/subscription/plan`, body, { headers })
  },

  async updateSubscriptionCycle (ctx) {
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
    }

    await this.$axios.put(`/billing/subscription/cycle`, {}, { headers })
  },

  async updateSubscriptionSeats (ctx, nbSeats) {
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
    }
    const body = { nb_seats: nbSeats }

    await this.$axios.put(`/billing/subscription/seats`, body, { headers })
  },

  async cancelSubscription (ctx) {
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
    }

    await this.$axios.post(`/billing/subscription/cancel`, {}, { headers })
  },

  async abortPendingSubscription (ctx) {
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.post(`/billing/subscription/abort`, {}, { headers })
  },

  async createCustomer (ctx, payload) {
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const { data } = await this.$axios.post(`/billing/customer`, payload, { headers })

    await this.dispatch('billing/onBillingInfoPatch', { customer: data })
  },

  async updateCustomer (ctx, payload) {
    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }

    const { data } = await this.$axios.put(`/billing/customer`, payload, { headers })

    await this.dispatch('billing/onBillingInfoPatch', { customer: data })
  },

  async updatePaymentMethod (ctx, cardNumber) {
    const response = await this.$stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: state.customer,
    })
    if (response.error) return Promise.reject(response.error)

    const headers = {
      'x-current-workspace-id': ctx.rootState.workspace.currentId,
      'x-client-id': this.$sse.id,
    }
    const payload = { payment_method_id: response.paymentMethod.id }

    const { data } = await this.$axios.put(`/billing/payment-method`, payload, { headers })

    await this.dispatch('billing/onBillingInfoPatch', { payment_method: data })
  },
}

// ================================== EFFECTS ==================================

const storeEffects = {
  onBillingPlanReload (ctx, notifierFullname) {
    this.$showAppReloadModal(notifierFullname)
  },

  onBillingWorkspacePatch (ctx, workspacePatch) {
    this.commit('workspace/PATCH_CURRENT_WORKSPACE', workspacePatch)
  },

  onBillingPatch (ctx, billingPatch) {
    const workspacePatch = { billing: billingPatch }

    this.commit('workspace/PATCH_CURRENT_WORKSPACE', workspacePatch)

    const workspaceId = this.state.workspace.currentId
    const pwPatch = { workspace: workspacePatch }

    this.commit('workspace/PATCH_PERSONAL_WORKSPACE', { workspaceId, patch: pwPatch })
  },

  onBillingInfoPatch (ctx, patch) {
    if (ctx.state.billingInfoLoaded) {
      ctx.commit('PATCH_BILLING_INFO', patch)
    }
  },

  onInvoiceNew (ctx) {
    if (ctx.state.invoicesLoaded) {
      ctx.dispatch('loadInvoices')
    }
  },
}

addLogOnCall(storeActions)
addLogOnCall(storeEffects)

export const actions = {
  ...storeActions,
  ...storeEffects,
}
