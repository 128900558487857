<template>
  <div>
    <!-- Label -->
    <label v-if="label" class="block font-bold mb-2 mr-2">{{ label }}</label>

    <!-- Input -->
    <input
      ref="input"
      v-model="inputValue"
      type="text"
      :placeholder="placeholder || label"
      :autofocus="autofocus"
      v-bind="$attrs"
      class="InputBox w-full"
      :class="{'invalid': v.$error}"
      v-on="listenersExceptInput"
    >

    <!-- Input errors -->
    <InputError :v="v" :vMessages="vMessages" />
  </div>
</template>

<script>
import InputError from '~/components/forms/InputError'

export default {
  name: 'TextInput',

  components: {
    InputError,
  },

  props: {
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    value: { type: String, required: false, default: '' },
    v: { type: Object, required: false, default: () => ({}) },
    vMessages: { type: Object, required: false, default: () => ({}) },
    autofocus: { type: Boolean, required: false, default: false },
  },

  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    listenersExceptInput () {
      const { input: _, ...otherListeners } = this.$listeners
      return otherListeners
    },
  },

  mounted () {
    if (this.autofocus) this.$refs.input.focus()
  },
}
</script>
