<template>
  <div class="Dropdown-content z-30 bg-white text-gray-900 whitespace-no-wrap rounded shadow-dropdown py-2 w-screen sm:w-auto">
    <!-- User info -->
    <header class="flex px-4 py-2 mb-2">
      <!-- Avatar -->
      <div class="flex-shrink-0 w-8 h-8">
        <Avatar :url="myAvatar" :label="myInitial" :colorSource="myFullname" />
      </div>

      <!-- Titles -->
      <div class="text-sm ml-4" style="max-width: 200px">
        <div class="font-bold truncate">{{ myFullname }}</div>
        <div class="text-gray-400 leading-tight truncate">{{ currentJobTitle || $t('No title') }}</div>
      </div>
    </header>

    <!-- Profile action link -->
    <nuxt-link
      :to="`/workspace/${workspaceId}/settings/profile`"
      class="flex items-center px-4 py-2 hover:bg-gray-50"
    >
      <div class="w-6 p-2px">
        <CogSolid />
      </div>
      <div class="text-sm ml-2">{{ $t('Profile & security') }}</div>
    </nuxt-link>

    <!-- Help action link -->
    <a
      href="https://flightlog.crisp.help/fr/"
      target="_blank"
      rel="noopener noreferrer"
      class="flex items-center px-4 py-2 hover:bg-gray-50"
    >
      <div class="w-6 p-2px">
        <InformationCircleSolid />
      </div>
      <div class="text-sm ml-2">{{ $t('Help') }}</div>
    </a>

    <!-- Separator -->
    <div class="h-px bg-gray-100 mt-2" />

    <!-- Personal Workspaces -->
    <div class="overflow-y-auto" style="max-height: 40vh">
      <template v-for="(pw, index) in personalWorkspaces">
        <!-- Separator -->
        <div v-if="index > 0" :key="`sep-${pw.workspace.id}`" class="h-px bg-gray-100" />

        <!-- Workspace Action link -->
        <nuxt-link
          :key="pw.workspace.id"
          :to="`/workspace/${pw.workspace.id}`"
          class="flex items-center hover:bg-gray-50 px-4 py-2 my-2"
        >
          <!-- Avatar -->
          <div class="flex-shrink-0 w-8 h-8">
            <Avatar
              :url="pw.workspace.logo"
              :label="pw.workspace.name[0]"
              :colorSource="pw.workspace.name"
            />
          </div>

          <!-- Titles -->
          <div class="text-sm ml-4">
            <div class="font-bold truncate" style="max-width: 200px">{{ pw.workspace.name }}</div>
            <div class="text-gray-400 leading-tight">
              <span>{{ pw.workspace.billing.plan }} {{ $t('Plan') }}</span>
              <span>&mdash;</span>
              <span>{{ $t(`${pw.workspace.billing.nb_seats} seats`) }}</span>
            </div>
          </div>

          <!-- Is current? -->
          <div v-if="workspaceId === pw.workspace.id" class="flex-shrink-0 w-6 ml-4">
            <CheckSolid />
          </div>
        </nuxt-link>
      </template>
    </div>

    <!-- Separator -->
    <div class="h-px bg-gray-100 mb-2" />

    <div class="flex flex-col">
      <!-- Create workspace action link -->
      <nuxt-link
        to="/signup/workspace"
        class="w-full px-4 py-2 hover:bg-gray-50"
      >
        <div class="flex items-center">
          <div class="w-4 bg-gray-900 text-white rounded-full m-1">
            <PlusSolid />
          </div>
          <div class="text-sm ml-2">{{ $t('Create a new workspace') }}</div>
        </div>
      </nuxt-link>

      <!-- Create demo workspace action link -->
      <nuxt-link
        v-if="canCreateDemoWorkspace"
        :to="`/workspace/${workspaceId}/create-demo`"
        class="w-full px-4 py-2 hover:bg-gray-50"
      >
        <div class="flex items-center">
          <div class="w-6 rounded-full p-1">
            <SunSolid />
          </div>
          <div class="text-sm ml-2">{{ $t('Create a demo workspace') }}</div>
        </div>
      </nuxt-link>

      <!-- Report action link -->
      <button class="w-full px-4 py-2 hover:bg-gray-50" @click="reportCrisp">
        <div class="flex items-center">
          <div class="w-6 p-2px">
            <ExclamationCircleSolid />
          </div>
          <div class="text-sm ml-2">{{ $t('Report Abuse') }}</div>
        </div>
      </button>

      <!-- Signout action link -->
      <button class="w-full px-4 py-2 hover:bg-gray-50" @click="signout">
        <div class="flex items-center">
          <div class="w-6 p-2px transform rotate-90">
            <DownloadSolid />
          </div>
          <div class="text-sm ml-2">{{ $t('Sign out') }}</div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"

import Avatar from '~/components/cards/Avatar'
import CheckSolid from '~/components/icons/CheckSolid'
import CogSolid from "~/components/icons/CogSolid"
import DownloadSolid from "~/components/icons/DownloadSolid"
import ExclamationCircleSolid from '~/components/icons/ExclamationCircleSolid'
import InformationCircleSolid from '~/components/icons/InformationCircleSolid'
import PlusSolid from "~/components/icons/PlusSolid"
import SunSolid from "~/components/icons/SunSolid"

export default {
  name: 'MainMenuDropdown',

  components: {
    Avatar,
    CheckSolid,
    CogSolid,
    DownloadSolid,
    ExclamationCircleSolid,
    InformationCircleSolid,
    PlusSolid,
    SunSolid,
  },

  computed: {
    ...mapGetters('user', [
      'myAvatar',
      'myFullname',
      'myInitial',
    ]),

    ...mapState('workspace', {
      workspaceId: 'currentId',
      personalWorkspaces: 'personalWorkspaces',
    }),

    ...mapGetters('workspace', [
      'currentJobTitle',
    ]),

    canCreateDemoWorkspace () {
      return this.$canCreateDemoWorkspace(this.workspaceId)
    },
  },

  methods: {
    async reportCrisp () {
      if (process.client) {
        await window.$crisp.push(["do", "chat:open"])
        await window.$crisp.push(["do", "message:send", ["text", "Bonjour, je souhaite signaler un abus"]])
      }
    },
    async signout () {
      await this.$store.dispatch('user/signout')
      this.$router.push('/account/signin')
    },
  },
}
</script>
