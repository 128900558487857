var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex -ml-px"},[_vm._l((_vm.avatars),function(user){return _c('div',{staticClass:"flex-shrink-0 w-6 h-6 ml-px"},[_c('Avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(user.fullname),expression:"user.fullname"}],key:user.id,attrs:{"url":user.avatar,"label":user.initial,"colorSource":user.fullname}})],1)}),_vm._v(" "),(_vm.nbOtherUsers)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.otherUserNames),expression:"otherUserNames"}],staticClass:"flex-shrink-0 w-6 h-6 rounded-full flex justify-center items-center border text-xs",class:[
      _vm.color === 'primary'
        ? 'bg-primary-900 border-primary-800 text-primary-300'
        : 'bg-gray-30 border-gray-300 text-gray-900',
      _vm.addRoute ? 'ml-px' : 'ml-1'
    ]},[_vm._v("\n    "+_vm._s(_vm.nbOtherUsers)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.slow.compact",value:(_vm.tooltipText),expression:"tooltipText",modifiers:{"slow":true,"compact":true}}],staticClass:"flex-shrink-0 ml-1"},[(_vm.addRoute)?_c('nuxt-link',{staticClass:"block w-6 h-6 p-2px rounded-full",class:_vm.color === 'primary'
        ? 'bg-primary-800 hover:bg-primary-700'
        : 'bg-gray-900 hover:bg-gray-800 text-white',attrs:{"to":_vm.addRoute}},[_c('PlusSolid')],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }