<template>
  <!-- Border container -->
  <div class="w-full h-full bg-gray-300 rounded-full p-px">
    <!-- Background container -->
    <div class="w-full h-full rounded-full overflow-hidden" :style="avatarStyle">
      <!-- Photo -->
      <img
        v-if="url && !failedLoadingUrl"
        :src="$mediaUrl(url)"
        :alt="label"
        class="w-full h-full object-cover bg-primary-100"
        @error="failedLoadingUrl = true"
      >
      <!-- Initial -->
      <div v-else class="w-full h-full flex justify-center items-center">
        <span class="uppercase" :class="fontSizeClass">{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export const COLORS = [
  { 'background-color': '#c154c1', color: 'white' }, // Fuschia
  { 'background-color': '#9457eb', color: 'white' }, // Neon purple
  { 'background-color': '#667eea', color: 'white' }, // ~blue violet
  { 'background-color': '#318ce7', color: 'white' }, // Bleu de France
  { 'background-color': '#00bfff', color: 'white' }, // Deep sky blue
  { 'background-color': '#00c5cd', color: 'white' }, // Turquoise surf
  { 'background-color': '#009b7d', color: 'white' }, // Paolo Veronese green
  { 'background-color': '#63bf68', color: 'white' }, // green
  { 'background-color': '#9acd32', color: 'white' }, // Yellow green
  { 'background-color': '#f2ba49', color: 'white' }, // Maximum yellow red
  { 'background-color': '#ed872d', color: 'white' }, // Cadmium orange
  { 'background-color': '#f88379', color: 'white' }, // Tea rose
  { 'background-color': '#fc5a8d', color: 'white' }, // Strawberry iced tea
  { 'background-color': '#d71868', color: 'white' }, // Dogwood rose
]

export default {
  name: 'Avatar',

  props: {
    url: { type: String, required: false, default: '' },
    label: { type: String, required: true, default: '' },
    colorSource: { type: String, required: true, default: '' },
  },

  data () {
    return {
      failedLoadingUrl: false,
      fontSizeClass: 'text-xs',
    }
  },

  computed: {
    avatarStyle () {
      const src = this.colorSource || this.label
      var result = 0
      for (const c of src) result += c.charCodeAt(0)
      return COLORS[result % COLORS.length]
    },
  },

  mounted () {
    const size = this.$el.getBoundingClientRect().width
    this.fontSizeClass = getBestFontSize(size)
  },
}

function getBestFontSize (size) {
  if (size > 80) return 'text-5xl'
  if (size > 72) return 'text-4xl'
  if (size > 64) return 'text-3xl'
  if (size > 56) return 'text-2xl'
  if (size > 48) return 'text-xl'
  if (size > 40) return 'text-lg'
  if (size > 32) return 'text-md'
  return 'text-xs'
}
</script>
