<template>
  <!-- Wrapper -->
  <div class="relative">
    <!-- Top bar -->
    <DefaultTopBar
      :signinButton="signinButton"
      :signupButton="signupButton"
      class="fixed z-10 top-0"
    />

    <!-- Background -->
    <div class="absolute w-screen h-screen flex">
      <!-- Left part -->
      <div class="w-full lg:w-1/2 bg-white" />
      <!-- Right part -->
      <div class="hidden lg:block w-1/2 bg-gray-30" />
    </div>

    <!-- Page content -->
    <div class="relative w-screen h-screen pt-topbar overflow-y-auto px-6">
      <div class="w-full h-full max-w-screen-xl flex mx-auto">
        <!-- Left: Main content -->
        <div class="w-full lg:w-1/2 h-full">
          <div class="w-full sm:max-w-md h-full mx-auto">
            <nuxt />
          </div>
        </div>

        <!-- Right: Illustration -->
        <div class="hidden lg:flex w-1/2 h-full items-center">
          <img :src="illustration" alt="Illustration">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultTopBar from '~/components/bars/DefaultTopBar'

export default {
  name: 'OnboardingLayout',

  components: {
    DefaultTopBar,
  },

  data () {
    return {
      signinButton: undefined,
      signupButton: undefined,
      illustration: undefined,
    }
  },

  provide () {
    return {
      layoutContext: this,
    }
  },
}
</script>
