<template>
  <div>
    <div class="flex items-center">
      <!-- Label left -->
      <slot name="before" />

      <!-- Input -->
      <label
        class="relative block rounded-full outline-none transition-colors duration-150 ease-in-out cursor-pointer focus:shadow-border-primary-400"
        :class="[
          v.$error ? 'bg-red-800 shadow-border-red-100'
          : value ? 'bg-primary shadow-border-primary-200'
            : 'bg-gray-200',
          disabled ? 'opacity-50 pointer-events-none' : ''
        ]"
        tabindex="0"
        @keydown.enter="$refs.inputEl.click()"
        @keydown.space="$event.preventDefault(); $refs.inputEl.click()"
      >
        <!-- Hidden native input -->
        <input
          ref="inputEl"
          v-model="inputValue"
          type="checkbox"
          v-bind="$attrs"
          class="absolute inset-0 w-full h-full invisible opacity-0"
        >
        <div class="w-10 h-5" style="margin: 2px">
          <div
            class="w-1/2 h-full bg-white rounded-full shadow transform translate-x-0 transition-transform duration-150 ease-in-out"
            :class="{'translate-x-full': value}"
          />
        </div>
      </label>

      <!-- Label after -->
      <slot name="default" />
    </div>

    <!-- Input errors -->
    <InputError :v="v" :vMessages="vMessages" />
  </div>
</template>

<script>
import InputError from '~/components/forms/InputError'

export default {
  name: 'SwitchInput',

  components: {
    InputError,
  },

  props: {
    value: { type: Boolean, required: true, default: false },
    v: { type: Object, required: false, default: () => ({}) },
    vMessages: { type: Object, required: false, default: () => ({}) },
    disabled: { type: Boolean, required: false, default: false },
  },

  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
