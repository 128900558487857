<template>
  <button
    type="button"
    :disabled="disabled"
    :class="{'cursor-default': disabled}"
    v-on="$listeners"
  >
    <div class="w-full h-full flex justify-center items-center overflow-hidden">
      <div v-if="emoji">{{ emoji }}</div>
      <div v-else :class="iconClass">
        <PuzzleSolid />
      </div>
    </div>
  </button>
</template>

<script>
import PuzzleSolid from "~/components/icons/PuzzleSolid"

export default {
  name: 'EmojiButton',

  components: {
    PuzzleSolid,
  },

  props: {
    emoji: { type: String, required: false, default: '' },
    iconClass: {
      type: String,
      required: false,
      default: 'w-full',
      // if iconClass is used, it must contain a "width class" (w-**). Ex: w-full, w-6, ...
      validator: v => !v || /(^| )w-.+( |$)/.test(v),
    },
    disabled: { type: Boolean, required: false, default: false },
  },
}
</script>
