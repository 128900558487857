/**
 * This middleware can be used in pages where an active billing subscription is required.
 *
 * REQUIREMENTS:
 *   - Must be called after 'auth' middleware.
 *   - Must be called after 'workspace' middleware.
 *   - Must be called after 'billing' middleware.
 */
export default function activeSubscriptionRequired ({ store, route, params, redirect }) {
  console.info('[middleware::activeSubscriptionRequired]')

  if (!store.getters['billing/activeSubscription']) {
    redirect(`/workspace/${params.wsid}/settings/billing/funnel/finalization`)
  }
}
