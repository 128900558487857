import { throwIfNot, isId } from "~/utils/common"

export const state = () => ({
  searchGlobalList: undefined,
})

/**
 * @type {import("vuex").MutationTree<typeof state>}
 */
export const mutations = {
  SET_GLOBAL_LIST (state, list) {
    state.searchGlobalList = list || null
  },
}

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
export const actions = {
  async loadGlobalList (ctx, workspaceId) {
    throwIfNot(isId, workspaceId)

    const headers = {
      'x-current-workspace-id': workspaceId,
    }
    const { data: list } = await this.$axios.get(`/search`, { headers })

    ctx.commit('SET_GLOBAL_LIST', list)
    return list
  },
}
