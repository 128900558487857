import { inviteError } from '~/utils/errors'
import { isId, throwIfNot, patchObject, removeFrom, addLogOnCall, extractProperties } from '~/utils/common'
import { RoleToRank } from '~/utils/constants'
import { isAtLeastOwner, isAtLeastAdmin, isAtLeastMember } from '~/utils/rights'

// =================================== STATE ===================================

export const state = () => ({
  currentId: undefined,
  currentWorkspace: undefined,
  personalWorkspaces: [],
  personalWorkspacesLoaded: false,
  lastProjects: [],
  lastProjectsLoaded: false,
  teamUsers: [],
  teamUsersLoaded: false,
})

// ================================== GETTERS ==================================

/**
 * @type {import("vuex").GetterTree<typeof state>}
 */
export const getters = {
  currentPersonalWorkspace: (state) => {
    return state.personalWorkspaces.find(pw => pw.workspace.id === state.currentId) || {}
  },
  nbOwners: (state) => {
    var count = 0
    for (const t of state.teamUsers) if (isAtLeastOwner(t.role)) ++count
    return count
  },
  currentJobTitle:    (state, get) => get.currentPersonalWorkspace.job_title || '',
  currentEmailAlert:  (state, get) => get.currentPersonalWorkspace.email_alert || false,
  currentRole:        (state, get) => get.currentPersonalWorkspace.role || '',
  currentRank:        (state, get) => RoleToRank[get.currentRole] || 0,
  currentName:        state => state.currentWorkspace?.name || '',
  currentEmailDomain: state => state.currentWorkspace?.email_domain || '',
  currentLogo:        state => state.currentWorkspace?.logo || '',
  currentlyOwner:     (state, get) => isAtLeastOwner(get.currentRole),
  currentlyAdmin:     (state, get) => isAtLeastAdmin(get.currentRole),
  currentlyMember:    (state, get) => isAtLeastMember(get.currentRole),
}

// ================================= MUTATIONS =================================

/**
 * @type {import("vuex").MutationTree<typeof state>}
 */
export const mutations = {
  SET_CURRENT_ID (state, id) {
    state.currentId = id === 'public' ? id : (Number(id) || null)
  },
  SET_PERSONAL_WORKSPACES (state, payload) {
    state.personalWorkspaces = payload || []
    state.personalWorkspacesLoaded = Array.isArray(payload)
  },
  SET_CURRENT_WORKSPACE (state, payload) {
    state.currentWorkspace = payload || null
  },
  PATCH_CURRENT_WORKSPACE (state, patch) {
    patchObject(state.currentWorkspace, patch)
  },
  PATCH_PERSONAL_WORKSPACE (state, { workspaceId, patch }) {
    for (const pw of state.personalWorkspaces) {
      if (pw.workspace.id === workspaceId) {
        patchObject(pw, patch)
      }
    }
  },
  REMOVE_PERSONAL_WORKSPACE (state, workspaceId) {
    removeFrom(state.personalWorkspaces, pw => pw.workspace.id === workspaceId)
  },
  SET_TEAM_USERS (state, payload) {
    state.teamUsers = payload || []
    state.teamUsersLoaded = Array.isArray(payload)
  },
  PATCH_TEAM_USER (state, { userId, patch }) {
    for (const tu of state.teamUsers) {
      if (tu.user.id === userId) {
        patchObject(tu, patch)
      }
    }
  },
  REMOVE_TEAM_USER (state, userId) {
    removeFrom(state.teamUsers, tu => tu.user.id === userId)
  },
  SET_LAST_PROJECTS (state, payload) {
    state.lastProjects = payload || []
    state.lastProjectsLoaded = Array.isArray(payload)
  },
  PATCH_LAST_PROJECT (state, { projectId, patch }) {
    for (const p of state.lastProjects) {
      if (p.id === projectId) {
        patchObject(p, patch)
      }
    }
    if (patch.last_update) {
      // reorder projects by descending last_update
      state.lastProjects.sort((a, b) => {
        if (a.last_update < b.last_update) return 1
        if (a.last_update > b.last_update) return -1
        return 0
      })
    }
  },
  REMOVE_LAST_PROJECT (state, projectId) {
    removeFrom(state.lastProjects, p => p.id === projectId)
  },
  PATCH_LAST_PROJECTS_USER (state, { userId, patch }) {
    for (const p of state.lastProjects) {
      for (const u of p.users) {
        if (u.id === userId) {
          patchObject(u, patch)
        }
      }
    }
  },
  REMOVE_LAST_PROJECTS_USER (state, { userId, projectId = null }) {
    for (const p of state.lastProjects) {
      if (projectId && p.id !== projectId) continue
      removeFrom(p.users, u => u.id === userId)
    }
  },
}

// ================================== ACTIONS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeActions = {
  _reset (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined)
    }
    if (process.browser && !this.state.publicMode) {
      this.$sse.close()
    }
  },

  async create (ctx, payload) {
    const body = {
      name: payload.name,
    }
    const { data } = await this.$axios.post(`/workspaces`, body)

    await ctx.dispatch('loadWorkspaces')
    await ctx.dispatch('setCurrentWorkspace', data.created_id)
  },

  async createDemoWorkspace (ctx, payload) {
    if (!this.$canCreateDemoWorkspace(ctx.state.currentId)) return

    const headers = {
      'x-current-workspace-id': ctx.state.currentId,
      'x-client-id': this.$sse.id,
    }
    const body1 = {
      name: payload.workspaceName,
      owner_email: payload.ownerEmail,
      email_domain: payload.emailDomain,
      owner_lang: payload.ownerLang,
    }
    const { data } = await this.$axios.post("/admin/workspaces/demo", body1, { headers })

    const body2 = {
      workspace_id: data.created_id,
      customer_email: payload.ownerEmail,
      plan: 'PREMIUM',
      cycle: 'monthly',
      nb_seats: 10,
      trial_days: 365,
    }
    await this.$axios.post("/admin/billing/trial", body2, { headers })

    await ctx.dispatch('loadWorkspaces')
  },

  async loadCurrentWorkspace (ctx) {
    const workspaceId = ctx.state.currentId
    throwIfNot(isId, workspaceId)

    const headers = {
      'x-current-workspace-id': workspaceId,
    }

    const { data } = await this.$axios.get(`/workspaces/c`, { headers })

    ctx.commit('SET_CURRENT_WORKSPACE', data)
  },

  async setCurrentWorkspace (ctx, workspaceId) {
    throwIfNot(isId, workspaceId)
    if (workspaceId === ctx.state.currentId) return

    // Unset any previous workspace state
    ctx.commit('SET_TEAM_USERS')
    ctx.commit('SET_LAST_PROJECTS')
    await this.dispatch('resetWorkspaceContext')

    ctx.commit('SET_CURRENT_ID', workspaceId)
    this.$userPrefs.set(this.state.user.me.id, 'workspaceId', workspaceId)

    await ctx.dispatch('loadCurrentWorkspace')

    if (process.browser) {
      this.$sse.close()
      const channelId = ctx.state.currentWorkspace.sse_channel_id
      await this.$sse.open(`${this.$SUB_URL}/${channelId}`, {
        seed: this.$localStorage.currentUserId,
      })
      const use = action => payload => this.dispatch(action, payload)
      this.$sse.sub('user:patch', use('user/onUserPatch'))
      this.$sse.sub('user:delete', use('user/onUserDelete'))
      this.$sse.sub('workspace:patch', use('workspace/onWorkspacePatch'))
      this.$sse.sub('workspace:delete', use('workspace/onWorkspaceDelete'))
      this.$sse.sub('workspaceUser:add', use('workspace/onWorkspaceUserAdd'))
      this.$sse.sub('workspaceUser:patch', use('workspace/onWorkspaceUserPatch'))
      this.$sse.sub('workspaceUser:remove', use('workspace/onWorkspaceUserRemove'))
      this.$sse.sub('group:create', use('group/onGroupCreate'))
      this.$sse.sub('group:delete', use('group/onGroupDelete'))
      this.$sse.sub('group:patch', use('group/onGroupPatch'))
      this.$sse.sub('group:toggleArchive', use('group/onGroupToggleArchive'))
      this.$sse.sub('groupUser:add', use('group/onGroupUserAdd'))
      this.$sse.sub('groupUser:patch', use('group/onGroupUserPatch'))
      this.$sse.sub('groupUser:remove', use('group/onGroupUserRemove'))
      this.$sse.sub('project:create', use('project/onProjectCreate'))
      this.$sse.sub('project:patch', use('project/onProjectPatch'))
      this.$sse.sub('project:toggleArchive', use('project/onProjectToggleArchive'))
      this.$sse.sub('project:delete', use('project/onProjectDelete'))
      this.$sse.sub('projectUser:add', use('project/onProjectUserAdd'))
      this.$sse.sub('projectUser:patch', use('project/onProjectUserPatch'))
      this.$sse.sub('projectUser:remove', use('project/onProjectUserRemove'))
      // Billing
      this.$sse.sub('billingPlan:reload', use('billing/onBillingPlanReload'))
      this.$sse.sub('billingWorkspace:patch', use('billing/onBillingWorkspacePatch'))
      // These are only useful to owners BUT, managing them according to the user role
      // (which may change) is out of the question.
      // To handle these messages without having to check for the role,
      // resources are associated with flags that can only be true for users that
      // are currently owners. This way, the following handlers won't do anything
      // if the current user is not owner.
      this.$sse.sub('billing:patch', use('billing/onBillingPatch'))
      this.$sse.sub('billingInfo:patch', use('billing/onBillingInfoPatch'))
      this.$sse.sub('invoice:new', use('billing/onInvoiceNew'))
    }

    await ctx.dispatch('sortPersonalWorkspaces')

    await this.dispatch('group/loadMenuGroups')
  },

  async fallbackWorkspacePath (ctx) {
    const pws = await ctx.dispatch('loadWorkspaces', { useCache: true })
    if (pws.length) {
      // Recover stored workspace id
      const userId = this.state.user.me.id
      var currentId = this.$userPrefs.get(userId, 'workspaceId')
      // Ensure the new id exists or fallback to the first available user workspace
      if (!currentId || !pws.some(pw => pw.workspace.id === currentId)) {
        currentId = pws[0].workspace.id
      }
      return `/workspace/${currentId}`
    }
    return '/signup/workspace?process=onboarding'
  },

  async loadWorkspaces (ctx, { useCache = false } = {}) {
    // NOTE: workspace context is only accessible to signed users, no need to check for publicMode
    if (useCache === true && ctx.state.personalWorkspacesLoaded) {
      return ctx.state.personalWorkspaces
    }

    const { data: personalWorkspaces } = await this.$axios.get(`/workspaces`)

    ctx.commit('SET_PERSONAL_WORKSPACES', personalWorkspaces)
    return personalWorkspaces
  },

  sortPersonalWorkspaces (ctx, payload) {
    const copy = [...ctx.state.personalWorkspaces]

    copy.sort((a, b) => {
      // current workpace always comes first
      if (a.workspace.id === ctx.state.currentId) return -1
      if (b.workspace.id === ctx.state.currentId) return 1
      // Else order by name (alphabetic, ascending, handle unicode)
      const nameA = a.workspace.name || ''
      const nameB = b.workspace.name || ''
      return nameA.localeCompare(nameB)
    })

    ctx.commit('SET_PERSONAL_WORKSPACES', copy)
  },

  async updatePersonalSettings (ctx, body) {
    throwIfNot(isId, ctx.state.currentId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': ctx.state.currentId,
      'x-client-id': this.$sse.id,
    }
    const result = await this.$axios.put(`/workspaces/c/personal-settings`, body, { headers })

    await ctx.dispatch('onWorkspaceUserPatch', { userId: this.state.user.me.id, patch: body })

    return result
  },

  async updateWorkspaceSettings (ctx, body) {
    const workspaceId = ctx.state.currentId
    throwIfNot(isId, workspaceId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': workspaceId,
      'x-client-id': this.$sse.id,
    }

    const result = await this.$axios.put(`/workspaces/c`, body, { headers })

    await ctx.dispatch('onWorkspacePatch', { patch: body })

    return result
  },

  async loadTeam (ctx, { useCache = false } = {}) {
    // NOTE: workspace context is only accessible to signed users, no need to check for publicMode
    if (useCache === true && ctx.state.teamUsersLoaded) return
    throwIfNot(isId, ctx.state.currentId)

    const headers = {
      'x-current-workspace-id': ctx.state.currentId,
    }
    const { data: teamUsers } = await this.$axios.get(`/workspaces/c/team`, { headers })

    ctx.commit('SET_TEAM_USERS', teamUsers)
    return teamUsers
  },

  async addToTeam (ctx, { emails, onboarding = false }) {
    throwIfNot(isId, ctx.state.currentId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': ctx.state.currentId,
      'x-client-id': this.$sse.id,
    }
    const params = { onboarding }
    const body = { invites: emails }

    const result = await this.$axios.post(`/workspaces/c/team`, body, { headers, params })

    await ctx.dispatch('onWorkspaceUserAdd')

    return result
  },

  async changeTeamRole (ctx, { userId, ...body }) {
    throwIfNot(isId, ctx.state.currentId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': ctx.state.currentId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.put(`/workspaces/c/team/${userId}`, body, { headers })

    await ctx.dispatch('onWorkspaceUserPatch', { userId, patch: body })
  },

  async resendInvite (ctx, userId) {
    throwIfNot(isId, ctx.state.currentId)

    const headers = { 'x-current-workspace-id': ctx.state.currentId }

    await this.$axios.put(`/workspaces/c/team/${userId}/refresh`, null, { headers })
  },

  async acceptInvite (ctx, inviteToken) {
    if (!inviteToken) throw new Error("Missing inviteToken. Cannot accept invitation")

    var data
    try {
      data = await this.$axios.$post(`/workspaces/accept-invite/${inviteToken}`)
    } catch (e) {
      return { error: inviteError(e) }
    }

    // If the user is currently authenticated (or will be, auth token stored)
    // and the current account is not the one that received the invite,
    // we need to signout to clear all user data.
    // Otherwise, if the user is loaded, update the invite status
    // This shouldn't happen since there is no inner navigation to this page in
    // the app at the moment (only links from received emails).
    const currentUserId = this.$localStorage.currentUserId.get()
    if (currentUserId) {
      if (currentUserId !== data.user_id) {
        await ctx.dispatch('user/signout', null, { root: true })
      } else if (this.state.user.me) {
        await ctx.dispatch('onWorkspaceUserPatch', {
          userId: currentUserId,
          patch: { pending_invite: false },
        })
      }
    }
    // Set current workspace to automatically access it after the process is finalized
    this.$userPrefs.set(data.user_id, 'workspaceId', data.workspace_id)

    return { activationToken: data.activation_token, workspaceId: data.workspace_id }
  },

  async removeFromWorkspace (ctx, userId) {
    throwIfNot(isId, ctx.state.currentId)

    const headers = {
      'x-current-workspace-id': ctx.state.currentId,
      'x-client-id': this.$sse.id,
    }
    await this.$axios.delete(`/workspaces/c/team/${userId}`, { headers })

    await ctx.dispatch('onWorkspaceUserRemove', { userId })
  },

  async leaveWorkspace (ctx, body) {
    const workspaceId = ctx.state.currentId
    throwIfNot(isId, workspaceId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': workspaceId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.post(`/workspaces/c/leave`, body, { headers })

    await ctx.dispatch('onWorkspaceDelete')
  },

  async deleteWorkspace (ctx, body) {
    const workspaceId = ctx.state.currentId
    throwIfNot(isId, workspaceId)
    throwIfNot(isId, this.$sse.id)

    const headers = {
      'x-current-workspace-id': workspaceId,
      'x-client-id': this.$sse.id,
    }

    await this.$axios.post(`/workspaces/c/delete`, body, { headers })

    await ctx.dispatch('onWorkspaceDelete')
  },

  async loadLastProjects (ctx, { useCache = false } = {}) {
    // NOTE: workspace context is only accessible to signed users, no need to check for publicMode
    if (useCache === true && ctx.state.lastProjectsLoaded) return
    throwIfNot(isId, ctx.state.currentId)

    const headers = { 'x-current-workspace-id': ctx.state.currentId }
    const params = { limit: 6 }

    const { data: lastProjects } = await this.$axios.get(`/projects`, { params, headers })

    ctx.commit('SET_LAST_PROJECTS', lastProjects)
  },
}

// ================================== EFFECTS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
const storeEffects = {
  onWorkspacePatch (ctx, { patch }) {
    ctx.commit('PATCH_CURRENT_WORKSPACE', patch)

    const workspaceId = ctx.state.currentId
    const pwPatch = { workspace: patch }

    ctx.commit('PATCH_PERSONAL_WORKSPACE', { workspaceId, patch: pwPatch })
  },

  async onWorkspaceDelete (ctx) {
    const workspaceId = ctx.state.currentId

    ctx.commit('SET_CURRENT_ID') // reset current id first, to prevent resolving irrelevant data
    ctx.commit('REMOVE_PERSONAL_WORKSPACE', workspaceId)

    const wsPath = await ctx.dispatch('fallbackWorkspacePath')
    this.$router.push(wsPath)
  },

  async onWorkspaceUserAdd (ctx) {
    if (ctx.state.teamUsersLoaded) {
      await ctx.dispatch('loadTeam')
    }
  },

  async onWorkspaceUserPatch (ctx, { userId, patch }) {
    const workspaceId = ctx.state.currentId
    const isCurrentUser = this.state.user.me.id === userId
    const adminshipChanged = ctx.getters.currentlyAdmin !== isAtLeastAdmin(patch.role)
    const membershipChanged = ctx.getters.currentlyMember !== isAtLeastMember(patch.role)
    const retrograded = ctx.getters.currentRank > RoleToRank[patch.role]

    if (isCurrentUser) {
      ctx.commit('PATCH_PERSONAL_WORKSPACE', { workspaceId, patch })
    }

    const wsUserPatch = extractProperties(patch, ['job_title', 'pending_invite', 'role'])
    if (wsUserPatch) {
      ctx.commit('PATCH_TEAM_USER', { userId, patch: wsUserPatch })
    }

    const teamPatch = extractProperties(patch, ['job_title', 'pending_invite'])
    if (teamPatch) {
      this.commit('group/PATCH_GROUP_USER', { userId, patch: teamPatch })
      this.commit('group/PATCH_PROJECT_USER', { userId, patch: teamPatch })
      this.commit('project/PATCH_TEAM_USER', { userId, patch: teamPatch })
    }

    if (isCurrentUser) {
      // Admins see everything
      // Members see everything that is public AND what they are in
      // Non members see nothing but what they are in
      // If adminship or membership changes, it is possible that some groups will
      // become accessible (or inaccessible), which requires reloading groups.
      if (adminshipChanged || membershipChanged) {
        if (retrograded) {
          // Easier way to deal with this special case, force redirect the user to the
          // workspace team page and reset all contexts under the workspace
          this.$router.push(`/workspace/${this.state.workspace.currentId}/settings/team`)
          this.dispatch('resetWorkspaceContext')
          // TODO: send a toast indicating their role has been reduced.
        } else {
          // Reload current workspace to get newly accessible billing details
          await ctx.dispatch('loadCurrentWorkspace')
        }
        // Finally reload groups and there projects in the main menu
        // NOTE: this must be done after "resetWorkspaceContext" or groups would
        // be deleted after being loaded
        await this.dispatch('group/loadMenuGroups')
        const loaded = Object.values(this.state.group.groupDetails).filter(Boolean).map(g => g.id)
        await this.dispatch('group/loadGroupDetails', loaded)
      }
    }
  },

  async onWorkspaceUserRemove (ctx, { userId }) {
    if (userId === this.state.user.me.id) {
      return await ctx.dispatch('onWorkspaceDelete')
    }

    ctx.commit('REMOVE_TEAM_USER', userId)
    this.commit('group/REMOVE_GROUP_USER', userId)
    this.commit('group/REMOVE_PROJECT_USER', { userId })
    this.commit('project/REMOVE_TEAM_USER', userId)

    ctx.commit('REMOVE_LAST_PROJECTS_USER', { userId })
    this.commit('group/REMOVE_GROUP_DETAIL_USER', { userId })
    this.commit('group/REMOVE_CURRENT_PROJECTS_USER', { userId })
    this.commit('project/REMOVE_PROJECT_DETAIL_USER', { userId })

    await ctx.dispatch('loadCurrentWorkspace')
  },
}

addLogOnCall(storeActions)
addLogOnCall(storeEffects)

export const actions = {
  ...storeActions,
  ...storeEffects,
}
