<template>
  <!-- Wrapper -->
  <div class="relative">
    <!-- Top bar -->
    <FormTopBar
      :topBarTitle="topBarTitle"
      @goBack="$router.push(`/workspace/${workspaceId}/settings/billing`)"
    />

    <!-- Background -->
    <div class="absolute w-screen h-screen flex">
      <!-- Left part -->
      <div class="w-full lg:w-1/2 bg-white" />
      <!-- Right part -->
      <div class="hidden lg:block w-1/2 bg-gray-30" />
    </div>
    <nuxt />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FormTopBar from '~/components/bars/FormTopBar'

export default {
  name: 'OnboardingLayout',

  components: {
    FormTopBar,
  },

  middleware: ['auth', 'workspace', 'billing'],

  data () {
    return {
      topBarTitle: undefined,
    }
  },

  computed: {
    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),
  },

  provide () {
    return {
      layoutContext: this,
    }
  },
}
</script>
