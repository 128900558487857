import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

export default ({ $config }) => {
  Sentry.init({
    dsn: $config.SENTRY_DSN,
    release: $config.VERSION_NAME,
    environment: $config.ENV_NAME,
    integrations: [
      new VueIntegration({ Vue, attachProps: true }),
    ],
  })
}
