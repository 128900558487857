<template>
  <div>
    <template v-for="a in visibleActions">
      <!-- Separation -->
      <div v-if="a.separation === true" class="w-full h-px bg-gray-100 my-2" />

      <!-- Navigation action -->
      <nuxt-link
        v-else-if="a.path"
        :key="a.labelI18n"
        :to="a.path"
        class="block w-full hover:bg-gray-50"
      >
        <ActionMenuItem :labelI18n="a.labelI18n" :icon="a.icon" />
      </nuxt-link>

      <!-- Button action -->
      <button
        v-else-if="a.token"
        :key="a.labelI18n"
        type="button"
        class="block w-full hover:bg-gray-50"
        @click="$emit('actionClicked', a.token)"
      >
        <ActionMenuItem :labelI18n="a.labelI18n" :icon="a.icon" :toggleValue="a.toggleValue" />
      </button>

      <!-- Simple label -->
      <ActionMenuItem v-else :key="a.labelI18n" :labelI18n="a.labelI18n" :icon="a.icon" />
    </template>

    <!-- Toggle more/less button -->
    <button
      v-if="actions.length > 3 && nbActionsWhenCollapsed > 0"
      ref="btn"
      type="button"
      class="block w-full h-10 text-left text-gray-400 text-sm px-4"
      @click="$refs.btn.focus(); collapsed = !collapsed"
    >
      <span>{{ collapsed ? 'More' : 'Less' }}</span>
    </button>

    <!-- Separation -->
    <div v-if="actions.length && $slots.bottomContent" class="w-full h-px bg-gray-100 my-2" />

    <!-- Bottom content -->
    <slot name="bottomContent" />
  </div>
</template>

<script>
import ActionMenuItem from './items/ActionMenuItem'

export default {
  name: 'ActionMenu',

  components: {
    ActionMenuItem,
  },

  props: {
    /**
     * Valid action shapes:
     *   - Separation: { separation: true }
     *   - Link: { path: String, labelI18n: String, icon: String? }
     *   - Button: { token: String, labelI18n: String, icon: String?, toggleValue: Boolean? }
     */
    actions: { type: Array, required: false, default: () => [] },
    nbActionsWhenCollapsed: { type: Number, required: false, default: 0 },
  },

  data () {
    return {
      collapsed: true,
    }
  },

  computed: {
    visibleActions () {
      return this.nbActionsWhenCollapsed > 0 && this.collapsed
        ? this.actions.slice(0, this.nbActionsWhenCollapsed)
        : this.actions
    },
  },
}
</script>
