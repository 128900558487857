import { isBoolean, throwIfNot } from '~/utils/common'

// =================================== STATE ===================================

export const state = () => ({
  publicMode: undefined,
  publicToken: undefined,
  menuOpened: {
    main: undefined,
    project: undefined,
    settings: undefined,
  },
})

// ================================== GETTERS ==================================

// ================================= MUTATIONS =================================

/**
 * @type {import("vuex").MutationTree<typeof state>}
 */
export const mutations = {
  SET_PUBLIC_MODE (state, { mode, token } = {}) {
    state.publicMode = mode || null
    state.publicToken = token || null
  },
  SYNC_MENU_OPENED (state, menus) {
    // Only sync provided menus, throw if not boolean
    for (const name in menus) {
      if (name in state.menuOpened) {
        const opened = menus[name]
        throwIfNot(isBoolean, opened)
        state.menuOpened[name] = opened
      }
    }
    this.$localStorage.menuOpened.set(state.menuOpened)
  },
}

// ================================== ACTIONS ==================================

/**
 * @type {import("vuex").ActionTree<typeof state>}
 */
export const actions = {
  resetAllContexts (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined)
    }
    this.dispatch('user/_reset')
    this.dispatch('workspace/_reset')
    this.dispatch('resetWorkspaceContext')
  },

  resetWorkspaceContext (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined)
    }
    this.dispatch('billing/_reset')
    this.dispatch('group/_reset')
    this.dispatch('project/_reset')
    this.dispatch('doc/_reset')
    this.dispatch('log/_reset')
    this.dispatch('link/_reset')
  },

  async uploadFile (ctx, file) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    formData.append('file', file)

    const { data } = await this.$axios.post(`/upload`, formData, { headers })

    return data.filename
  },
}
