<template>
  <div class="relative">
    <!-- placeholder "before" -->
    <div v-show="beforePlaceholderVisible" class="absolute z-10 top-0 DragTargetPin">
      <div class="DragTargetPin-head" />
    </div>

    <!-- Node Item -->
    <div
      class="rounded select-none"
      :class="{'shadow-border-primary-400': !isDraggedNode && insidePlaceholderVisible}"
      @dragstart="updateDraggedNode($event, node)"
      @dragenter="updateTargetNode($event, node)"
      @dragover="updateTargetArea($event, node)"
      @dragleave="resetTarget($event, node)"
      @drop="isValidTarget && moveNode()"
      @dragend="resetDrag"
    >
      <slot name="node" :node="node" :depth="depth" />
    </div>

    <!-- Node Children -->
    <div
      v-if="node.children.length && node.opened"
      class="ml-4"
      :class="{'pointer-events-none': isDraggedNode}"
    >
      <DraggableTreeNode
        v-for="child in node.children"
        :key="child.path"
        :node="child"
        :depth="depth + 1"
        :draggedNode="draggedNode"
        :targetNode="targetNode"
        :targetArea="targetArea"
        :updateDraggedNode="updateDraggedNode"
        :updateTargetNode="updateTargetNode"
        :updateTargetArea="updateTargetArea"
        :moveNode="moveNode"
        :resetDrag="resetDrag"
        :resetTarget="resetTarget"
      >
        <template v-slot:node="scopedSlot">
          <slot name="node" v-bind="scopedSlot" />
        </template>
      </DraggableTreeNode>
    </div>

    <!-- placeholder after -->
    <div v-show="afterPlaceholderVisible" class="absolute z-10 DragTargetPin">
      <div class="DragTargetPin-head" />
    </div>
  </div>
</template>

<script>
import { MAX_DEPTH, NodeArea, resolveDepthBelow } from '~/utils/treenode'

export default {
  name: 'DraggableTreeNode',

  props: {
    node: { type: Object, required: true, default: () => ({}) },
    depth: { type: Number, required: true, default: 0 },
    draggedNode: { type: Object, required: false, default: null },
    targetNode: { type: Object, required: false, default: null },
    targetArea: { type: String, required: false, default: '' },
    updateDraggedNode: { type: Function, required: true, default: () => {} },
    updateTargetNode: { type: Function, required: true, default: () => {} },
    updateTargetArea: { type: Function, required: true, default: () => {} },
    moveNode: { type: Function, required: true, default: () => {} },
    resetDrag: { type: Function, required: true, default: () => {} },
    resetTarget: { type: Function, required: true, default: () => {} },
  },

  computed: {
    isDraggedNode () {
      return this.draggedNode === this.node
    },
    isTargetNode () {
      return this.targetNode === this.node
    },
    depthBelowDragged () {
      return this.draggedNode ? resolveDepthBelow(this.draggedNode) : 0
    },
    depthOfDragged () {
      return this.depth + (this.targetArea === NodeArea.INSIDE ? 1 : 0)
    },
    isValidTarget () {
      return this.isTargetNode && (this.depthOfDragged + this.depthBelowDragged) <= MAX_DEPTH
    },
    beforePlaceholderVisible () {
      return this.isValidTarget && this.targetArea === NodeArea.BEFORE
    },
    insidePlaceholderVisible () {
      return this.isValidTarget && this.targetArea === NodeArea.INSIDE
    },
    afterPlaceholderVisible () {
      return this.isValidTarget && this.targetArea === NodeArea.AFTER
    },
  },
}
</script>

<style scoped>
.DragTargetPin {
  @apply w-full bg-primary rounded py-px -my-px pointer-events-none;
}
.DragTargetPin-head {
  top: 50%;
  @apply absolute left-0 w-2 h-2 bg-primary rounded-full pointer-events-none;
  @apply transform -translate-x-1/2 -translate-y-1/2;
}
</style>
