<template>
  <div class="relative flex overflow-hidden">
    <!-- Workspace Menu -->
    <MainMenu
      :opened="menuOpened.main"
      @openedChanged="$store.commit('SYNC_MENU_OPENED', { main: $event })"
    />

    <!-- Right: Page -->
    <div class="flex-grow relative h-screen flex flex-col">
      <!-- Top bar -->
      <div
        class="flex-shrink-0 w-full h-topbar bg-white border-b-2 border-gray-100"
        :class="menuOpened.main ? 'pl-0' : 'pl-8 md:pl-12'"
        style="transition: padding 200ms ease-in-out"
      >
        <ActionTopBar class="h-full" :canEdit="currentlyAbleToEdit" :showGlobalActionsBtn="showGlobalActionsBtn">
          <template v-slot:default>
            <h3 class="text-sm font-bold">{{ currentName || '...' }}</h3>
          </template>

          <template v-slot:leadingContextualActions>
            <!-- Group settings -->
            <nuxt-link
              :to="`/workspace/${workspaceId}/group/${groupId}/settings`"
              class="hidden sm:block h-6 text-sm rounded px-2 mx-2"
            >
              <span>{{ $t('Edit group settings') }}</span>
            </nuxt-link>
          </template>

          <template v-slot:otherContextualActions>
            <!-- Responsive : Edit group -->
            <button
              class="sm:hidden h-10 flex items-center text-sm hover:bg-gray-50 px-4"
            >
              <nuxt-link
                :to="`/workspace/${workspaceId}/group/${groupId}/settings`"
              >
                <span>{{ $t('Edit group settings') }}</span>
              </nuxt-link>
            </button>
            <!-- Archive group -->
            <button
              class="h-10 flex items-center text-sm hover:bg-gray-50 px-4"
            >
              <span v-if="currentGroup" @click="manageGroup">{{ currentGroup.is_archived ? $t('Restore') : $t('Archive') }}</span>
            </button>
            <!-- Delete group -->
            <button
              class="h-10 flex items-center text-sm hover:bg-gray-50 px-4 w-full"
            >
              <span v-if="currentGroup && canDelete" @click="deleteGroup">{{ $t('Delete') }}</span>
            </button>
          </template>
        </ActionTopBar>
      </div>

      <ArchivedBar
        v-if="currentGroup && currentGroup.is_archived"
        class="flex-shrink-0 w-full"
        :label="$t('This group is archived')"
        @restore="$store.dispatch('group/restoreGroup', { groupId })"
      />

      <!-- Content -->
      <div class="flex-grow overflow-y-auto">
        <nuxt />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { isAtLeastEdit, isAtLeastInvite, isAtLeastMember } from '~/utils/rights'

import groupContext from '~/middleware/context/group'

import ActionTopBar from "~/components/bars/ActionTopBar"
import ArchivedBar from '~/components/bars/ArchivedBar'
import MainMenu from "~/components/menus/MainMenu"
import GroupArchiveModal from "~/components/modals/GroupArchiveModal"
import ConfirmModal from '~/components/modals/ConfirmModal'

export default {
  name: 'GroupLayout',

  components: {
    ActionTopBar,
    ArchivedBar,
    MainMenu,
  },

  data () {
    return {
      showGlobalActionsBtn: true,
    }
  },

  provide () {
    return {
      groupProvider: this,
    }
  },

  middleware: ['auth', 'workspace', groupContext.enter],

  computed: {
    ...mapState([
      'menuOpened',
    ]),

    ...mapState('workspace', {
      workspaceId: 'currentId',
    }),

    ...mapState('group', {
      groupId: 'currentId',
    }),

    ...mapGetters('group', [
      'currentName',
      'currentGroup',
      'currentlyAbleToEdit',
    ]),

    ...mapGetters('workspace', {
      workspaceName: 'currentName',
      currentRole: 'currentRole',
    }),

    canEdit () {
      return isAtLeastEdit(this.currentGroup.current_user_right)
    },

    canInvite () {
      return isAtLeastInvite(this.currentGroup.current_user_right)
    },

    isMemberOrHigherRole () {
      return isAtLeastMember(this.currentRole)
    },

    canDelete () {
      return this.canEdit && this.canInvite && this.isMemberOrHigherRole
    },
  },

  beforeDestroy () {
    groupContext.leave(this.$nuxt.context)
  },

  methods: {
    manageGroup () {
      this.$showModal(GroupArchiveModal, {
        group: this.currentGroup,
        onConfirm: (action, result) => {
          if (result.data.success) {
            if (action === 'group/restoreGroup') {
              this.$addToast({
                text: this.$t('Group restored'),
              })
            } else if (action === 'group/archiveGroup') {
              this.$addToast({
                text: this.$t('Group archived'),
              })
            }
          }
        },
      })
    },
    deleteGroup () {
      this.$showModal(ConfirmModal, {
        title: this.$t('Delete group'),
        description: this.$t(`You're about to delete group: "${this.currentName}" from workspace: "${this.workspaceName}" and its content.\n\nThis action cannot be undone. We will delete all associated data immediatly after your confirmation. Be very careful.\n\nAre you sure you wish to continue?`),
        confirmLabel: this.$t('Yes, delete'),
        isCritical: true,
        onConfirm: async () => {
          const result = await this.$store.dispatch('group/deleteGroup', { groupId: this.groupId })
          if (result.data.success) {
            this.$addToast({
              text: this.$t('Group deleted'),
            })
          }
          this.$closeModal()
        },
      })
    },
  },
}
</script>
