<template>
  <div class="h-10 flex items-center px-4">
    <!-- Icon -->
    <div v-if="icon" class="flex-shrink-0 w-6 text-gray-400 p-2px mr-2">
      <component :is="icon" />
    </div>

    <!-- Label -->
    <div class="flex-grow text-sm text-left">{{ $t(labelI18n) }}</div>

    <!-- Switch decoration -->
    <SwitchInput
      v-if="toggleValue !== null"
      :value="toggleValue"
      class="flex-shrink-0 pointer-events-none ml-6"
    />
  </div>
</template>

<script>
import ArchiveSolid from '~/components/icons/ArchiveSolid'
import CalendarSolid from '~/components/icons/CalendarSolid'
import DuplicateSolid from '~/components/icons/DuplicateSolid'
import ExternalLinkSolid from '~/components/icons/ExternalLinkSolid'
import LockClosedSolid from '~/components/icons/LockClosedSolid'
import ShareSolid from '~/components/icons/ShareSolid'
import TrashSolid from "~/components/icons/TrashSolid"
import TemplateSolid from '~/components/icons/TemplateSolid'
import SwitchInput from '~/components/inputs/SwitchInput'

export default {
  name: 'ActionMenuItem',

  components: {
    ArchiveSolid,
    CalendarSolid,
    DuplicateSolid,
    ExternalLinkSolid,
    LockClosedSolid,
    ShareSolid,
    SwitchInput,
    TrashSolid,
    TemplateSolid,
  },

  props: {
    labelI18n: { type: String, required: true, default: '' },
    icon: { type: String, required: false, default: '' },
    toggleValue: { type: Boolean, required: false, default: null },
  },
}
</script>
