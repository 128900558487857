<template>
  <div class="w-screen h-screen bg-white text-gray-900">
    <!-- Page content -->
    <div class="h-screen overflow-y-auto">
      <div class="w-full h-full max-w-fl-md mx-auto">
        <nuxt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormLayout',

  components: {
  },

  destroyed () {
    this.$store.commit('SET_LAYOUT_CONTEXT', { topBarTitle: null })
  },
}
</script>
