
export function modalQueryMixin (options) {
  return {
    watch: {
      '$route.query.modal': 'modalQueryMixin_autoOpenModal',
    },

    created () {
      for (const k in options) {
        const detail = 'modalQueryMixin options must be a mapping of string to method names.'
        if (typeof k !== 'string') {
          throw new TypeError(`Not a string: ${k}. ${detail}`)
        }
        const methodName = options[k]
        if (typeof this[methodName] !== 'function') {
          throw new TypeError(`Not an instance method: ${methodName}. ${detail}`)
        }
      }
    },

    mounted () {
      this.modalQueryMixin_autoOpenModal(this.$route.query.modal)
    },

    methods: {
      modalQueryMixin_autoOpenModal (modal) {
        if (modal in options) {
          const { modal: _, ...query } = this.$route.query
          this.$router.replace({ query })
          this[options[modal]]()
        }
      },
    },
  }
}

/**
 * Returns the same path with guaranted trailing slash
 * @param {string} path route path
 */
function trailingSlash (path) {
  return path.replace(/\/?$/, '/')
}

/**
 * Check that paths have the same base.
 * @param {string} current Current route path
 * @param {string} target Destination route path
 *
 * @example
 * pathIncludes('/user', '/user') // true
 * pathIncludes('/user/details', '/user') // true
 * pathIncludes('/users', '/user') // false
 */
export function pathIncludes (current, target) {
  // With trailing slash, 2 paths having their last part starting alike will differ
  return trailingSlash(current).startsWith(trailingSlash(target))
}

/**
 * Check that all keys of target query are in current query (current > target)
 * @param {object} current Current route query
 * @param {object} target Destination route query
 */
function queryIncludes (current, target) {
  return Object.keys(target).every(k => k in current)
}

/**
 * Check that current route includes target route
 * i.e. all consituants of target route are included in constituants of current route
 * - current.path > target.path
 * - current.query > target.query
 * - current.hash > target.hash
 * @param {import('vue-router').Route} current Current route
 * @param {import('vue-router').Route} target Destination route (of a link for example)
 */
export function routeIncludes (current, target) {
  const samePath = pathIncludes(current.path || '', target.path || '')
  const sameQuery = queryIncludes(current.query || {}, target.query || {})
  // only compare hashes if target specifies one
  const sameHash = (!target.hash || current.hash === target.hash)
  return samePath && sameQuery && sameHash
}
