/**
* This middleware must be used in routes where we require NO authentication.
* Not to be confused with routes where authentication is irrelevant (such as the site landing page).
*/
export default async function noauth ({ store, query, redirect }) {
  console.info('[middleware::noauth]')
  // Disable the public mode to access a "no auth" page
  if (store.state.publicMode) {
    store.commit('SET_PUBLIC_MODE')
  }

  // Handle signout redirections
  if (query.signout) {
    return await store.dispatch('user/signout')
  }

  if (await store.dispatch('user/isAuthenticated')) {
    const wsPath = await store.dispatch('workspace/fallbackWorkspacePath')
    return redirect(wsPath)
  }
}
