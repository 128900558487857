<template>
  <div class="flex -ml-px">
    <!-- Limited user avatars -->
    <div v-for="user in avatars" class="flex-shrink-0 w-6 h-6 ml-px">
      <Avatar
        :key="user.id"
        v-tooltip="user.fullname"
        :url="user.avatar"
        :label="user.initial"
        :colorSource="user.fullname"
      />
    </div>

    <!-- Nb non displayed users -->
    <div
      v-if="nbOtherUsers"
      v-tooltip="otherUserNames"
      class="flex-shrink-0 w-6 h-6 rounded-full flex justify-center items-center border text-xs"
      :class="[
        color === 'primary'
          ? 'bg-primary-900 border-primary-800 text-primary-300'
          : 'bg-gray-30 border-gray-300 text-gray-900',
        addRoute ? 'ml-px' : 'ml-1'
      ]"
    >
      {{ nbOtherUsers }}
    </div>

    <!-- Add button -->
    <div v-tooltip.slow.compact="tooltipText" class="flex-shrink-0 ml-1">
      <nuxt-link
        v-if="addRoute"
        :to="addRoute"
        class="block w-6 h-6 p-2px rounded-full"
        :class="color === 'primary'
          ? 'bg-primary-800 hover:bg-primary-700'
          : 'bg-gray-900 hover:bg-gray-800 text-white'
        "
      >
        <PlusSolid />
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import Avatar from "~/components/cards/Avatar"
import PlusSolid from "~/components/icons/PlusSolid"

const NB_DISPLAYED_AVATARS = 3

export default {
  name: 'Avatars',

  components: {
    Avatar,
    PlusSolid,
  },

  props: {
    users: { type: Array, required: true, default: () => [] },
    color: { type: String, required: false, default: 'gray' },
    addRoute: { type: [Object, String], required: false, default: '' },
    tooltipText: { type: String, required: false, default: '' },
  },

  computed: {
    avatars () {
      return this.users.slice(0, NB_DISPLAYED_AVATARS)
    },

    nbOtherUsers () {
      return Math.max(0, this.users.length - NB_DISPLAYED_AVATARS)
    },

    otherUserNames () {
      return this.users.slice(NB_DISPLAYED_AVATARS).map(u => u.fullname).join(', ')
    },
  },
}
</script>
