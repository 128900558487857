<template>
  <BaseModal :title="$t('Duplicate document')" @modalClose="close">
    <template v-slot:body>
      <div class="max-w-full" style="width: 544px">
        <!-- Error hint -->
        <ErrorCard :error="formMixin_error" class="mb-6" />

        <form :id="formId" novalidate @submit.prevent="submit">
          <TextInput
            v-model="name"
            :v="$v.name"
            :label="$t('Name')"
            class="mb-4"
          />
        </form>

        <ProjectInput
          v-model="targetProjectId"
          :groupsOfProjects="groupsOfEditableProjects"
          :label="$t('Location')"
        />
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex justify-end items-center ml-auto">
        <button type="button" class="Btn Btn-wired-white m-2 xs:m-0" @click="close">
          <span>{{ $t('Cancel') }}</span>
        </button>
        <button type="submit" :form="formId" class="Btn Btn-filled m-2 xs:m-0 xs:ml-3" :disabled="!targetProjectId">
          <span>{{ $t('Confirm') }}</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { formMixin } from '~/utils/form'

import ErrorCard from '~/components/cards/ErrorCard'
import ProjectInput from '~/components/inputs/ProjectInput'
import TextInput from '~/components/inputs/TextInput'
import BaseModal from '~/components/modals/BaseModal'

export default {
  name: 'DuplicateDocModal',

  components: {
    BaseModal,
    ProjectInput,
    TextInput,
    ErrorCard,
  },

  mixins: [formMixin],

  props: {
    currentDoc: { type: Object, required: true, default: () => ({}) },
    projectId: { type: Number, required: true, default: 0 },
  },

  async fetch () {
    await this.$store.dispatch('project/loadEditableProjects', { useCache: true })
  },

  data () {
    return {
      name: '',
      targetProjectId: undefined,
    }
  },

  validations: {
    name: { required },
  },

  computed: {
    formId () {
      return `Form-${this._uid}`
    },

    ...mapState('project', {
      groupsOfEditableProjects: 'editableProjects',
    }),
  },

  created () {
    this.name = `Copy of ${this.currentDoc.name}`
  },

  methods: {
    submit () {
      this.formMixin_handleSubmit(async () => {
        await this.$store.dispatch('doc/duplicateDoc', {
          projectId: this.projectId,
          docId: this.currentDoc.id,
          name: this.name,
          target_project_id: this.targetProjectId || this.projectId,
        })
        this.close()
      })
    },

    close () {
      this.$emit('modalClose')
    },
  },
}
</script>
